import {
    makeStyles
} from "@mui/styles";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { FLEET_MANAGING_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import Axios from "axios";
import { SimpleModal } from "../modals/SimpleModal";
import {
    IconButton,
    Button,
    MenuItem,
    ListItemIcon,
    Box,
    Typography,
    TextField,
    Grid,
    styled,
    Paper,
    Snackbar,
    Alert,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { MaterialReactTable } from "material-react-table";
import { CreateEditFaultReport } from "../forms/CreateEditFaultReport";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import PlayCircleFilledWhiteTwoToneIcon from '@mui/icons-material/PlayCircleFilledWhiteTwoTone';
import {
    DateTimePicker,
    LocalizationProvider
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { FaultJob } from "../../../../app/pages/FaultJob";
import { FaultReport } from "../../../../app/pages/FaultReport";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function FaultTable({ className }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showFaultDetails, setShowFaultDetails] = useState(false);
    const [showFaultJob, setShowFaultJob] = useState(false);
    const [initialValues, setInitialValues] = useState({
        driverID: '',
        reporterID: '',
        images: {},
        faultText: ''
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Fault Report');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');

    const TableErrorMessage = useRef();
    const FaultID = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        driverID: '',
        reporterID: '',
        images: {},
        faultText: ''
    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('faults/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = Axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.faultText,
            id: 'faultText',
            header: 'Fault Description',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => `${row?.document?.driver.firstName} ${row?.document?.driver.lastName}`,
            id: 'name',
            header: 'Driver Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Fault Report Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ], []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            {/* <SimpleModal
                modal_tittle={"Create Fault Report"}
                show={show}
                size="lg"
                close={() => {
                    setShow(false);
                }}
                body={
                    <CreateEditFaultReport
                        initialValues={initialValues}
                        createEditText={createEditText}
                        onChanger={onChangeWorker}
                    />
                }
            /> */}
            <SimpleModal
                modal_tittle={"Create Fault Report"}
                show={show}
                size="lg"
                close={() => {
                    setShow(false);
                }}
                body={
                    <FaultReport
                        onChanger={onChangeWorker}
                    />
                }
            />
            <SimpleModal
                modal_tittle={"Create Fault Job"}
                show={showFaultJob}
                size="lg"
                close={() => {
                    setShowFaultJob(false);
                }}
                body={
                    <FaultJob
                        faultReportId={FaultID.current}
                        onChanger={onChangeWorker}
                    />
                }
            />
            <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Fault Report Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Fault Report Records from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="secondary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Fault Report");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Fault Report
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <MaterialReactTable
                    columns={columns}
                    data={data}
                    enableRowSelection
                    getRowId={(row) => row.id}
                    initialState={{ showColumnFilters: true }}
                    manualPagination
                    enableColumnFilterModes
                    enableColumnOrdering
                    enableGrouping
                    enableColumnPinning
                    enableRowPinning
                    enableRowActions
                    muiToolbarAlertBannerProps={
                        isError
                            ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            }
                            : undefined
                    }
                    onPaginationChange={setPagination}
                    rowCount={rowCount}
                    state={{
                        isLoading,
                        pagination,
                        showAlertBanner: isError,
                        showProgressBars: isRefetching
                    }}
                    renderDetailPanel={({ row }) => (
                        <>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={3}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                        <Grid item xs={12}>
                                            <Item>
                                                <Box sx={{ textAlign: 'left' }}>
                                                    <Typography variant="label" className='bold'>Driver: </Typography>
                                                    <Typography variant="p">
                                                        {row?.original?.document?.driver.firstName}
                                                        {" "}
                                                        {row?.original?.document?.driver.lastName}
                                                    </Typography>
                                                </Box>
                                            </Item>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Item>
                                                <Box sx={{ textAlign: 'left' }}>
                                                    <Typography variant="label" className='bold'>Driver: </Typography>
                                                    <Typography variant="p">
                                                        {row?.original?.document?.driver.firstName}
                                                        {" "}
                                                        {row?.original?.document?.driver.lastName}
                                                    </Typography>
                                                </Box>
                                            </Item>
                                        </Grid>

                                    </Grid>
                                </Grid>
                                <Grid item xs={3}>
                                    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                        <Grid item xs={12}>
                                            <Item>
                                                <Box sx={{ textAlign: 'left' }}>
                                                    <Typography variant="label" className='bold'>Fault ID: </Typography>
                                                    <Typography variant="p">
                                                        {row?.original?.document?.ID}
                                                    </Typography>
                                                </Box>
                                            </Item>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    renderRowActionMenuItems={({ closeMenu, row }) => [
                        // <MenuItem key={1} onClick={() => {
                        //     const faultReportID = row.original.document.ID;
                        //     setStorageItem('faultReportID', faultReportID);
                        //     setShowFaultDetails(true);
                        //     closeMenu();
                        // }}>
                        //     <ListItemIcon>
                        //         <InfoTwoToneIcon
                        //             style={{ color: "#22ABE2" }}
                        //         />
                        //     </ListItemIcon>
                        //     See Fault Details
                        // </MenuItem>,
                        <MenuItem key={2} onClick={() => {
                            const faultReportID = row.original.document.ID;
                            FaultID.current = faultReportID;
                            setShowFaultJob(true);
                            closeMenu();
                        }}>
                            <ListItemIcon>
                                <PlayCircleFilledWhiteTwoToneIcon
                                    style={{ color: "#00FF00" }}
                                />
                            </ListItemIcon>
                            Create Job
                        </MenuItem>
                    ]}
                    muiSkeletonProps={{
                        animation: 'wave',
                        height: 30,
                    }}
                    muiLinearProgressProps={{
                        color: 'primary',
                    }}
                    muiPaginationProps={{
                        rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                        showFirstButton: true,
                        showLastButton: true,
                        color: 'success',
                        shape: 'rounded',
                        showRowsPerPage: true,
                        variant: 'outlined',
                    }}
                    enableStickyFooter
                    enableColumnResizing
                    paginationDisplayMode={"pages"}
                    paginateExpandedRows={true}
                    muiTableContainerProps={{
                        sx: {
                            maxHeight: '650px',
                        }
                    }}
                    enableBatchRowSelection
                    enableRowNumbers
                    rowNumberDisplayMode={'original'}
                    selectAllMode={"all"}
                    options={{
                        selection: true,
                    }}
                />
            </div >
        </>
    );

}