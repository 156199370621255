/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useLayoutEffect, useEffect, useState } from "react";
import objectPath from "object-path";
import { useLocation } from "react-router-dom";
import { QuickActions } from "./components/QuickActions";
import { BreadCrumbs } from "./components/BreadCrumbs";
import { getBreadcrumbsAndTitle, useSubheader } from "../../_core/ManaSubheader";
import { useHtmlClassService } from "../../_core/ManaLayout";
import { useSelector, shallowEqual } from "react-redux";
import { SimpleModal } from "../../../_partials/widgets/modals/SimpleModal";
import { TokenExpirationNotice } from "../../../_partials/widgets/data-displays/TokenExpirationNotice";

export function SubHeader() {

    const { user } = useSelector((state) => state.auth, shallowEqual);
    const uiService = useHtmlClassService();
    const location = useLocation();
    const subheader = useSubheader();

    const [isTokenToExpire, setIsTokenToExpire] = useState(null);
    const [isTokenToExpireModal, setIsTokenToExpireModal] = useState(false);

    const layoutProps = useMemo(() => {

        return {
            config: uiService.config,
            subheaderMobileToggle: objectPath.get(
                uiService.config,
                "subheader.mobile-toggle"
            ),
            subheaderCssClasses: uiService.getClasses("subheader", true),
            subheaderContainerCssClasses: uiService.getClasses(
                "subheader_container",
                true
            ),
        };
    }, [uiService]);

    useLayoutEffect(() => {
        const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
        const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
        const breadcrumbs = (aside && aside.breadcrumbs.length > 0) ? aside.breadcrumbs : header.breadcrumbs;
        subheader.setBreadcrumbs(breadcrumbs);
        subheader.setTitle((aside && aside.title && aside.title.length > 0) ? aside.title : header.title);
        // eslint-disable-next-line
    }, [location.pathname]);

    // Do not remove this useEffect, needed from update title/breadcrumbs outside (from the page)
    useEffect(() => {

        let isMounted = true;

        const interval = setInterval(() => {

            const now = new Date().getTime();
            const signInTime = new Date(user.user_data.auth.lastSignInTime).getTime();

            const hours = Math.abs(now - signInTime) / 36e5;
            const hoursToReturn = hours.toFixed(2);

            setIsTokenToExpire(hoursToReturn);

            if (isTokenToExpire >= 0.04) {

                //setIsTokenToExpireModal(true);
                return clearInterval(interval);

            }

            console.log(hoursToReturn);

        }, 2000);

        return () => {
            isMounted = false;
            clearInterval(interval);
        };

    }, [isTokenToExpire, subheader, user.user_data.auth.lastSignInTime]);

    return (

        <>

            <SimpleModal
                modal_tittle={`Token Expiration`}
                show={isTokenToExpireModal}
                close={() => setIsTokenToExpireModal(false)}
                size="lg"
                body={
                    <TokenExpirationNotice
                        tokenTime={isTokenToExpire}
                    />
                }
            />
            <div id="kt_subheader" className={`subheader py-2 py-lg-4 ${layoutProps.subheaderCssClasses}`}>
                <div className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}>
                    {/* Info */}
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        {layoutProps.subheaderMobileToggle && (<>
                            <button
                                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                                id="kt_subheader_mobile_toggle"
                                type="button"
                            >
                                <span />
                            </button>
                        </>)}

                        <div className="d-flex align-items-baseline mr-5">
                            <h5 className="text-dark font-weight-bold my-2 mr-5">
                                <>
                                    {subheader.title}
                                </>
                                {/*<small></small>*/}
                            </h5>

                        </div>
                        <BreadCrumbs items={subheader.breadcrumbs} />
                    </div>

                    {/* Toolbar */}
                    <div className="d-flex align-items-center">
                        <a
                            href="#"
                            className={`${isTokenToExpire < 1 && "btn-light"} btn font-weight-bold btn-sm ${isTokenToExpire >= 1 && "btn-danger"}`}
                            id="kt_dashboard_daterangepicker"
                            data-toggle="tooltip"
                            title="When your token will expire"
                            data-placement="left"
                        >
                            <span
                                className={`${isTokenToExpire >= 1 && "text-default"} ${isTokenToExpire < 1 && "text-muted"} font-weight-bold mr-2`}
                                id="kt_dashboard_daterangepicker_title"
                            >
                                Token Time:
                            </span>
                            <span
                                className={`${isTokenToExpire >= 1 && "text-white"} ${isTokenToExpire < 1 && "text-primary"} font-weight-bold`}
                                id="kt_dashboard_daterangepicker_date"
                            >
                                {`${isTokenToExpire} hours`}
                            </span>
                        </a>
                        <QuickActions />
                    </div>
                </div>
            </div>

        </>

    );
}
