import React from 'react';
import { VehiclesTable } from './VehiclesTable';
import {
    Button,
} from '@mui/material';

export const GhanaCarbonBikesTable = () => {

    return (
        <>
            <VehiclesTable
                className="card-stretch gutter-b"
                baseFetchUrl={"/organizations/wahu-ghana/carbon-vehicles/getAll"}
                tableTopHeader={"Ghana Carbon Vehicles"}
                tableSubHeader={"Manage Ghana carbon vehicles records from this table"}
            />
        </>
    );

};