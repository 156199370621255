import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { addBattery, editBattery } from "../../../../app/modules/Auth/_redux/authCrud";
import { getStorageItem } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls/';

export function CreateEditBatteryForm({ initialValues, editState, createEditText, fieldDisabled, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        type: Yup.string().required(),
        capacity: Yup.number().required(),
        operatingVoltage: Yup.number().required(),
        peakVoltage: Yup.number().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    addBattery(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        type: values.type,
                        capacity: values.capacity,
                        operatingVoltage: values.operatingVoltage,
                        peakVoltage: values.peakVoltage
                    };

                    editBattery(editPayload, getStorageItem('batteryIdForEdit')).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="batteryFormType">
                    <Form.Label>Battery Chemistry</Form.Label>
                    <Form.Control type="text" placeholder="Battery Type" value={formik.values.type} onChange={formik.handleChange} className={`${getInputClasses("type")}`} name="type" {...formik.getFieldProps("type")} />
                    <Form.Text className="text-muted">
                        Enter Battery Type: eg. LiFeP
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Battery Type field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.type && formik.errors.type ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.type}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="batteryFormCapacity">
                    <Form.Label>Capacity (Ah)</Form.Label>
                    <Form.Control type="number" placeholder="Battery Capacity" value={formik.values.capacity} onChange={formik.handleChange} className={`${getInputClasses("capacity")}`} name="capacity" {...formik.getFieldProps("capacity")} />
                    <Form.Text className="text-muted">
                        Battery Capacity
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Battery Capacity cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.capacity && formik.errors.capacity ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.capacity}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="batteryFormOperatingVoltage">
                    <Form.Label>Operating Voltage (V)</Form.Label>
                    <Form.Control type="number" step={0.01} placeholder="Operating Voltage" value={formik.values.operatingVoltage} onChange={formik.handleChange} className={`${getInputClasses("operatingVoltage")}`} name="operatingVoltage" {...formik.getFieldProps("operatingVoltage")} />
                    <Form.Text className="text-muted">
                        Operating Voltage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Operating voltage cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.operatingVoltage && formik.errors.operatingVoltage ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.operatingVoltage}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="batteryFormPeakVoltage">
                    <Form.Label>Peak Voltage (V)</Form.Label>
                    <Form.Control type="number" step={0.01} placeholder="Operating Voltage" value={formik.values.peakVoltage} onChange={formik.handleChange} className={`${getInputClasses("peakVoltage")}`} name="peakVoltage" {...formik.getFieldProps("peakVoltage")} />
                    <Form.Text className="text-muted">
                        Peak Voltage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Peak voltage cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.peakVoltage && formik.errors.peakVoltage ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.peakVoltage}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}