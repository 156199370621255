export const entityTypesForDeletionArray = [
    "websiteFeedbackForm",
    "organizations",
    "vehicles",
    "driverCohorts",
    "inventoryParts",
    "consultationRequests",
    "contactUsRequest",
    "inventoryParts",
    "drivers",
];

export const riderOnboardingStepNames = [
    "ghanaCard",
    "digitalAddress",
    "infoSession",
    "payment-plan",
    "safetyVideo",
    "maintenanceVideo",
    "deposit",
    "attendInterview",
    "guarantorDetails",
    "homeVisit",
    "driverContractSigned",
    "guarantorContractSigned",
    "bikeReceived",
    "accessoriesReceived",
    "vetting",
];

export const generalFormActions = [
    "activateDriver",
    "deactivateDriver",
    "pauseSubscriptions",
    "unPauseSubscriptions",
];