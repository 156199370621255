import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ButtonGroup,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssignmentTurnedIn, Delete } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateOrganization, assignDriversToOrganization, assignVehiclesToOrganization, deactivateOrganization } from '../../../../app/modules/Auth/_redux/authCrud';
import { AUTH_BASE_URL, FLEET_MANAGING_BASE_URL, setStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import { VehiclesTableSub } from "../advance-tables/VehicleTableSub";
import { CreateEditOrganizationForm, DriversTable, RemoveRecordConfirmForm } from '../';
import { SimpleModal } from '../modals/SimpleModal';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { entityTypesForDeletionArray } from '../../../_helpers/Interfaces';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { shallowEqual, useSelector } from "react-redux";
import BasicLoader from '../../loaders/SimpleLoader';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function OrganizationsTable({ className, onChanger, assignmentType, assignAction, isSelectionAction, selectionAction, vehicleID, assignmentEntityName, driverID, assignmentModalCloser }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [triggerSnackBarOpen, setTriggerSnackBarOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
        country: ""
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Organization');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [showAssignModal, setShowAssignModal] = useState(false);
    const [showDriverAssignModal, setShowDriverAssignModal] = useState(false);
    const [showRecordDeletionModal, setShowRecordDeletionModal] = useState(false);
    const [selectedOrganizationID, setSelectedOrganizationID] = useState("");
    const [selectedOrganizationIDForOrganization, setSelectedOrganizationIDForOrganization] = useState("");
    const [selectedOrganizationData, setSelectedOrganizationData] = useState();

    const [unAssigning, setUnAssigning] = useState(false);
    const [loadingAssignment, setLoadingAssignment] = useState(false);

    const TableErrorMessage = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        name: "",
        address: "",
        email: "",
        phoneNumber: "",
        country: ""
    };

    function triggerSnackBar() {
        setTriggerSnackBarOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setTriggerSnackBarOpen(false);
    }

    async function handleUnAssignment(vehicleID, organizationID) {

        setUnAssigning(true);

        const url = new URL('/vehicles/unassignToOrganization', `${FLEET_MANAGING_BASE_URL}`);
        const requestPayload = {
            vehicleID: vehicleID,
            organizationID: organizationID,
            assignerID: user.user_data.document.ID
        };

        try {

            const response = axios.post(url.href, requestPayload);
            const responseData = (await response).data;

            TableErrorMessage.current = responseData.message;
            setUnAssigning(false);
            setTriggerSnackBarOpen(true);

        } catch (error) {

            TableErrorMessage.current = error.response.data.message ?? "could not unassign vehicle";
            setUnAssigning(false);
            setTriggerSnackBarOpen(true);

            return;

        }

    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const DisplayAssignedEntities = (props) => {
        return (
            <Alert
                icon={false}
                action={
                    <Button
                        startIcon={
                            <Delete />
                        }
                        color="error"
                        size="small"
                        variant="contained"
                        onClick={() => {
                            handleUnAssignment(props.vehicleid, props.organizationid);
                        }}
                    >
                        Un-Assign
                        {" "}
                        {unAssigning && <BasicLoader size={"sm"} />}
                    </Button>
                }
                elevation={7}
                variant="filled"
                {...props}
            >
                {`${props.number + 1}. ${props.vehicleid}`}
            </Alert>
        );
    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/organizations/getAll', `${AUTH_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;
                console.log(data);

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setTriggerSnackBarOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setTriggerSnackBarOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.name,
            id: 'name',
            header: 'Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <img
                        alt="avatar"
                        height={30}
                        src={row.original.document.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                    />
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.email,
            id: "email",
            enableClickToCopy: true,
            header: 'Email'
        },
        {
            accessorFn: (row) => row?.document?.phone,
            id: "phone",
            enableClickToCopy: true,
            header: 'Phone'
        },
        {
            accessorFn: (row) => row?.document?.address,
            id: "address",
            enableClickToCopy: true,
            header: 'Address'
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: "country",
            enableClickToCopy: true,
            header: 'Country'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt).toLocaleDateString(),
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less Than'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={triggerSnackBarOpen}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="secondary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Organization`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditOrganizationForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Select vehicle(s) to be assigned`}
                show={showAssignModal}
                close={() => setShowAssignModal(false)}
                size="xl"
                body={<VehiclesTableSub
                    assignmentType="organization"
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Assign Selected Driver(s) to ${selectedOrganizationData?.name}`}
                show={showDriverAssignModal}
                close={() => setShowDriverAssignModal(false)}
                size="lg"
                infoAnnotation={`Use the checkboxes to select riders you want to assign`}
                body={<DriversTable
                    onChanger={onChangeWorker}
                    organizationID={selectedOrganizationID}
                    assignAction={true}
                    assignmentType={"organization"}
                />}
            />
            <SimpleModal
                modal_tittle={`Delete Record?`}
                show={showRecordDeletionModal}
                close={() => setShowRecordDeletionModal(false)}
                size="md"
                body={<RemoveRecordConfirmForm
                    recordID={selectedOrganizationID}
                    recordData={selectedOrganizationData}
                    onChanger={onChangeWorker}
                    entityType={entityTypesForDeletionArray[1]}
                />}
            />
            <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Organizations
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Organizations from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Record");
                                        setInitialValues(defaultValuesToSet);

                                        setShow(true);
                                    }}
                                >
                                    Create Organization
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <div className='row'>
                                <div className='col-md-4 col-sm-12 col-xs-12'>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="label" className='bold'>Assigned Vehicles: </Typography>
                                        {row.original.document?.vehicles?.map((element, index) => (
                                            <div key={index} className='mb-5'>
                                                <DisplayAssignedEntities severity="info" key={index} vehicleid={element} number={index} organizationid={row.original.document.ID} />
                                            </div>
                                        ))}
                                    </Box>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12'>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <Typography variant="label" className='bold'>Assigned Drivers: </Typography>
                                        {row.original.document?.drivers?.map((element, index) => (
                                            <div key={element} className='mb-5'>
                                                <DisplayAssignedEntities severity="info" key={index} vehicleid={element} number={index} organizationid={row.original.document.ID} />
                                            </div>
                                        ))}
                                    </Box>
                                </div>
                                <div className='col-md-4 col-sm-12 col-xs-12'>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                        <img alt="avatar" height={80} src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")} loading="lazy" style={{ borderRadius: '50%' }}
                                        />
                                        <Box sx={{ textAlign: 'center' }}>
                                            <Typography variant="label" className='bold'>ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.ID}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </div>
                            </div>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const organizationID = row.original.document.ID;
                                    const valuesToSet = {
                                        name: row.original.document.name,
                                        address: row.original.document.address,
                                        email: row.original.document.email,
                                        phoneNumber: row.original.document.phone,
                                        country: row.original.document.country
                                    };

                                    setStorageItem('organizationIdForEdit', organizationID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit Organization
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const organizationID = row.original.document.ID;

                                    setStorageItem('organizationIdForAssignment', organizationID);
                                    setShowAssignModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AssignmentTurnedIn
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                Assign Vehicles
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={() => {

                                    const organizationID = row.original.document.ID;
                                    const organizationData = row.original.document;

                                    setSelectedOrganizationData(organizationData);
                                    setStorageItem('organizationIdForAssignment', organizationID);
                                    setSelectedOrganizationID(organizationID);

                                    setShowDriverAssignModal(true);
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AssignmentTurnedIn
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Assign Riders
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                onClick={() => {

                                    const organizationID = row.original.document.ID;
                                    const organizationData = row.original.document;

                                    setSelectedOrganizationID(organizationID);
                                    setSelectedOrganizationData(organizationData);

                                    setShowRecordDeletionModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteForeverIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                Delete Record
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAssignment = () => {

                                setActionBtnDisabled(true);
                                setLoadingAssignment(true);

                                if (!assignmentType) {

                                    setSeverity('error');
                                    TableErrorMessage.current = "no assignment type set for this action";

                                    setActionBtnDisabled(false);
                                    return triggerSnackBar();

                                }

                                switch ((assignmentType).toLowerCase()) {

                                    case 'vehicle': {

                                        table.getSelectedRowModel().flatRows.map(async (row) => {

                                            if (!vehicleID) {

                                                setSeverity('error');
                                                TableErrorMessage.current = "no vehicle ID set for this action";
                                                setLoadingAssignment(false);

                                                setActionBtnDisabled(false);
                                                return triggerSnackBar();

                                            }

                                            const vehicleAssignmentPayload = ({
                                                organizationID: row.original.document.ID,
                                                vehicleID: vehicleID,
                                                assignerID: user.user_data.document.ID
                                            });

                                            await assignVehiclesToOrganization(vehicleAssignmentPayload).then((organizationAssignmentResponse) => {

                                                setSeverity('success');
                                                TableErrorMessage.current = organizationAssignmentResponse.data.message;
                                                triggerSnackBar();

                                                setReRender(prevState => prevState + 1);
                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);

                                                setTimeout(() => {
                                                    assignmentModalCloser();
                                                }, 2000);

                                                return onChanger();

                                            }).catch((error) => {

                                                setSeverity('error');
                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);

                                                if (error.response) {
                                                    TableErrorMessage.current = error?.response?.data?.message;
                                                    triggerSnackBar();
                                                    return onChanger();
                                                }

                                                TableErrorMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? "an expected error occurred";
                                                triggerSnackBar();
                                                return onChanger();

                                            });

                                        });

                                        break;

                                    }

                                    case 'driver': {

                                        table.getSelectedRowModel().flatRows.map(async (row) => {

                                            if (!driverID) {

                                                setSeverity('error');
                                                TableErrorMessage.current = "no driver ID set for this action";
                                                setLoadingAssignment(false);

                                                setActionBtnDisabled(false);
                                                return triggerSnackBar();

                                            }

                                            const driverAssignmentPayload = ({
                                                organizationID: row.original.document.ID,
                                                driverID: driverID,
                                                assignerID: user.user_data.document.ID
                                            });

                                            await assignDriversToOrganization(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                                setSeverity('success');
                                                TableErrorMessage.current = driverAssignmentResponse.data.message;
                                                triggerSnackBar();

                                                setReRender(prevState => prevState + 1);
                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);

                                                assignmentModalCloser();

                                                return onChanger();

                                            }).catch((error) => {

                                                setSeverity('error');
                                                setActionBtnDisabled(false);

                                                if (error.response) {
                                                    TableErrorMessage.current = error?.response?.data?.message;
                                                    triggerSnackBar();
                                                    return onChanger();
                                                }

                                                TableErrorMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? "an expected error occurred";
                                                triggerSnackBar();
                                                setLoadingAssignment(false);

                                                return onChanger();

                                            });

                                        });

                                        break;

                                    }

                                    default: {

                                        setSeverity('error');
                                        TableErrorMessage.current = 'assignment parent entity not set for this action';

                                        setActionBtnDisabled(false);
                                        setLoadingAssignment(false);

                                        return triggerSnackBar();

                                    }

                                }

                            };

                            const handleSelectionAction = () => {

                                setActionBtnDisabled(true);

                                const selectedRows = table.getSelectedRowModel().rows;
                                setSelectedOrganizationIDForOrganization(selectedRows);
                                selectionAction();

                            };

                            const handleDeactivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateOrganization(row.original.document.ID);
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');
                                        triggerSnackBar();

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');
                                        triggerSnackBar();

                                        setActionBtnDisabled(false);
                                    }
                                });
                            };

                            const handleActivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateOrganization(row.original.document.ID);
                                        triggerSnackBar();
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                    } catch (error) {
                                        triggerSnackBar();
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    {assignAction && <ButtonGroup size="small" aria-label="small button group">
                                        <Button
                                            color="secondary"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled || !assignAction}
                                            onClick={handleAssignment}
                                            variant="contained"
                                            size="small"
                                        >
                                            <span>
                                                {assignmentEntityName}
                                                {" "}
                                                {loadingAssignment && <BasicLoader size={"sm"} />}
                                            </span>
                                        </Button>
                                    </ButtonGroup>}
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate
                                    </Button>
                                    {isSelectionAction && <ButtonGroup size="small" aria-label="small button group">
                                        <Button
                                            color="secondary"
                                            disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled || isSelectionAction}
                                            onClick={handleSelectionAction}
                                            variant="contained"
                                            size="small"
                                        >
                                            Select Organizations
                                        </Button>
                                    </ButtonGroup>}
                                </div>
                            );
                        }}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        enableStickyHeader
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
