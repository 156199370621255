import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import {
    setVehicleCohortSizeLimit
} from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';

export function VehicleCohortSizeLimitForm({ initialValues, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        size: Yup.number().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(async () => {

                return setVehicleCohortSizeLimit(values.size).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        onChanger();
                        return setStatus(response.data.message);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);
                    disableLoading();
                    setSubmitting(false);

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                });

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="size">
                    <Form.Label>Cohort Size Limit</Form.Label>
                    <Form.Control type="number" step={1} placeholder="Cohort Start Date" value={formik.values.size} onChange={formik.handleChange} className={`${getInputClasses("size")}`} name="size" {...formik.getFieldProps("size")} />
                    <Form.Text className="text-muted">
                        Set a Cohort Size Limit
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Cohort size limit cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.size && formik.errors.size ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.size}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    Set Limit
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>

        </>
    );
}