/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import { ContentRoute } from "../../../../_wahu/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_wahu/_assets/sass/pages/login/classic/login-1.scss";

export function AuthPage() {
    return (
        <>
            <div className="d-flex flex-column flex-root">
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
                        style={{
                            backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-9.jpg")})`,
                        }}
                    >
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    className="max-h-80px"
                                    src={toAbsoluteUrl("/media/svg/logos/App-Icon-01.svg")}
                                />
                            </Link>

                            <div className="flex-column-fluid d-flex flex-column justify-content-center">
                                <h3 className="font-size-h1 mb-5">
                                    Wahu OS
                                </h3>
                                <p className="font-weight-lighter opacity-80">
                                    Manufacturing & eMobility Services
                                </p>
                            </div>

                            <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
                                <div className="opacity-70 font-weight-bold	text-wahu-dark">
                                    &copy;
                                    {" "}
                                    {new Date().getFullYear()}
                                    {" "}
                                    <a href="https://wahu.me" className="font-wahu-dark" target="_blank" rel="noopener noreferrer">Wahu Mobility</a>
                                </div>
                                <div className="d-flex">
                                    {/* <Link to="/privacy-policy" className="text-wahu-dark">
                                        Privacy
                                    </Link> */}
                                    <a href="https://support-service-api-62cj62noaq-oa.a.run.app/change-log" target="_blank" rel="noopener noreferrer" className="text-wahu-dark ml-10">
                                        Change log
                                    </a>
                                    {/* <Link to="/contact" className="text-wahu-dark ml-10">
                                        Contact
                                    </Link> */}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                        {/* <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                            <span className="font-weight-bold text-dark-50">
                                Don't have an account yet?
                            </span>
                            <Link
                                to="/auth/registration"
                                className="font-weight-bold ml-2"
                                id="kt_login_signup"
                            >
                                Sign Up!
                            </Link>
                        </div> */}

                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                <ContentRoute path="/auth/login" component={Login} />
                                <ContentRoute path="/auth/registration" component={Registration} />
                                <ContentRoute path="/auth/forgot-password" component={ForgotPassword} />
                                <Redirect from="/auth" exact={true} to="/auth/login" />
                                <Redirect to="/auth/login" />
                            </Switch>
                        </div>

                        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
                            <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                                &copy;
                                {" "}
                                {new Date().getFullYear()}
                                {" "}
                                <a href="https://wahu.me" className="font-wahu-dark" target="_blank" rel="noopener noreferrer">Wahu Mobility</a>
                            </div>
                            <div className="d-flex order-1 order-sm-2 my-2">
                                <Link to="/privacy-policy" className="text-dark-75 text-hover-primary">
                                    Privacy
                                </Link>
                                <Link
                                    to="/changelog"
                                    className="text-dark-75 text-hover-primary ml-4"
                                >
                                    Changelog
                                </Link>
                                <Link
                                    to="/contact"
                                    className="text-dark-75 text-hover-primary ml-4"
                                >
                                    Contact
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
