import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { createSubscriptionPlan, editSubscriptionPlanDetails } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';

export function CreateEditSubscriptionPlan({ initialValues, editState, createEditText, fieldDisabled, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        planTitle: Yup.string().required(),
        planPrice: Yup.number().required(),
        planBenefits: Yup.string().required(),
        planDuration: Yup.string().required(),
        weekNumber: Yup.number().required(),
        dailyPaymentBuffer: Yup.number().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            const benefitsArray = values.planBenefits?.split(";");
            values.planBenefits = benefitsArray;

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    createSubscriptionPlan(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const id = getStorageItem('subscriptionForEdit');
                    const editPayload = {
                        planTitle: values.planTitle,
                        planPrice: values.planPrice,
                        planBenefits: values.planBenefits,
                        planDuration: values.planDuration,
                        isRecommended: values.isRecommended,
                        weekNumber: values.weekNumber,
                        dailyPaymentBuffer: values.dailyPaymentBuffer,
                    };

                    editSubscriptionPlanDetails(editPayload, id).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    return (

        <>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="subscriptionTitle">
                    <Form.Label>
                        Plan Title
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="Subscription Title" value={formik.values.planTitle} onChange={formik.handleChange} className={`${getInputClasses("planTitle")}`} name="planTitle" {...formik.getFieldProps("planTitle")} />
                    <Form.Text className="text-muted">
                        Provide a descriptive title for the plan eg.Own your bike after 24 months
                    </Form.Text>
                    <Form.Control.Feedback>Your subscription title looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Subscription title cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.planTitle && formik.errors.planTitle ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.planTitle}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subscriptionPrice">
                    <Form.Label>
                        Subscription Unit Price
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="300" value={formik.values.planPrice} onChange={formik.handleChange} className={`${getInputClasses("planPrice")}`} name="planPrice" {...formik.getFieldProps("planPrice")} />
                    <Form.Text className="text-muted">
                        Provide a unit price for the subscription plan
                    </Form.Text>
                    <Form.Control.Feedback>Your last name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Unit price for the subscription plan cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.planPrice && formik.errors.planPrice ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.planPrice}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subscriptionBenefits">
                    <Form.Label>
                        Subscription Benefits
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control as="textarea" value={formik.values.planBenefits} rows={3} onChange={formik.handleChange} className={`${getInputClasses("planBenefits")}`} name="planBenefits" {...formik.getFieldProps("planBenefits")} />
                    <Form.Text className="text-danger">
                        Separate each benefit by a semi-colon(;)
                    </Form.Text>
                    <Form.Control.Feedback>Your subscription benefits looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Subscription benefits cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.planBenefits && formik.errors.planBenefits ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.planBenefits}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subscriptionPaymentFrequency">
                    <Form.Label>
                        Payment Frequency
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.planDuration} onChange={formik.handleChange} className={`${getInputClasses("planDuration")}`} name="planDuration" {...formik.getFieldProps("planDuration")}>
                        <option defaultValue="Select one" disabled value="Select one">Select one</option>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="monthly">Monthly</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        When is the subscription due eg. daily, weekly
                    </Form.Text>
                    <Form.Control.Feedback>Due period looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Due periodical field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.planDuration && formik.errors.planDuration ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.planDuration}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subscriptionWeeksNumber">
                    <Form.Label>
                        Plan Duration in Weeks
                        <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="72" value={formik.values.weekNumber} onChange={formik.handleChange} className={`${getInputClasses("weekNumber")}`} name="weekNumber" {...formik.getFieldProps("weekNumber")} />
                    <Form.Text className="text-muted">
                        Number of weeks in plan duration
                    </Form.Text>
                    <Form.Control.Feedback>Your plan duration looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Plan duration cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.weekNumber && formik.errors.weekNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.weekNumber}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subscriptionDailyPaymentBuffer">
                    <Form.Label>
                        Daily Payment Buffer
                        <span className="text-primary">(Optional)</span>
                    </Form.Label>
                    <Form.Control type="number" placeholder="60" value={formik.values.dailyPaymentBuffer} onChange={formik.handleChange} className={`${getInputClasses("dailyPaymentBuffer")}`} name="dailyPaymentBuffer" {...formik.getFieldProps("dailyPaymentBuffer")} />
                    <Form.Text className="text-muted">
                        Daily Payment Buffer
                    </Form.Text>
                    <Form.Control.Feedback>Your daily payment buffer looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Daily Payment Buffer cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.dailyPaymentBuffer && formik.errors.dailyPaymentBuffer ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.dailyPaymentBuffer}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>
                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button
                    variant="primary"
                    type="submit"
                    disabled={formik.isSubmitting}
                    className={`font-weight-bold px-9 py-4 my-3`}
                >
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>

    );
}