import React, { useEffect, useMemo, useRef, useState } from "react";
import { PAYMENT_BASE_URL } from "../../../../app/services/baseService";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
    Box,
    IconButton,
    Snackbar,
    ListItemIcon,
    Chip,
    MenuItem,
    Button,
    Alert,
    styled,
    LinearProgress,
    linearProgressClasses,
    Tooltip,
    Grid,
} from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import CloseIcon from '@mui/icons-material/Close';
import VerifiedIcon from '@mui/icons-material/Verified';
import { ExportToCsv } from 'export-to-csv';
import { toAbsoluteUrl } from "../../../_helpers";

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    invoiceContainer: {
        width: 200
    },
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export function DriverTransactions({ driverID, driverName, driverData }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [severity, setSeverity] = useState('info');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [specDriverData, specSpecDriverData] = useState(null);

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    }

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            setIsError(false);
            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(`/payments/getTransactions/${driverID}`, `${PAYMENT_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    const dataArrayDocument = {
                        ID: element.document.ID,
                        amount: element.document.metadata.provider === "Paystack" ? Number(element.document.metadata.amount) / 100 : Number(element.document.metadata.amount),
                        firstName: element.document.metadata.firstName,
                        lastName: element.document.metadata.lastName,
                        isFirstPayment: element.document.metadata.isFirstPayment,
                        isSubscriptionPayment: element.document.metadata.isSubscriptionPayment,
                        phone: element.document.metadata.phone,
                        provider: element.document.metadata.provider,
                        weeksPaid: element.document.metadata.weeksPaid,
                        createdAt: new Date(element.document.createdAt).toUTCString(),
                        status: element?.document?.verificationData?.data?.status ?? "failed",
                        paidAt: element?.document?.verificationData?.data?.paid_at ? new Date(element.document.verificationData.data.paid_at).toUTCString() : "N/A"
                    };
                    dataArrayAlt.push(dataArrayDocument);

                    if ((i + 1) >= dataArray.length)
                        isMounted && setDataDocument(dataArrayAlt);

                }

                setData(data.data);
                setRowCount(data.max_count);

                setIsLoading(false);
                setIsRefetching(false);

            } catch (error) {

                setIsError(true);
                setSeverity("error");
                setIsLoading(false);
                setIsRefetching(false);

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    TableErrorMessage.current = "network error, please check your internet connection";
                    triggerSnackBar();

                    return;

                }

                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                triggerSnackBar();

                return;

            }

        };

        isMounted && fetchData();
        isMounted && specSpecDriverData(driverData);

        return () => isMounted = false;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        driverData,
        driverID,
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'createdAt',
            header: 'Transaction Date',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            grow: true,
            size: 350,
        },
        {
            accessorFn: (row) => `${row?.document?.verificationData?.data?.status ?? "initiated"}`,
            id: "isTransactionComplete",
            header: 'Status',
            Cell: ({ cell }) => {

                let chipColor;
                const status = cell.getValue();
                switch (status) {
                    case 'success':
                        chipColor = 'success';
                        break;
                    case 'failed':
                        chipColor = 'error';
                        break;
                    case 'abandoned':
                        chipColor = 'warning';
                        break;
                    case 'ongoing':
                        chipColor = 'info';
                        break;
                    default:
                        chipColor = 'warning';
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                            fontWeight: "bold"
                        }}
                    >
                        <Chip label={status} color={chipColor} />
                    </Box >
                );
            },
            grow: true,
        },
        {
            accessorFn: (row) => `${row?.document?.reference ?? 'NA'}`,
            id: "reference",
            enableClickToCopy: true,
            header: 'Payment Reference',
            grow: true,
        },
        {
            accessorFn: (row) => `${row?.document?.verificationData?.data?.amount ? Number(row?.document?.verificationData?.data?.amount) / 100 : 'Failed'}`,
            enableClickToCopy: true,
            id: 'verificationDataAmount',
            header: 'Amount Paid (GHS)',
            Cell: ({ cell, row }) => {

                let chipColor;
                const status = cell.getValue();
                switch (status) {
                    case 'Failed':
                        chipColor = 'error';
                        break;
                    default:
                        chipColor = 'default';
                }

                return (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                        }}
                    >
                        <Chip label={status} color={chipColor} />
                    </Box>
                );
            },
            grow: true,
        },
        {
            accessorFn: (row) => `${row?.document?.verificationData?.data?.metadata?.weeksPaid ?? 'NA'}`,
            id: "weeksPaid",
            enableClickToCopy: true,
            header: '# of Weeks Paid For',
            grow: true,
        },
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: `Subscription Transactions for ${driverName}`,
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => ({
            ID: row.original.document.ID,
            amount: row.original.document.metadata.provider === "Paystack" ? Number(row.original.document.metadata.amount) / 100 : Number(row.original.document.metadata.amount),
            firstName: row.original.document.metadata.firstName,
            lastName: row.original.document.metadata.lastName,
            isFirstPayment: row.original.document.metadata.isFirstPayment,
            isSubscriptionPayment: row.original.document.metadata.isSubscriptionPayment,
            phone: row.original.document.metadata.phone,
            provider: row.original.document.metadata.provider,
            weeksPaid: row.original.document.metadata.weeksPaid,
            createdAt: new Date(row.original.document.createdAt).toUTCString(),
            status: row.original?.document?.verificationData?.data?.status ?? "failed",
            paidAt: row.original?.document?.verificationData?.data?.paid_at ? new Date(row.original.document.verificationData.data.paid_at).toUTCString() : "N/A"
        })));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    const transactionProgressValue = (Number(specDriverData?.document?.successfulTransactionCount ?? 0) / ((Number(specDriverData?.document?.subscriptionPlanWeeksOutStanding ?? 0) + Number(specDriverData?.document?.successfulTransactionCount ?? 0)))) * 100;

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className="driver-inner-profile-container">
                <div className="mt-1 rounded mb-1">
                    <div className="mb-3">
                        {specDriverData?.invoices === null ? (<>
                            <div className="centerMan mb-2">
                                <span className="bg-light-danger pl-2 pr-2 rounded">
                                    no invoices generated because this rider has not yet selected a payment plan, you can correct this on the onboarding tab
                                </span>
                            </div>
                        </>)
                            : (<>

                                <div className="centerMan mb-5">

                                </div>

                                <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>

                                    {specDriverData?.invoices?.map((invoice, index) => {

                                        const isInvoicePaid = invoice.paid;
                                        let invoiceDueDate = new Date().toUTCString();
                                        if (typeof (invoice.dueDate) === "object") {
                                            invoiceDueDate = new Date(invoice.dueDate._seconds * 1000 + invoice.dueDate._nanoseconds / 1000000).toDateString();
                                        } else {
                                            invoiceDueDate = new Date(invoice.dueDate).toDateString();
                                        }

                                        return (
                                            <Grid item xs={2} sm={4} md={4} key={invoiceDueDate}>
                                                <div className={`bg-light-${isInvoicePaid ? "success" : "primary"} p-2 rounded`}>
                                                    <div className="">
                                                        <h4>
                                                            {index + 1}
                                                            {"."}
                                                        </h4>
                                                    </div>
                                                    <div className="">
                                                        Due date:
                                                        {" "}
                                                        {invoiceDueDate}
                                                    </div>
                                                    <div className="">
                                                        Status:
                                                        {" "}
                                                        {isInvoicePaid ? (<>
                                                            <Chip label={"Paid"} color={"success"} size="sm" />
                                                        </>) : (<>
                                                            <Chip label={"Not Paid"} color={"error"} size="sm" />
                                                        </>)}
                                                    </div>
                                                </div>
                                            </Grid>
                                        );

                                    })}
                                </Grid>

                            </>)
                        }
                    </div>
                    {specDriverData && <div className="flex-grow-1 mt-1 p-8 rounded-xl bgi-no-repeat justify-content-center align-items-start bg-light-primary"
                        style={{
                            backgroundPosition: "right bottom",
                            backgroundSize: "auto 100%",
                            backgroundImage: `url(${toAbsoluteUrl("/media/products/wallet.webp")})`
                        }}
                    >
                        <div className="mb-2">
                            <span className="font-size-lg font-weight-bolder">
                                {specDriverData?.user_data?.document?.successfulTransactionCount ?? 0}
                                {"/"}
                                {(Number(specDriverData?.user_data?.document?.subscriptionPlanWeeksOutStanding ?? 0) + Number(specDriverData?.user_data?.document?.successfulTransactionCount ?? 0))}
                                {" weeks"}
                            </span>
                            <br />
                            <BorderLinearProgress variant="determinate" value={transactionProgressValue ? transactionProgressValue : 0} />
                        </div>
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12">
                                <div
                                    className="p-2 rounded-lg"
                                    style={{
                                        backgroundColor: "#ffffffbf",
                                    }}
                                >
                                    <span className="text-dark-80">
                                        Amount Collected
                                    </span>
                                    <br></br>
                                    <span className="text-dark-100 font-weight-bolder">
                                        GHS
                                        {" "}
                                        {specDriverData?.user_data?.document?.wallet?.subscriptionBalance ?? 0}
                                    </span>
                                    <div className="centerMan">
                                        {specDriverData?.document?.isSubscriptionsPaused ? (<>
                                            <Tooltip
                                                title={
                                                    <React.Fragment>
                                                        {specDriverData?.user_data?.document?.subscriptionPauseNotes?.map((item, index) => {

                                                            return (
                                                                <div key={index} className="mb-1">
                                                                    <span>
                                                                        {item.note}
                                                                    </span>
                                                                    <br />
                                                                    <span className="pl-1 pr-1 bg-primary rounded-lg">
                                                                        {new Date(item.createdAt).toLocaleDateString()}
                                                                    </span>
                                                                </div>
                                                            );

                                                        })}
                                                    </React.Fragment>
                                                }
                                                placement="top"
                                                arrow
                                            >
                                                <Alert
                                                    variant="outlined"
                                                    color="error"
                                                    severity="error"
                                                >
                                                    Subscription Paused
                                                </Alert>
                                            </Tooltip>
                                        </>) : ""}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
                </div>
                <div className="mt-5 rounded-xl mb-5">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    onChangeWorker();
                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <VerifiedIcon
                                        color="primary"
                                    />
                                </ListItemIcon>
                                Verify Transaction
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            return (

                                <>
                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                        <Button
                                            disabled={!table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()}
                                            onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                            variant="contained"
                                            size='small'
                                            className="text-nowrap"
                                            color="info"
                                            fullWidth
                                        >
                                            Export Selected Rows
                                        </Button>
                                        <Button
                                            onClick={handleExportData}
                                            variant="contained"
                                            size='small'
                                            className="text-nowrap"
                                            color="info"
                                            fullWidth
                                        >
                                            Export Paged Data
                                        </Button>
                                    </div>
                                </>

                            );

                        }}
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        enableStickyFooter
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        muiSelectCheckboxProps={{
                            color: 'primary',
                        }}
                        muiTableBodyRowProps={({ row, table }) => {
                            const { density } = table.getState();
                            return {
                                sx: {
                                    //Set a fixed height for pinned rows
                                    height: row.getIsPinned() ?
                                        `${density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69}px`
                                        :
                                        undefined,
                                },
                            };
                        }}
                        autoResetSelectedRows={false}
                        autoResetPageIndex={false}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}