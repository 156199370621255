import styled from "@emotion/styled";
import {
    Box,
    Button,
    Grid,
    IconButton,
    Paper,
    Typography,
    Snackbar,
    MenuItem,
    ListItemIcon,
    Alert
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useMemo, useRef, useState } from "react";
import { MaterialReactTable } from "material-react-table";
import { AUTH_BASE_URL, setStorageItem } from "../../../../app/services/baseService";
import axios from "axios";
import { convertUnixTimestampToUTC } from "../../../_helpers";
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import VerifiedTwoToneIcon from '@mui/icons-material/VerifiedTwoTone';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import MoreVertTwoToneIcon from '@mui/icons-material/MoreVertTwoTone';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { SimpleModal } from "../modals/SimpleModal";
import { CreateEditVendorAccountForm } from "../forms/CreateEditVendorAccountForm";
import { confirmVendorAccount } from "../../../../app/modules/Auth/_redux/authCrud";
import { UpdateVendorPhoneNumberForm } from "../forms/UpdateVendorPhoneNumberForm";
import { UpdateVendorEmailForm } from "../forms/UpdateVendorEmailForm";
import { VendorDetailsList } from "../lists/VendorDetailsList";



const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function VendorAccountsTable({ className }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showUpdatePhoneNumber, setShowUpdatePhoneNumber] = useState(false);
    const [showUpdateEmail, setShowUpdateEmail] = useState(false);
    const [showVendorDetails, setShowVendorDetails] = useState(false);
    const [initialValues, setInitialValues] = useState({
        contactFirstName: "",
        contactLastName: "",
        businessName: "",
        country: "",
        phoneNumber: "",
        email: "",
        businessRegistrationNumber: "",
        locationLat: 0,
        locationLng: 0,
        locationName: "",
        requestID: ""
    });

    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Tracker');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');

    const defaultValuesToSet = {
        contactFirstName: "",
        contactLastName: "",
        businessName: "",
        country: "",
        phoneNumber: "",
        email: "",
        businessRegistrationNumber: "",
        locationLat: 0,
        locationLng: 0,
        locationName: "",
        requestID: ""
    };

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/users/vendors/getAll', `${AUTH_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {
                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }
        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.businessName,
            id: 'businessName',
            header: 'Business Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => `${row?.document?.contactFirstName} ${row?.document?.contactLastName}`,
            id: 'contactName',
            header: 'Contact Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.dataConfirmed,
            header: 'Is Vendor Data Confirmed?',
            Cell: ({ cell, }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Confirmed" : "Unconfirmed"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: 'country',
            header: 'Country',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.location?.name,
            id: 'locationName',
            header: 'Location',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.email,
            id: 'email',
            header: 'Email',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber,
            id: 'phoneName',
            header: 'Phone Number',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },

    ], []);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Vendor Account`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditVendorAccountForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Update Phone Number`}
                show={showUpdatePhoneNumber}
                size="lg"
                close={() => setShowUpdatePhoneNumber(false)}
                body={
                    <UpdateVendorPhoneNumberForm
                        onChanger={onChangeWorker}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`Update Email`}
                show={showUpdateEmail}
                size="lg"
                close={() => setShowUpdateEmail(false)}
                body={
                    <UpdateVendorEmailForm
                        onChanger={onChangeWorker}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`Show Vendor Details`}
                show={showVendorDetails}
                size="lg"
                close={() => setShowVendorDetails(false)}
                body={
                    <VendorDetailsList />
                }
            />

            <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Vendor Accounts
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage all vendor accounts here
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {

                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Account");
                                        setInitialValues(defaultValuesToSet);

                                        setShow(true);

                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Vendor Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        data={data}
                        columns={columns}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <div>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Role Description: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.roleDescription}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>CreatedAt: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const requestID = row.original.document.ID;
                                    const locationLat = `${row.original.document.location.lat}`;
                                    const locationLng = `${row.original.document.location.lat}`;
                                    const valuesToSet = {
                                        contactFirstName: row.original.document.contactFirstName,
                                        contactLastName: row.original.document.contactLastName,
                                        businessName: row.original.document.businessName,
                                        country: row.original.document.country,
                                        phoneNumber: row.original.document.phoneNumber,
                                        tags: [],
                                        email: row.original.document.email,
                                        businessRegistrationNumber: row.original.document.ID,
                                        locationLat: locationLat,
                                        locationLng: locationLng,
                                        locationName: row.original.document.location.name,
                                        requestID: row.original.document.ID
                                    };

                                    setStorageItem('requestIDForEdit', requestID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Update Account");
                                    setInitialValues(valuesToSet);
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <ModeEditTwoToneIcon
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                Edit Vendor Details
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const vendorID = row.original.document.ID;
                                    const requestID = row.original.document.requestID;
                                    const locationLat = `${row.original.document.location.lat}`;
                                    const locationLng = `${row.original.document.location.lat}`;

                                    const valuesToSet = {
                                        contactFirstName: row.original.document.contactFirstName,
                                        contactLastName: row.original.document.contactLastName,
                                        businessName: row.original.document.businessName,
                                        country: row.original.document.country,
                                        phoneNumber: row.original.document.phoneNumber,
                                        tags: [],
                                        email: row.original.document.email,
                                        businessRegistrationNumber: row.original.document.businessRegistrationNumber,
                                        locationLat: locationLat,
                                        locationLng: locationLng,
                                        locationName: row.original.document.location.name,
                                    };

                                    confirmVendorAccount(vendorID, requestID, valuesToSet).then((response) => {

                                        if (response) {

                                            TableErrorMessage.current = response.data.message;
                                            setSeverity('success');
                                            setReRender(prevState => prevState + 1);
                                            setActionBtnDisabled(false);
                                            triggerSnackBar();

                                        }

                                        closeMenu();

                                    }).catch((error) => {

                                        TableErrorMessage.current = error.response.data.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    });

                                }}
                            >
                                <ListItemIcon>
                                    <VerifiedTwoToneIcon color="primary" />
                                </ListItemIcon>
                                Confirm Details
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const vendorID = row.original.document.ID;
                                    setStorageItem('vendorIdForEdit', vendorID);
                                    setShowUpdatePhoneNumber(true);

                                    closeMenu();

                                }}
                            >
                                <ListItemIcon>
                                    <LocalPhoneTwoToneIcon color="secondary" />
                                </ListItemIcon>
                                Update Phone Number
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={() => {

                                    const vendorID = row.original.document.ID;
                                    setStorageItem('vendorIdForEdit', vendorID);
                                    setShowUpdateEmail(true);

                                    closeMenu();

                                }}
                            >
                                <ListItemIcon>
                                    <EmailTwoToneIcon color="secondary" />
                                </ListItemIcon>
                                Update Email
                            </MenuItem>,
                            <MenuItem key={4} onClick={() => {
                                setShowVendorDetails(true);
                                closeMenu();
                            }}>
                                <ListItemIcon>
                                    <MoreVertTwoToneIcon color="secondary" />
                                </ListItemIcon>
                                See Vendor Details
                            </MenuItem>,
                        ]}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div >
        </>
    );

}
