/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from '@mui/styles';
import {
    IconButton,
    Snackbar,
    Grow,
    Button,
    Alert,
} from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { CreateEditVehicleCohortForm } from "../forms/CreateEditVehicleCohortForm";
import { SimpleModal } from "../modals/SimpleModal";
import { FLEET_MANAGING_BASE_URL } from "../../../../app/services/baseService";
import axios from "axios";
import BasicLoader from "../../loaders/SimpleLoader";
import { VehicleCohortSizeLimitForm } from "../forms/VehicleCohortSizeLimitForm";
import { useHistory } from "react-router-dom";
import CloseIcon from '@mui/icons-material/Close';
import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import ArrowOutwardTwoToneIcon from '@mui/icons-material/ArrowOutwardTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`
    }
}));

export function VehicleCohortsWidget({ className }) {

    const classes = useStyles();
    const navigate = useHistory();
    const widgetErrorMessage = useRef();

    const [showCohortCreationModal, setShowCohortCreationModal] = useState(false);
    const [initialValues, setInitialValues] = useState(null);
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Vehicle Cohort');
    const [reRender, setReRender] = useState(0);

    const [showCohortSizeLimitModal, setShowCohortSizeLimitModal] = useState(false);

    const [vehicleCohortSizeLimit, setVehicleCohortSizeLimit] = useState(0);
    const [isLoadingSizeLimit, setIsLoadingSizeLimit] = useState(false);

    const [isLimitFetchError, setIsLimitFetchError] = useState(false);
    const [isCohortFetchError, setIsCohortFetchError] = useState(false);

    const [isLoadingCohorts, setIsLoadingCohorts] = useState(false);
    const [vehicleCohorts, setVehicleCohorts] = useState([]);
    const [openSnackBar, setOpenSnackBar] = useState(false);
    const [severity, setSeverity] = useState('info');

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackBar(false);
    }

    function triggerSnackBar() {
        setOpenSnackBar(true);
    }

    useEffect(() => {

        let isMounted = true;

        const getVehicleCohortSizeLimit = async () => {

            setIsLoadingSizeLimit(true);
            setIsLimitFetchError(false);

            const url = new URL('/vehicles/cohorts/size', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                setVehicleCohortSizeLimit(data?.size ?? 0);
                setIsLoadingSizeLimit(false);

            } catch (error) {

                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohort size limit: ${error?.response?.data?.message}` : `error loading vehicle cohort size limit: ${error?.message}`;
                setSeverity("error");
                triggerSnackBar();

                setIsLimitFetchError(true);
                setIsLoadingSizeLimit(false);

                return;

            }

        };

        const getVehicleCohorts = async () => {

            setIsLoadingCohorts(true);
            setIsCohortFetchError(false);

            handleSnackbarClose();

            const url = new URL('/vehicles/cohorts/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                setVehicleCohorts(data.data);
                setIsLoadingCohorts(false);

            } catch (error) {

                widgetErrorMessage.current = error?.response?.data?.message ? `error loading vehicle cohorts: ${error?.response?.data?.message}` : `error loading vehicle cohorts ${error?.message}`;
                setSeverity("error");
                triggerSnackBar();

                setIsCohortFetchError(true);
                setIsLoadingCohorts(false);

                return;

            }

        };

        isMounted && getVehicleCohortSizeLimit();
        isMounted && getVehicleCohorts();

        return () => isMounted = false;

    }, [reRender]);

    return (
        <>
            <Grow
                in={openSnackBar}
                style={{ transformOrigin: '0 0 0' }}
                {...(openSnackBar ? { timeout: 500 } : {})}
            >
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={openSnackBar}
                    autoHideDuration={10000}
                    onClose={handleSnackbarClose}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    action={[
                        <Button
                            key="undo"
                            color="inherit"
                            size="small"
                            onClick={handleSnackbarClose}
                        >
                            UNDO
                        </Button>,
                        <IconButton
                            key="close"
                            aria-label="Close"
                            color="inherit"
                            className={classes.close}
                            onClick={handleSnackbarClose}
                        >
                            <CloseIcon />
                        </IconButton>,
                    ]}
                >
                    <Alert
                        elevation={6}
                        variant="filled"
                        onClose={handleSnackbarClose}
                        severity={severity}
                    >
                        {widgetErrorMessage.current}
                    </Alert>
                </Snackbar>
            </Grow>
            <SimpleModal
                modal_tittle={`Create Vehicle Cohort`}
                show={showCohortCreationModal}
                close={() => setShowCohortCreationModal(false)}
                size="xl"
                body={<CreateEditVehicleCohortForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Set Vehicle Cohort Size Limit`}
                show={showCohortSizeLimitModal}
                close={() => setShowCohortSizeLimitModal(false)}
                size="md"
                body={<VehicleCohortSizeLimitForm
                    initialValues={initialValues}
                    onChanger={onChangeWorker}
                />}
            />
            <div className={`card card-custom ${className} rounded-xl`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Vehicle Cohorts
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage vehicle cohorts from this window
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="success"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {

                                        setCreateEditText("Create Cohort");
                                        setEditState(false);
                                        setInitialValues({
                                            creationDate: "",
                                            name: "",
                                            vehicleType: "",
                                        });

                                        return setShowCohortCreationModal(true);

                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Vehicle Cohort
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <div className="row m-0">
                        <div className="col bg-light-success px-6 py-8 rounded-xl mb-7">
                            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                <span className="text-success font-weight-bold font-size-h2 mt-2">
                                    {isLoadingSizeLimit && <BasicLoader size={"lg"} />}
                                    {!isLoadingSizeLimit && vehicleCohortSizeLimit}
                                    {isLimitFetchError && (
                                        <>
                                            <ErrorTwoToneIcon color="error" />
                                            {" "}
                                            <span className="text-danger font-size-h6">
                                                {widgetErrorMessage.current}
                                            </span>
                                        </>
                                    )}
                                </span>
                            </span>
                            <span className="text-success font-weight-bold font-size-h6 mt-2">
                                Vehicle Cohort Size Limit
                            </span>
                            <div className="row mt-5">
                                <div className="col">
                                    <Button
                                        variant="contained"
                                        color="success"
                                        size="medium"
                                        onClick={() => {

                                            setInitialValues({
                                                size: vehicleCohortSizeLimit
                                            });

                                            return setShowCohortSizeLimitModal(true);

                                        }}
                                    >
                                        Set New Size Limit
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="centerMan">
                        {isLoadingCohorts && (
                            <>
                                <div className="centerMan">
                                    <BasicLoader size={"lg"} />
                                </div>
                            </>
                        )}
                    </div>
                    {isCohortFetchError && (<>
                        <div className="centerMan">
                            <ErrorTwoToneIcon color="error" fontSize="large" />
                            <div className="text-danger">
                                {widgetErrorMessage.current}
                            </div>
                        </div>
                    </>)}
                    <div className="row m-0 mt-5">
                        {!isLoadingCohorts && vehicleCohorts.map((item, index) => {

                            return (
                                <Grow
                                    in={!isLoadingCohorts}
                                    style={{ transformOrigin: '0 1 0' }}
                                    {...(!isLoadingCohorts ? { timeout: 500 } : {})}
                                    key={index}
                                >
                                    <div
                                        className="col bg-light-primary px-6 py-8 rounded-xl mr-7 ml-7 mb-7 cursor-pointer"
                                        onClick={() => {
                                            navigate.push('/vehicles/cohorts/details', { cohortID: item.document_id });
                                        }}
                                    >
                                        <span className="svg-icon svg-icon-3x svg-icon-primary d-block">
                                            <span className="text-primary font-weight-bold font-size-h3">
                                                {item.document.name}
                                            </span>
                                            <br />
                                            <small className="text-primary text-nowrap">
                                                {item.document_id}
                                            </small>
                                        </span>
                                        <span className="text-primary mt-2">
                                            <span className="font-size-h6">
                                                {new Date(item.document.creationDate).toDateString()}
                                            </span>
                                            <br />
                                            <span className="font-size-h6">
                                                {item.document.vehicles.length}
                                                {" Vehicles"}
                                            </span>
                                        </span>
                                        <div className="mt-2 centerMan">
                                            <span className="font-size-sm">
                                                Click for more details
                                                {" "}
                                                <ArrowOutwardTwoToneIcon />
                                            </span>
                                        </div>
                                    </div>
                                </Grow>
                            );

                        })}
                    </div>
                    {/* <div className="row m-0">
                        <div className="col bg-light-danger px-6 py-8 rounded-xl mr-7">
                            <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                                ></SVG>
                            </span>
                            <a
                                href="#"
                                className="text-danger font-weight-bold font-size-h6 mt-2"
                            >
                                Item Orders
                            </a>
                        </div>
                        <div className="col bg-light-success px-6 py-8 rounded-xl">
                            <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                                <SVG
                                    src={toAbsoluteUrl(
                                        "/media/svg/icons/Communication/Urgent-mail.svg"
                                    )}
                                ></SVG>
                            </span>
                            <a
                                href="#"
                                className="text-success font-weight-bold font-size-h6 mt-2"
                            >
                                Bug Reports
                            </a>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    );
}
