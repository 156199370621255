import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createVehicleRecord, editVehicleRecord } from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls/';
import BasicLoader from '../../loaders/SimpleLoader';
import { toAbsoluteUrl } from "../../../_helpers";

export function CreateEditVehicleForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, vehicleID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [specVehicleModelData, setVehicleModelData] = useState([]);
    const [specBatteryTypeData, setBatteryTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showLoader, setShowLoader] = useState('d-none');
    const [profileImageURL, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState("");

    const TableErrorMessage = useRef();

    const creationSchema = Yup.object().shape({
        VIN: Yup.string().required(),
        vehicleModelID: Yup.string().required(),
        batteryID: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const handleLoadingAndErrors = () => {
        if (isLoading)
            return true;
        if (isRefetching)
            return true;

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (profileImageFile)
                    values.profileImage = profileImageFile;

                if (!editState) {

                    createVehicleRecord(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        id: vehicleID,
                        vehicleModelID: values.vehicleModelID,
                        VIN: values.VIN,
                        batteryID: values.batteryID
                    };

                    if (profileImageFile)
                        editPayload.profileImage = profileImageFile;

                    editVehicleRecord(editPayload).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    const getUserPic = () => {
        if (!profileImageURL) {
            return "none";
        }

        return `url(${profileImageURL})`;
    };

    const handleNewProfileImage = (event) => {

        let file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            let reader = new FileReader();
            let url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                setProfileImage(reader.result);
            };
            console.log(url);
        }

    };

    const removePic = () => {
        setProfileImage("");
    };

    const makeOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.vehicleType.name}
            {" - "}
            {X.document.motor.name}
            {" - "}
            {X.document.battery.name}
            {" - "}
            {X.document.manufacturer.name}
        </option>;
    };

    const makeBatteryOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
            {" - "}
            {X.document.capacity}
            mAh
            {" - "}
            {X.document.operatingVoltage}
            V
            {" - "}
            {X.document.peakVoltage}
            V
        </option>;
    };

    useEffect(() => {

        let isMounted = true;

        const fetchVehicleModelData = async () => {

            setShowLoader('');

            if (!specVehicleModelData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/vehicleModels/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setVehicleModelData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        const fetchBatteryTypeData = async () => {

            setShowLoader('');

            if (!specBatteryTypeData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/batteries/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setBatteryTypeData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        fetchVehicleModelData();
        fetchBatteryTypeData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <div className="form-group row">
                <div className="col-lg-12 col-xl-12">
                    <div className="centerMan">
                        <div
                            className="image-input image-input-outline"
                            id="kt_profile_avatar"
                            style={{
                                backgroundImage: `url(${toAbsoluteUrl(
                                    "/media/users/blank.png"
                                )}`,
                            }}
                        >
                            <div
                                className="image-input-wrapper"
                                style={{ backgroundImage: `${getUserPic()}` }}
                            />
                            <label
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                            >
                                <i className="fa fa-pen icon-sm text-muted"></i>
                                <input
                                    type="file"
                                    name="pic"
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e => handleNewProfileImage(e)}
                                />
                                <input type="hidden" name="profile_avatar_remove" />
                            </label>
                            <span
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="cancel"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Cancel avatar"
                            >
                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                            <span
                                onClick={removePic}
                                className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                data-action="remove"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Remove avatar"
                            >
                                <i className="ki ki-bold-close icon-xs text-muted"></i>
                            </span>
                        </div>
                        <span className="form-text text-muted">
                            Allowed file types: png, jpg, jpeg.
                        </span>
                    </div>
                </div>
            </div>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="vehicleFormVIN">
                    <Form.Label>VIN</Form.Label>
                    <Form.Control type="text" placeholder="Vehicle Identification Number / Serial Number" value={formik.values.VIN} onChange={formik.handleChange} className={`${getInputClasses("VIN")}`} name="VIN" {...formik.getFieldProps("VIN")} />
                    <Form.Text className="text-muted">
                        Vehicle Identification Number / Serial Number
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle Identification Number / Serial Number cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.VIN && formik.errors.VIN ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.VIN}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormModelID">
                    <Form.Label>Vehicle Model</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.vehicleModelID} onChange={formik.handleChange} className={`${getInputClasses("vehicleModelID")}`} name="vehicleModelID" {...formik.getFieldProps("vehicleModelID")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        {specVehicleModelData.map(makeOption)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Vehicle Type - Motor Type - Battery Type - Manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle model selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.vehicleModelID && formik.errors.vehicleModelID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.vehicleModelID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormBatteryID">
                    <Form.Label>Battery Type</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.batteryID} onChange={formik.handleChange} className={`${getInputClasses("batteryID")}`} name="batteryID" {...formik.getFieldProps("batteryID")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        {specBatteryTypeData.map(makeBatteryOption)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Chemistry - Capacity - Operating Voltage - Peak Voltage
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Battery type selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.batteryID && formik.errors.batteryID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.batteryID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>

        </>
    );
}