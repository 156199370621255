import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { sendBulkMessages } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';

export function SendCustomSmsToDriverForm({ initialValues, createEditText, onChanger, contactUsRequestID, contactUsRequestData, driverData }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        messageHeader: Yup.string().required("message header field cannot be left blank"),
        messageBody: Yup.string().required("message field cannot be left blank"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            values.userIDs = driverData.userIDs;
            values.messageSubject = "";
            values.channel = "sms";
            values.senderID = driverData.senderID;
            values.senderFirstName = driverData.senderFirstName;
            values.senderLastName = driverData.senderLastName;

            enableLoading();
            setTimeout(() => {

                sendBulkMessages(values).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        onChanger();

                        return setStatus(`SMS sent`);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });

                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {

                    disableLoading();
                    setSubmitting(false);
                    return onChanger();

                });

            }, 1000);

        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="SMSFormMessageHeader">
                    <Form.Label>Message Header</Form.Label>
                    <Form.Control type="text" placeholder="eg Wahu Sales" value={formik.values.messageHeader} onChange={formik.handleChange} className={`${getInputClasses("messageHeader")}`} name="messageHeader" {...formik.getFieldProps("messageHeader")} />
                    <Form.Text className="text-muted">
                        SMS Message Header
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        SMS Message Header cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageHeader && formik.errors.messageHeader ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageHeader}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="SMSFormMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control as="textarea" rows={6} placeholder="SMS Message" value={formik.values.messageBody} onChange={formik.handleChange} className={`${getInputClasses("messageBody")}`} name="messageBody" {...formik.getFieldProps("messageBody")} />
                    <Form.Text className="text-muted">
                        SMS Message
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        SMS Message cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageBody && formik.errors.messageBody ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageBody}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form>
        </>
    );
}