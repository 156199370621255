import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_wahu/layout";

import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { AdminsPage } from "./pages/AdminsPage";
import { OrganizationsPage } from "./pages/OrganizationsPage";
import { DashboardPage } from "./pages/DashboardPage";
import { SettingsPage } from "./pages/SettingsPage";
import { ManageTrackersPage } from "./pages/ManageTrackersPage";
import { CareersPage } from "./pages/CareersPage";
import { VendorRequestsPage } from "./pages/VendorRequestsPage";
import { OverviewPage } from "./pages/OverviewPage";
import { WahuBikeSubscriptionsPage } from "./pages/WahuBikeSubscriptionsPage";
import { DriverCohortsPage } from "./pages/DriverCohortsPage";
import { UserTransactionsPage } from "./pages/UserTransactionsPage";
import { PartsManagementPage } from "./pages/PartsManagementPage";
import { SubscriptionPlansPage } from "./pages/SubscriptionPlansPage";
import { DeferredPaymentsPage } from "./pages/DeferredPaymentsPage";
import { RequisitionsPage } from "./pages/RequisitionPage";
import { DriverVehicleMetricsPage } from "./pages/DriverVehicleMetricsPage";
import { FaultConfigPage } from "./pages/FaultConfigPage";
import { VendorAccountsPage } from "./pages/VendorAccountsPage";
import { InvoicesPage } from "./pages/InvoicesPage";
import { CustomersPage } from "./pages/CustomersPage";
import { FaultPage } from "./pages/FaultPage";
import { FaultCategoriesPage } from "./pages/FaultCategoriesPage";
import { FaultSubcategoriesPage } from "./pages/FaultSubcategoriesPage";
import { LabourUnitsPage } from "./pages/LabourUnitsPage";
import { ServiceCenterPage } from "./pages/ServiceCenterPage";
import { AllServiceCentersPage } from "./pages/AllServiceCentersPage";

const VehiclesModule = lazy(() =>
    import("./modules/Vehicles/VehiclesModule")
);

const DriversModule = lazy(() =>
    import("./modules/Drivers/DriversModule")
);

const UserProfilePage = lazy(() =>
    import("./modules/UserProfile/UserProfilePage")
);

const DriverDetailsPage = lazy(() =>
    import("./modules/DriverProfile/DriverDetailsPage")
);

const CommunicationsModule = lazy(() =>
    import("./modules/Communications/CommunicationsModule")
);

export default function BasePage() {

    /** 
        useEffect(() => {
            console.log('Base page');
        },
            []
        ) // [] - is required if you need only one call
        https://reactjs.org/docs/hooks-reference.html#useeffect
    */

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from="/" to="/dashboard" />
                }
                <ContentRoute path="/dashboard" component={DashboardPage} />
                <ContentRoute path="/builder" component={BuilderPage} />
                <ContentRoute path="/my-page" component={MyPage} />
                <ContentRoute path="/admins" component={AdminsPage} />
                <ContentRoute path="/user-transactions" component={UserTransactionsPage} />
                <ContentRoute path="/organizations" component={OrganizationsPage} />
                <ContentRoute path="/settings" component={SettingsPage} />
                <ContentRoute path="/trackers" component={ManageTrackersPage} />
                <ContentRoute path="/careers" component={CareersPage} />
                <ContentRoute path="/vendor-requests" component={VendorRequestsPage} />
                <ContentRoute path="/vendor-accounts" component={VendorAccountsPage} />
                <ContentRoute path="/overview" component={OverviewPage} />
                <ContentRoute path="/wahu-bike-subscriptions" component={WahuBikeSubscriptionsPage} />
                <ContentRoute path="/driver-cohorts" component={DriverCohortsPage} />
                <ContentRoute path="/parts-management" component={PartsManagementPage} />
                <ContentRoute path="/subscription-plans" component={SubscriptionPlansPage} />
                <ContentRoute path="/deferred-payments" component={DeferredPaymentsPage} />
                <ContentRoute path="/requisitions" component={RequisitionsPage} />
                <ContentRoute path="/driver-metrics" component={DriverVehicleMetricsPage} />
                <ContentRoute path="/faultconfig" component={FaultConfigPage} />
                <ContentRoute path="/fault" component={FaultPage} />
                <ContentRoute path="/faultCategories" component={FaultCategoriesPage} />
                <ContentRoute path="/faultSubcategories" component={FaultSubcategoriesPage} />
                <ContentRoute path="/labourUnits" component={LabourUnitsPage} />
                <ContentRoute path="/serviceCenters" component={ServiceCenterPage} />
                <ContentRoute path="/invoices" component={InvoicesPage} />
                <ContentRoute path="/customers" component={CustomersPage} />
                <ContentRoute path="/service-centers" component={AllServiceCentersPage} />

                <Route path="/user-profile" component={UserProfilePage} />
                <Route path="/driver-details" component={DriverDetailsPage} />
                <Route path="/communications" component={CommunicationsModule} />
                <Route path="/vehicles" component={VehiclesModule} />
                <Route path="/drivers" component={DriversModule} />

                <Redirect to="/error" />
            </Switch>
        </Suspense>
    );
}
