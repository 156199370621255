import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import {
    AccountCircle,
    Drafts
} from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import LocalPhoneTwoToneIcon from '@mui/icons-material/LocalPhoneTwoTone';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateUser, deactivateUser } from '../../../../app/modules/Auth/_redux/authCrud';
import { AUTH_BASE_URL, setStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import { CreateEditAdminForm } from '../forms/CreateEditAdminForm';
import { SimpleModal } from '../modals/SimpleModal';
import { UpdateAdminPhoneNumber } from '../forms/UpdateAdminPhoneNumber';
import { UpdateAdminEmail } from '../forms/UpdateAdminEmail';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function AdminsTable({ className }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showUpdatePhoneNumber, setShowUpdatePhoneNumber] = useState(false);
    const [showUpdateEmail, setShowUpdateEmail] = useState(false);
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phoneNumber: "",
        country: ""
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Account');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');

    const TableErrorMessage = useRef();

    const classes = useStyles();

    const defaultValuesToSet = {
        firstName: "",
        lastName: "",
        gender: "",
        email: "",
        phoneNumber: "",
        country: ""
    };

    function triggerSnackBar() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/users/admins/getAll', `${AUTH_BASE_URL}`);
            url.searchParams.set('startNumber', `${pagination.pageIndex * pagination.pageSize}`);
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => `${row?.document?.firstName} ${row?.document?.lastName}`,
            id: 'name',
            header: 'Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <img
                        alt="avatar"
                        height={30}
                        width={30}
                        src={row.original.document.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                        loading="lazy"
                        style={{ borderRadius: '50%' }}
                    />
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.email,
            id: "email",
            enableClickToCopy: true,
            header: 'Email'
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber,
            id: "phone",
            enableClickToCopy: true,
            header: 'Phone Number'
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: "country",
            enableClickToCopy: true,
            header: 'Country'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'document.createdAt',
            header: 'Date Created',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            grow: true,
            size: 300,
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "adminID",
            enableClickToCopy: true,
            header: 'ID'
        },
    ],
        [],
    );

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Admin`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditAdminForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Update Phone Number`}
                show={showUpdatePhoneNumber}
                size="lg"
                close={() => setShowUpdatePhoneNumber(false)}
                body={
                    <UpdateAdminPhoneNumber
                        onChanger={onChangeWorker}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`Update Email`}
                show={showUpdateEmail}
                size="lg"
                close={() => setShowUpdateEmail(false)}
                body={
                    <UpdateAdminEmail
                        onChanger={onChangeWorker}
                    />
                }
            />

            <div className={`card card-custom ${className} rounded-xl`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Admin Records
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Admin Accounts from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Account");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Create Admin Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        /* renderDetailPanel={({ row }) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <img alt="avatar" height={80} width={80} src={row.original.document?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")} loading="lazy" style={{ borderRadius: '50%' }} />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="label">Gender: </Typography>
                                    <Typography variant="p">
                                        {row.original.document?.gender}
                                    </Typography>
                                </Box>
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="label">ID: </Typography>
                                    <Typography variant="p">
                                        {row.original.document?.ID}
                                    </Typography>
                                </Box>
                            </Box>
                        )} */
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const userID = row.original.document.ID;
                                    const valuesToSet = {
                                        email: row.original.document.email,
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        gender: row.original.document.gender,
                                        phoneNumber: row.original.document.phone,
                                        country: row.original.document.country
                                    };

                                    setStorageItem('userIdForEdit', userID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShow(true);

                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AccountCircle />
                                </ListItemIcon>
                                Edit Profile
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const userID = row.original.document.ID;
                                    setStorageItem('userIdForEdit', userID);
                                    setShowUpdateEmail(true);

                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <Drafts
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Update Email Address
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const userID = row.original.document.ID;
                                    setStorageItem('userIdForEdit', userID);
                                    setShowUpdatePhoneNumber(true);

                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <LocalPhoneTwoToneIcon
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                Update Phone Number
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {
                            const handleDeactivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateUser(row.original.document.ID);
                                        triggerSnackBar();
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                    } catch (error) {
                                        triggerSnackBar();
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                    }
                                });
                            };

                            const handleActivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateUser(row.original.document.ID);
                                        triggerSnackBar();
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                    } catch (error) {
                                        triggerSnackBar();
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="primary"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate
                                    </Button>
                                </div>
                            );
                        }}
                        muiSkeletonProps={{
                            animation: 'pulse',
                            height: 32,
                        }}
                        enableStickyFooter
                        enableStickyHeader
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                    />
                </div>
            </div>

        </>
    );
}
