import React from "react";
import {
    Tab,
    Row,
    Col,
    Nav,
    Card,
    Accordion
} from 'react-bootstrap';
import { useSubheader } from "../../_wahu/layout";
import {
    VehicleTypeSettingsTableSub,
    MotorSettingsTableSub,
    BatteriesSettingsTableSub,
    ManufacturerSettingsTableSub,
    VehicleModelsSettingsTableSub
} from "../../_wahu/_partials/widgets";

export const SettingsPage = () => {

    const suhbeader = useSubheader();
    React.useEffect(() => {
        const load = async () => {
            try {
                suhbeader.setTitle("Platform Settings");
            } catch (error) {
                console.log(error);
            }
        };
        load();
    }, [suhbeader]);

    return (
        <>
            <div className="row">
                <div className="col-xxl-12">
                    <div className="card-stretch gutter-b">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="vehicle-settings">
                            <Row>
                                <Col sm={2}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="vehicle-settings" className="tab-head-active">
                                                <span className="bold">Vehicle Settings</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="fault-settings">
                                                <span className="bold"> Fault Settings</span>
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col sm={10}>
                                    <Tab.Content className="tab-pane">
                                        <Tab.Pane eventKey="vehicle-settings">
                                            <Card>
                                                <Card.Body>
                                                    <Accordion defaultActiveKey="0">
                                                        <Card className="border-none">
                                                            <Accordion.Toggle as={Card.Header} eventKey="0" className="pl-5 pt-2 pb-1 a-toggle-head">
                                                                <h5>Vehicle Types</h5>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse className="accordion-border-bottom" eventKey="0">
                                                                <VehicleTypeSettingsTableSub className="card-stretch gutter-b" />
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card className="border-none">
                                                            <Accordion.Toggle as={Card.Header} eventKey="1" className="pl-5 pt-2 pb-1 a-toggle-head">
                                                                <h5>Motors</h5>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse className="accordion-border-bottom" eventKey="1">
                                                                <MotorSettingsTableSub className="card-stretch gutter-b" />
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card className="border-none">
                                                            <Accordion.Toggle as={Card.Header} eventKey="2" className="pl-5 pt-2 pb-1 a-toggle-head">
                                                                <h5>Battery Configurations</h5>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse className="accordion-border-bottom" eventKey="2">
                                                                <BatteriesSettingsTableSub className="card-stretch gutter-b" />
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card className="border-none">
                                                            <Accordion.Toggle as={Card.Header} eventKey="3" className="pl-5 pt-2 pb-1 a-toggle-head">
                                                                <h5>Manufacturers</h5>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse className="accordion-border-bottom" eventKey="3">
                                                                <ManufacturerSettingsTableSub className="card-stretch gutter-b" />
                                                            </Accordion.Collapse>
                                                        </Card>
                                                        <Card className="border-none">
                                                            <Accordion.Toggle as={Card.Header} eventKey="4" className="pl-5 pt-2 pb-1 a-toggle-head">
                                                                <h5>Vehicle Models</h5>
                                                            </Accordion.Toggle>
                                                            <Accordion.Collapse className="accordion-border-bottom" eventKey="4">
                                                                <VehicleModelsSettingsTableSub className="card-stretch gutter-b" />
                                                            </Accordion.Collapse>
                                                        </Card>
                                                    </Accordion>
                                                </Card.Body>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fault-settings">
                                            <Card body>
                                                <Card border="secondary">
                                                    <Card.Body>
                                                        <i className="fa fa-exclamation-triangle font-red pr-2"></i>
                                                        Slow down, construction ahead
                                                    </Card.Body>
                                                </Card>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </>
    );
};
