import {
    IconButton,
    Snackbar,
    Avatar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    Typography,
    Paper,
    Grid,
    styled,
    Chip,
    Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AssignmentTurnedIn } from '@mui/icons-material';
import TtyTwoToneIcon from '@mui/icons-material/TtyTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import RemoveRedEyeTwoToneIcon from '@mui/icons-material/RemoveRedEyeTwoTone';
import AssignmentLateTwoToneIcon from '@mui/icons-material/AssignmentLateTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { getVehicle, unassignVehicle } from '../../../../app/modules/Auth/_redux/authCrud';
import { AUTH_BASE_URL, setStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import {
    CreateEditDriverForm,
    UpdateUserPhoneForm,
    VehicleDetailsList,
    CreateTransactionForm,
    SubscriptionStartDateForm,
    OrganizationsTable,
    DriverChurnForm,
    GeneralPromptForm,
    RemoveRecordConfirmForm,
    PauseDriverSubscriptionForm,
    SendCustomSmsToDriverForm,
    VehiclesTable,
    SendUserEmailForm,
    UnchurnDriverForm
} from '../';
import { SimpleModal } from '../modals/SimpleModal';
import BasicLoader from '../../loaders/SimpleLoader';
import LocalAtmTwoToneIcon from '@mui/icons-material/LocalAtmTwoTone';
import ReceiptLongRoundedIcon from '@mui/icons-material/ReceiptLongRounded';
import { DriverTransactions } from './DriverTransactions';
import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone';
import DriverDetailsPage from '../../../../app/modules/DriverProfile/DriverDetailsPage';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { generalFormActions } from '../../../_helpers/Interfaces';
import { shallowEqual, useSelector } from "react-redux";
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';
import { assignDriversToOrganization } from '../../../../app/modules/Auth/_redux/authCrud';
import { ExportToCsv } from 'export-to-csv';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import { UpdateUserEmailForm } from '../forms/UpdateUserEmailForm';
import AssignmentReturnTwoToneIcon from '@mui/icons-material/AssignmentReturnTwoTone';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import CorporateFareTwoToneIcon from '@mui/icons-material/CorporateFareTwoTone';
import CalendarMonthTwoToneIcon from '@mui/icons-material/CalendarMonthTwoTone';
import ContactPageTwoToneIcon from '@mui/icons-material/ContactPageTwoTone';
import AutoDeleteTwoToneIcon from '@mui/icons-material/AutoDeleteTwoTone';
import PauseCircleFilledTwoToneIcon from '@mui/icons-material/PauseCircleFilledTwoTone';
import PlayCircleFilledTwoToneIcon from '@mui/icons-material/PlayCircleFilledTwoTone';
import SmsTwoToneIcon from '@mui/icons-material/SmsTwoTone';
import ReplayCircleFilledTwoToneIcon from '@mui/icons-material/ReplayCircleFilledTwoTone';
import SendTwoToneIcon from '@mui/icons-material/SendTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    },
    overflower: {
        overflow: 'scroll',
        overflowAnchor: 'auto',
        height: '230px',
    }
}));

export function DriversTable({ className, onChanger, assignmentType, assignAction, isSelectionAction, selectionAction, organizationID, baseFetchUrl, tableTopHeader, tableSubHeader, baseUrl }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [showVehicleAssignModal, setShowVehicleAssignModal] = useState(false);
    const [showPhoneUpdateModal, setShowPhoneUpdateModal] = useState(false);
    const [driverNameForUpdate, setDriverNameForUpdate] = useState("");
    const [initialPhoneValue, setInitialPhoneValue] = useState({
        phoneNumber: ""
    });
    const [initialEmailValue, setInitialEmailValue] = useState({
        email: ""
    });
    const [initialValues, setInitialValues] = useState({
        firstName: "",
        lastName: "",
        gender: "",
        licenseID: "",
        phoneNumber: "",
        country: ""
    });

    const [selectedDriverID, setSelectedDriverID] = useState(null);
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Account');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [disableActionButtons, setDisableActionButtons] = useState(true);
    const [severity, setSeverity] = useState('info');
    const [showVehicleDetails, setShowVehicleDetails] = useState(false);
    const [showModelDataLoader, setModelDataLoader] = useState('d-none');
    const [driverProfileImgURL, setDriverProfileImgURL] = useState('');
    const [vehicleModelID, setVehicleModelID] = useState('');
    const [vehicleData, setVehicleData] = useState({});
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [showDriverTransactions, setShowDriverTransactions] = useState(false);
    const [driverID, setDriverID] = useState("");
    const [selectedDriverName, setSelectedDriverName] = useState("");
    const [createTransactionDefaultValues, setCreateTransactionDefaultValues] = useState({
        email: "",
        amount: 0,
        currency: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        isFirstPayment: false,
        isSubscriptionPayment: false,
        userID: "",
        weeksPaid: 1
    });
    const [rowSelection, setRowSelection] = useState({});
    const [showSMSModal, setShowSMSModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);

    const [showDriveDetails, setShowDriveDetails] = useState(false);
    const [driveDetailsData, setDriveDetailsData] = useState(false);
    const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);
    const [showSubscriptionDateModal, setShowSubscriptionDateModal] = useState(false);
    const [subscriptionDateInitialValues, setSubscriptionDateInitialValues] = useState({
        startDate: "",
        driverID: "",
    });

    const [toggleOrganizationAssignmentConfirmationModal, setToggleOrganizationAssignmentConfirmationModal] = useState(false);
    const [selectedDriverIDForOrganization, setSelectedDriverIDForOrganization] = useState("");

    const [toggleDriverUnchurnConfirmationModal, setToggleDriverUnchurnConfirmationModal] = useState(false);
    const [toggleDriverDeactivationConfirmationModal, setToggleDriverDeactivationConfirmationModal] = useState(false);
    const [toggleDriverActivationConfirmationModal, setToggleDriverActivationConfirmationModal] = useState(false);
    const [toggleDriverDeletionConfirmationModal, setToggleDriverDeletionConfirmationModal] = useState(false);
    const [togglePauseSubscriptionConfirmationModal, setTogglePauseSubscriptionConfirmationModal] = useState(false);
    const [driverData, setDriverData] = useState(null);
    const [bikeUnassignmentModal, setBikeUnassignmentModal] = useState(false);

    const [showChurnDriverModal, setShowChurnDriverModal] = useState(false);

    const [driveDetailsDataForUnassignment, setDriveDetailsDataForUnassignment] = useState(null);
    const [driveIDForUnassignment, setDriveIDForUnassignment] = useState("");
    const [vehicleIDForUnassignment, setVehicleIDForUnassignment] = useState("");
    const [loadingUnassignment, setLoadingUnassignment] = useState(false);
    const [driveNameForUnassignment, setDriveNameForUnassignment] = useState("");

    const TableMessage = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        firstName: "",
        lastName: "",
        gender: "",
        licenseID: "",
        phoneNumber: "",
        country: ""
    };

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    function triggerSnackBar() {
        setOpen(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function displayAssignedVehicle(ingressData) {

        setModelDataLoader('');

        const specVehicleID = ingressData;
        getVehicle(specVehicleID).then((response) => {

            const modelID = response.data.document.vehicleModelID;
            setVehicleModelID(modelID);

            const vehicleData = response.data.document;
            const selectedVehicleDocument = {
                document: {}
            };
            selectedVehicleDocument.document = vehicleData;
            setVehicleData(selectedVehicleDocument);

        }).finally(() => {

            setTimeout(() => {
                setModelDataLoader('d-none');
                setShowVehicleDetails(true);
            }, 3000);

        }).catch((error) => {

            TableMessage.current = error.message;
            setSeverity('error');
            triggerSnackBar();
            setModelDataLoader('d-none');

        });

    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const driverDelegationRenderer = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffAccount;
        const isCustomerVehicle = rowData?.document?.isTestAccount;

        if (isStaffVehicleBool === true) {
            return (
                <Chip label="Staff Account" variant="outlined" color="primary" icon={<ManageAccountsTwoToneIcon />}></Chip>
            );
        }

        if (isCustomerVehicle === true) {
            return (
                <Chip label="Test Account" variant="outlined" color="secondary" icon={< PersonOutlineTwoToneIcon />}></Chip >
            );
        }

        return (
            <Chip label="Rider Account" variant="outlined" color="default" icon={<SportsMotorsportsTwoToneIcon />}></Chip>
        );

    };

    const DisplayAssignedVehicles = ({ vehicleID, driverID, driverData }) => {

        if (vehicleID === "" || vehicleID === null || !vehicleID) return (
            <Alert
                icon={false}
                elevation={6}
                variant="outlined"
                color='error'
            >
                no vehicle assigned yet
            </Alert>
        );

        return (
            <Alert
                icon={false}
                action={
                    <>
                        <Button
                            startIcon={<RemoveRedEyeTwoToneIcon />}
                            color="info"
                            size="small"
                            variant="contained"
                            onClick={() => {
                                displayAssignedVehicle(vehicleID);
                            }}
                            className="mr-2"
                        >
                            <span>
                                See Vehicle Details
                                {" "}
                                <BasicLoader
                                    hideShowLoader={showModelDataLoader}
                                    size="sm"
                                />
                            </span>
                        </Button>
                        <Button
                            startIcon={<AssignmentLateTwoToneIcon />}
                            color="error"
                            size="small"
                            variant="contained"
                            onClick={() => {

                                setDriveIDForUnassignment(driverID);
                                setDriveDetailsDataForUnassignment(driverData);
                                setVehicleIDForUnassignment(vehicleID);
                                setDriveNameForUnassignment(`${driverData.document.firstName} ${driverData.document.lastName}`);

                                setBikeUnassignmentModal(true);

                            }}
                        >
                            Unassign
                        </Button>
                    </>
                }
                elevation={7}
                variant="outlined"
            >
                {vehicleID}
            </Alert>
        );

    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(`${baseFetchUrl ? baseFetchUrl : "/users/drivers/getAll"}`, `${baseUrl ? baseUrl : AUTH_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    const dataToExport = {
                        "Name": element.document.firstName + " " + element.document.lastName,
                        "Email": element?.document?.email ?? "none",
                        "Phone Number": element?.document?.phone ?? element?.document?.phoneNumber,
                        "Country": element?.document?.country,
                        "Payment Cycle": element?.document?.subscriptionPlan?.ID,
                        "Weekly Amount": element?.document?.subscriptionPlan?.ID === "SUBP-ef949d4d-8971-425a-8565-2bc142414cbf" ? 300 : element?.document?.subscriptionPlan?.ID === "SUBP-6aa31093-e1fb-4991-b2f7-262f994ffbda" ? 400 : element?.document?.subscriptionPlan?.ID === "SUBP-64c80dc6-7f9b-4580-ad4b-655f0289a869" ? 600 : 300,
                        "Location": element?.onboarding ? element?.onboarding[4]?.digitalAddress?.ghanaPostGpsCode : "Accra"
                    };

                    dataArrayAlt.push(dataToExport);

                    if ((i + 1) >= dataArray.length) {
                        isMounted && setDataDocument(dataArrayAlt);
                        isMounted && setIsError(false);
                        isMounted && setOpen(false);
                    }

                }

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableMessage.current = "network error, please check your internet connection";
                    setIsError(true);
                    return setOpen(true);

                }

                TableMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender,
    ]);

    useEffect(() => {
        console.info({ rowSelection });
    }, [rowSelection]);

    const columns = useMemo(() => [
        {
            id: 'designation',
            header: 'Designation',
            Cell: ({ cell, row }) => (
                driverDelegationRenderer(row.original)
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.firstName} ${row?.document?.lastName}`,
            id: 'name',
            header: 'Name',
            size: 250,
            enableClickToCopy: true,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Avatar
                        alt={row.original?.document?.firstName}
                        src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                        className={classes.bigAvatar}
                    />
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => `${row?.document?.email ?? ""}`,
            id: "email",
            enableClickToCopy: true,
            header: 'Email',
            Cell: ({ cell, row }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? "" : "",
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : theme.palette.error.dark,
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : (<>
                        Not Added
                        <div
                            className="btn btn-icon btn-clean btn-lg mr-1 pulse pulse-success"
                            onClick={() => {

                                const driverID = row.original.document.ID;
                                setSelectedDriverID(driverID);

                                setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                setInitialEmailValue({
                                    email: row?.original?.document?.email ? row.original.document.email : "",
                                });
                                setCreateEditText("Update Email Address");

                                setShowEmailUpdateModal(true);

                            }}
                        >
                            <AddBoxTwoToneIcon
                                fontSize='small'
                                color='primary'
                            />
                            <span className="pulse-ring"></span>
                        </div>
                    </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber ?? row?.document?.phone,
            id: "phone",
            enableClickToCopy: true,
            header: 'Phone Number',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : "Not Added"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: "country",
            enableClickToCopy: true,
            header: 'Country',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() ? '' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? cell.getValue() : "Not Added"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.gender,
            id: 'driverGender',
            enableClickToCopy: true,
            header: 'Gender',
        },
        {
            accessorFn: (row) => row?.document?.isChurned,
            enableClickToCopy: true,
            header: 'Churn Status',
            id: "churnStatus",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.error.dark : cell.getValue() === false || !cell.getValue() ? theme.palette.success.light : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Churned" : cell.getValue() === false || !cell.getValue() ? "Not Churned" : "Churned"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.assignedVehiclesData,
            header: 'VIN',
            id: "vehicleVin",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() && cell.getValue()?.length ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() && cell.getValue()?.length ? '#000' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() && cell.getValue()?.length ?
                        (<>
                            {cell.getValue()?.map((item, index) => (
                                <div key={index} className='mb-5'>
                                    {item.vehicleVIN}
                                </div>
                            ))}
                        </>)
                        :
                        (<>
                            <span>
                                No Vehicle
                            </span>
                        </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.assignedVehiclesData,
            header: 'Tracker IMEI',
            id: "trackerImei",
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() && cell.getValue()?.length ? '' : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: cell.getValue() && cell.getValue()?.length ? '#000' : '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() && cell.getValue()?.length !== 0 ?
                        (<>
                            {cell.getValue()?.map((item, index) => (
                                <div key={index} className='mb-5'>
                                    {item.trackerIMEI}
                                </div>
                            ))}
                        </>)
                        :
                        (<>
                            <span>
                                No Vehicle
                            </span>
                        </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'document.createdAt',
            header: 'Date Created',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            size: 350,
        },
        {
            accessorFn: (row) => row?.document?.ID ?? row?.document?.uid,
            id: 'driverID',
            enableClickToCopy: true,
            header: 'ID',
        },
    ],
        [classes.bigAvatar],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Driver Data',
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    const BikeUnassignment = ({ driverID, vehicleID, driverData }) => {

        const assignedVehiclesData = driverData?.document?.assignedVehiclesData;
        const driverName = `${driverData?.document?.firstName} ${driverData?.document?.lastName}`;
        const vehicleIDIndex = assignedVehiclesData.findIndex(x => x.vehicleID === vehicleID);

        if (vehicleIDIndex >= 0) {

            const specVehicleVin = assignedVehiclesData[vehicleIDIndex].vehicleVIN;
            const specTrackerIMEI = assignedVehiclesData[vehicleIDIndex].trackerIMEI;

            return (
                <>
                    <div className='border-0'>
                        <div className='container-border p-3 rounded'>
                            <div className='p-2 centerMan font-weight-bold mb-2'>
                                Confirming this prompt will unassign the vehicle with details below from the selected rider:
                                <br />
                                <span className='centerMan rounded bg-light-success pl-2 pr-2'>
                                    {" "}
                                    {driverName}
                                </span>
                            </div>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={6}>
                                    <div className='bg-light-success rounded'>
                                        <div className='p-2'>
                                            <span>
                                                <span className='text-nowrap font-weight-bold'>
                                                    Tracker IMEI:
                                                </span>
                                                {" "}
                                                {specTrackerIMEI}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className='bg-light-success rounded'>
                                        <div className='p-2'>
                                            <span>
                                                <span className='text-nowrap font-weight-bold'>
                                                    Vehicle VIN:
                                                </span>
                                                {" "}
                                                {specVehicleVin}
                                            </span>
                                        </div>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                        <div className='mt-5'>
                            <div className='p-2 centerMan text-danger font-weight-bold mb-3'>
                                Are you sure you want to unassign the vehicle with VIN above from the selected rider?
                            </div>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={6}>
                                    <div className="centerMan">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="error"
                                            aria-label="Add"
                                            onClick={async () => {

                                                setLoadingUnassignment(true);

                                                const unAssigner = user.user_data.document.ID;
                                                const requestPayload = {
                                                    vehicleID: vehicleID,
                                                    driverID: driverID,
                                                    assignerID: unAssigner
                                                };

                                                try {

                                                    const response = await unassignVehicle(requestPayload);
                                                    if (response) {

                                                        TableMessage.current = response.data.message;

                                                        setSeverity("success");
                                                        onChangeWorker();

                                                        setLoadingUnassignment(false);
                                                        return setOpen(true);

                                                    }

                                                } catch (error) {

                                                    TableMessage.current = error?.message ?? "could not unassign vehicle, try again later";

                                                    setSeverity("error");
                                                    onChangeWorker();

                                                    setLoadingUnassignment(false);
                                                    return setOpen(true);

                                                }

                                            }}
                                            disabled={loadingUnassignment}
                                        >
                                            <span>
                                                Yes
                                                {' '}
                                                {loadingUnassignment && <BasicLoader size={'sm'} />}
                                            </span>
                                        </Button>
                                    </div>
                                </Grid>
                                <Grid item xs={6}>
                                    <div className="centerMan">
                                        <Button
                                            variant="contained"
                                            size="large"
                                            color="success"
                                            aria-label="Add"
                                            onClick={() => {
                                                setBikeUnassignmentModal(false);
                                            }}
                                            disabled={loadingUnassignment ? true : false}
                                        >
                                            No
                                        </Button>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </>
            );

        } else {

            TableMessage.current = "could not find find corresponding vehicle data: vehicle ID, vehicle VIN, and vehicle tracker IMEI";
            setSeverity("error");
            setIsError(true);
            setOpen(true);

            return (
                <>
                    <div className='border-0'>
                        <div className='container-border p-3 rounded'>
                            <div className='p-2 centerMan font-weight-bold text-danger mb-2'>
                                could not find find corresponding vehicle data: vehicle ID, vehicle VIN, and vehicle tracker IMEI
                            </div>
                        </div>
                    </div>
                </>
            );

        }

    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Driver`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditDriverForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    driverProfileImgURL={driverProfileImgURL}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Select vehicle(s) to be assigned`}
                show={showVehicleAssignModal}
                close={() => setShowVehicleAssignModal(false)}
                size="xl"
                body={<VehiclesTable
                    assignmentType="driver"
                    onChanger={onChangeWorker}
                    selectedDriverIdForVehicleAssignment={selectedDriverID}
                    assignAction={true}
                />}
            />
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate} Phone's Number`}
                show={showPhoneUpdateModal}
                close={() => setShowPhoneUpdateModal(false)}
                size="lg"
                body={<UpdateUserPhoneForm
                    initialValues={initialPhoneValue}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Vehicle Details`}
                show={showVehicleDetails}
                close={() => setShowVehicleDetails(false)}
                size="lg"
                body={<VehicleDetailsList
                    selectedVehicleModelID={vehicleModelID}
                    selectedVehicleData={vehicleData}
                    className="card-stretch gutter-b"
                />}
            />
            <SimpleModal
                modal_tittle={`Add payment for driver`}
                show={showAddPaymentModal}
                close={() => setShowAddPaymentModal(false)}
                size="lg"
                body={<CreateTransactionForm
                    initialValues={createTransactionDefaultValues}
                    createEditText={"Create Transaction"}
                    onChanger={onChangeWorker}
                    userID={createTransactionDefaultValues.userID}
                />}

            />
            <SimpleModal
                modal_tittle={`Driver Transactions for ${selectedDriverName}`}
                show={showDriverTransactions}
                close={() => setShowDriverTransactions(false)}
                size="lg"
                body={<DriverTransactions
                    driverID={driverID}
                    driverName={selectedDriverName}
                />}

            />
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate}'s Email Address`}
                show={showEmailUpdateModal}
                close={() => setShowEmailUpdateModal(false)}
                size="lg"
                body={<UpdateUserEmailForm
                    initialValues={initialEmailValue}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Subscription Start Date for ${selectedDriverName}`}
                show={showSubscriptionDateModal}
                close={() => setShowSubscriptionDateModal(false)}
                size="lg"
                body={
                    <SubscriptionStartDateForm
                        initialValues={subscriptionDateInitialValues}
                        editState={editState}
                        createEditText={createEditText}
                        onChanger={onChangeWorker}
                        selectedDriverID={driverID}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`Assign Vehicle to Organization`}
                show={toggleOrganizationAssignmentConfirmationModal}
                close={() => setToggleOrganizationAssignmentConfirmationModal(false)}
                size="lg"
                body={<OrganizationsTable
                    onChanger={onChangeWorker}
                    driverID={selectedDriverIDForOrganization}
                    assignAction={true}
                    assignmentType={"driver"}
                    assignmentEntityName={"Assign Riders"}
                />}
            />
            <SimpleModal
                modal_tittle={`Churn ${selectedDriverName}`}
                show={showChurnDriverModal}
                close={() => setShowChurnDriverModal(false)}
                size="lg"
                body={
                    <DriverChurnForm
                        initialValues={
                            { notes: "" }
                        }
                        editState={false}
                        createEditText={createEditText}
                        onChanger={onChangeWorker}
                        selectedDriverID={driverID}
                    />
                }
            />
            <SimpleModal
                modal_tittle={`UnChurn ${selectedDriverName}`}
                show={toggleDriverUnchurnConfirmationModal}
                close={() => setToggleDriverUnchurnConfirmationModal(false)}
                size="md"
                body={<UnchurnDriverForm
                    modalCloseCallback={() => {
                        setToggleDriverUnchurnConfirmationModal(false);
                    }}
                    driverID={driverID}
                    onChangeWorker={onChangeWorker}
                    selectedDriverName={selectedDriverName}
                />}
            />
            <SimpleModal
                modal_tittle={`Deactivate ${selectedDriverName}'s account?`}
                show={toggleDriverDeactivationConfirmationModal}
                close={() => setToggleDriverDeactivationConfirmationModal(false)}
                size="lg"
                body={<GeneralPromptForm
                    driverData={driverData}
                    onChanger={onChangeWorker}
                    editState={false}
                    promptText={`Are you sure you want to deactivate this rider's account?`}
                    actionType={generalFormActions[1]}
                    modalCloseCallback={() => {
                        setToggleDriverDeactivationConfirmationModal(false);
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={`Activate ${selectedDriverName}'s account?`}
                show={toggleDriverActivationConfirmationModal}
                close={() => setToggleDriverActivationConfirmationModal(false)}
                size="lg"
                body={<GeneralPromptForm
                    driverData={driverData}
                    onChanger={onChangeWorker}
                    editState={false}
                    promptText={`Are you sure you want to activate this rider's account?`}
                    actionType={generalFormActions[0]}
                    modalCloseCallback={() => {
                        setToggleDriverActivationConfirmationModal(false);
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={`Delete ${selectedDriverName}'s account?`}
                show={toggleDriverDeletionConfirmationModal}
                close={() => setToggleDriverDeletionConfirmationModal(false)}
                size="lg"
                body={<RemoveRecordConfirmForm
                    recordID={driverID}
                    recordData={driverData}
                    onChanger={onChangeWorker}
                    entityType={"drivers"}
                    modalBackAction={() => {
                        setToggleDriverDeletionConfirmationModal(false);
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={driverData?.document?.isSubscriptionsPaused ? `Unpause ${selectedDriverName}'s subscription?` : `Pause ${selectedDriverName}'s subscription?`}
                show={togglePauseSubscriptionConfirmationModal}
                close={() => setTogglePauseSubscriptionConfirmationModal(false)}
                size="lg"
                body={<PauseDriverSubscriptionForm
                    driverData={driverData}
                    onChanger={onChangeWorker}
                    fieldDisabled={driverData?.document?.isSubscriptionsPaused ? true : false}
                    editState={driverData?.document?.isSubscriptionsPaused ? true : false}
                    createEditText={driverData?.document?.isSubscriptionsPaused ? "Unpause Subscription" : "Pause Subscription"}
                    modalCloseCallback={() => {
                        setTogglePauseSubscriptionConfirmationModal(false);
                    }}
                    initialValues={{
                        driverID: driverData?.document?.ID,
                        notes: driverData?.document?.subscriptionPauseNotes ? driverData?.document?.subscriptionPauseNotes[driverData?.document?.subscriptionPauseNotes?.length - 1].note : "",
                        adderID: user.user_data.document.ID,
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={`Send SMS to ${selectedDriverName}`}
                show={showSMSModal}
                close={() => setShowSMSModal(false)}
                size="lg"
                body={<SendCustomSmsToDriverForm
                    driverData={driverData}
                    onChanger={onChangeWorker}
                    initialValues={initialValues}
                    createEditText={"Send SMS"}
                    modalCloseCallback={() => {
                        setShowSMSModal(false);
                    }}
                />}
            />
            <SimpleModal
                modal_tittle={`Rider data & KPIs for ${selectedDriverName}`}
                show={showDriveDetails}
                close={() => setShowDriveDetails(false)}
                size="xl"
                body={<DriverDetailsPage
                    selectedDriverData={driveDetailsData}
                    onChangeFunction={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Unassign Bike from ${driveNameForUnassignment}`}
                show={bikeUnassignmentModal}
                close={() => setBikeUnassignmentModal(false)}
                size="lg"
                body={<BikeUnassignment
                    driverData={driveDetailsDataForUnassignment}
                    driverID={driveIDForUnassignment}
                    vehicleID={vehicleIDForUnassignment}
                    onChangeFunction={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Send Email to ${selectedDriverName}`}
                show={showEmailModal}
                close={() => setShowEmailModal(false)}
                size="lg"
                body={<SendUserEmailForm
                    driverData={driverData}
                    onChanger={onChangeWorker}
                    initialValues={initialValues}
                    createEditText={"Send Email"}
                    modalCloseCallback={() => {
                        setShowEmailModal(false);
                    }}
                />}
            />
            <div className={`card card-custom rounded-lg ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {!isLoading && rowCount}
                            {isLoading && <BasicLoader size={'sm'} />}
                            {" "}
                            {`${tableTopHeader ? tableTopHeader : "Rider Records"}`}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            {`${tableSubHeader ? tableSubHeader : "Driver account management and actions"}`}
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Create Account");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Create Rider Account
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 mb-3">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        options={{
                            selection: true,
                        }}
                        enableRowSelection
                        enableBatchRowSelection
                        onRowSelectionChange={setRowSelection}
                        selectAllMode={"all"}
                        getRowId={(row) => row?.document?.ID}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enablePagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableFilterMatchHighlighting
                        enableColumnPinning
                        enableRowActions
                        enableRowPinning
                        enableFullScreenToggle
                        enableExpandAll
                        enableExpanding
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: `${severity}`,
                                children: `${TableMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            rowSelection,
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                        }}
                        renderDetailPanel={({ row }) => (

                            <>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={6}>
                                        <div className={`table-detail-area rounded p-2 ${classes.overflower}`}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Assigned Vehicles</Typography>
                                                            <div className='mt-5'>
                                                                {row.original.document?.vehicles && row.original.document?.vehicles?.length && row.original.document?.vehicles?.length > 0 ?
                                                                    row.original.document?.vehicles?.map((vehicleid) => (
                                                                        <div key={vehicleid} className='mb-5'>
                                                                            <DisplayAssignedVehicles
                                                                                severity="info"
                                                                                key={vehicleid}
                                                                                vehicleID={vehicleid ?? null}
                                                                                driverID={row.original.document?.ID}
                                                                                driverData={row.original}
                                                                            />
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <>
                                                                        <Alert
                                                                            icon={false}
                                                                            elevation={6}
                                                                            variant="outlined"
                                                                            color='error'
                                                                            action={
                                                                                <>
                                                                                    <Button
                                                                                        startIcon={< AssignmentTurnedIn />}
                                                                                        color="success"
                                                                                        size="small"
                                                                                        variant="contained"
                                                                                        onClick={() => {

                                                                                            const driverID = row.original.document.ID;
                                                                                            setSelectedDriverID(driverID);

                                                                                            setShowVehicleAssignModal(true);

                                                                                        }}
                                                                                    >
                                                                                        Assign Vehicle
                                                                                    </Button>
                                                                                </>
                                                                            }
                                                                        >
                                                                            no vehicle assigned yet
                                                                        </Alert>
                                                                    </>
                                                                }
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Churn History</Typography>
                                                            <div className='mt-5'>
                                                                {row.original.document?.notes && row.original.document?.notes?.length && row.original.document?.notes?.length > 0 ?
                                                                    row.original.document?.notes?.map((note, index) => (
                                                                        <div key={index} className='mb-5'>
                                                                            <div className='bg-light-warning p-3 rounded-lg'>
                                                                                <div>
                                                                                    <Chip size='sm' label="Churned" color='error' />
                                                                                </div>
                                                                                <div className='border-bottom'>
                                                                                    <span>
                                                                                        <span className='bold'>
                                                                                            Reason:
                                                                                        </span>
                                                                                        {" "}
                                                                                        {note.note}
                                                                                    </span>
                                                                                </div>
                                                                                <div className='mt-1'>
                                                                                    <span>
                                                                                        <ContactPageTwoToneIcon />
                                                                                    </span>
                                                                                    {" "}
                                                                                    {note.adderFirstName}
                                                                                    {" "}
                                                                                    {note.adderLastName}
                                                                                </div>
                                                                                <div className='mt-1'>
                                                                                    <span>
                                                                                        <CalendarMonthTwoToneIcon />
                                                                                    </span>
                                                                                    {" "}
                                                                                    {new Date(note.createdAt).toLocaleDateString()}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                    :
                                                                    <>
                                                                        <Alert
                                                                            icon={false}
                                                                            elevation={6}
                                                                            variant="outlined"
                                                                            color='success'
                                                                        >
                                                                            Rider is not Churned
                                                                        </Alert>
                                                                    </>
                                                                }
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={`table-detail-area rounded p-2 ${classes.overflower}`}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>Gender</Typography>
                                                            <div className='mt-0'>
                                                                <Typography className='mb-5' variant="p" display="block" gutterBottom>
                                                                    {row.original.document?.gender}
                                                                </Typography>
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className={`bold ${classes.allCaps}`}>ID</Typography>
                                                            <div className='mt-0'>
                                                                <Typography className='mb-5' variant="p" display="block" gutterBottom>
                                                                    {row.original.document?.ID}
                                                                </Typography>
                                                            </div>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <div className={`table-detail-area rounded p-2 ${classes.overflower}`}>
                                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className='bold'>Vehicle Tracker IMEI: </Typography>
                                                            <Typography variant="p">
                                                                {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                    <div key={index} className='mb-5'>
                                                                        {item.trackerIMEI}
                                                                    </div>
                                                                )) : ""}
                                                            </Typography>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className='bold'>Vehicle VIN: </Typography>
                                                            <Typography variant="p">
                                                                {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                    <div key={index} className='mb-5'>
                                                                        {item.vehicleVIN}
                                                                    </div>
                                                                )) : ""}
                                                            </Typography>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Item>
                                                        <Box sx={{ textAlign: 'left' }}>
                                                            <Typography variant="label" className='bold'>Vehicle ID: </Typography>
                                                            <Typography variant="p">
                                                                {row.original.document?.assignedVehiclesData ? row.original.document?.assignedVehiclesData?.map((item, index) => (
                                                                    <div key={index} className='mb-5'>
                                                                        {item.vehicleID}
                                                                    </div>
                                                                )) : ""}
                                                            </Typography>
                                                        </Box>
                                                    </Item>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </Grid>
                                </Grid>
                            </>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const specDriverData = row.original;
                                    const driverName = row.original.document.firstName + " " + row.original.document.lastName;

                                    setDriveDetailsData(specDriverData);
                                    setSelectedDriverName(driverName);

                                    setShowDriveDetails(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon
                                    style={{ color: "#174e48" }}
                                >
                                    <BadgeTwoToneIcon />
                                </ListItemIcon>
                                Rider's Profile
                            </MenuItem>,
                            <MenuItem
                                key={3}
                                onClick={() => {

                                    const driverID = row.original.document.ID;

                                    const valuesToSet = {
                                        licenseID: row.original.document.licenseID,
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        gender: row.original.document.gender,
                                        phoneNumber: row.original.document.phone
                                    };

                                    setSelectedDriverID(driverID);
                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShowVehicleAssignModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon
                                    style={{ color: "#22abe2" }}
                                >
                                    <AssignmentTurnedIn />
                                </ListItemIcon>
                                Assign Vehicle
                            </MenuItem>,
                            <MenuItem
                                key={10}
                                onClick={async () => {

                                    setSelectedDriverIDForOrganization(row.original?.document?.ID);
                                    setToggleOrganizationAssignmentConfirmationModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <CorporateFareTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                <span>
                                    Assign to Organization
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const driverID = row.original.document.ID;
                                    const driverProfileImageURL = row.original?.document?.profileImageURL;
                                    const valuesToSet = {
                                        licenseID: row.original.document.licenseID,
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        gender: row.original.document.gender,
                                        phoneNumber: row.original.document.phone,
                                        country: row.original.document.country,
                                    };

                                    setSelectedDriverID(driverID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setDriverProfileImgURL(driverProfileImageURL);
                                    setShow(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit Rider Details
                            </MenuItem>,
                            <MenuItem
                                key={9}
                                onClick={async () => {

                                    row?.original?.document?.subscriptionPlan ? setEditState(true) : setEditState(false);

                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                    setSubscriptionDateInitialValues({
                                        startDate: "",
                                        driverID: row.original.document.ID,
                                    });
                                    setCreateEditText("Set Date");
                                    setShowSubscriptionDateModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <TodayTwoToneIcon
                                        color='success'
                                    />
                                </ListItemIcon>
                                Set Subscription Start Date
                            </MenuItem>,
                            <MenuItem
                                key={7}
                                onClick={async () => {

                                    const churnState = row.original.document.isChurned;
                                    if (churnState === true) {

                                        setDriverID(row.original.document.ID);

                                        setSelectedDriverName(`${row.original.document?.firstName} ${row.original.document?.lastName}`);
                                        setToggleDriverUnchurnConfirmationModal(true);

                                        return closeMenu();

                                    } else {

                                        setDriverID(row.original.document.ID);
                                        setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                        setCreateEditText("Churn Rider");
                                        setShowChurnDriverModal(true);

                                        return closeMenu();

                                    }

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    {row?.original?.document.isChurned ?
                                        (<>
                                            <AssignmentReturnTwoToneIcon
                                                color='success'
                                            />
                                        </>) : (<>
                                            <ExitToAppTwoToneIcon
                                                color='warning'
                                            />
                                        </>)}
                                </ListItemIcon>
                                {row?.original?.document.isChurned ? "Unchurn Rider" : "Churn Rider"}
                            </MenuItem>,
                            <MenuItem
                                key={11}
                                onClick={async () => {

                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                    setDriverData(row.original);

                                    setTogglePauseSubscriptionConfirmationModal(true);
                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    {row?.original?.document?.isSubscriptionsPaused ? (<PlayCircleFilledTwoToneIcon
                                        color='success'
                                    />) : (<PauseCircleFilledTwoToneIcon
                                        color='error'
                                    />)}
                                </ListItemIcon>
                                <span>
                                    {row?.original?.document?.isSubscriptionsPaused ? "Unpause Subscription" : "Pause Subscription"}
                                </span>
                            </MenuItem>,
                            <MenuItem
                                key={4}
                                onClick={() => {

                                    const driverID = row.original.document.ID;

                                    setSelectedDriverID(driverID);
                                    setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                    setInitialPhoneValue({
                                        phone: row.original.document.phone
                                    });
                                    setCreateEditText("Update Phone Number");
                                    setShowPhoneUpdateModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <TtyTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                Update Phone Number
                            </MenuItem>,
                            <MenuItem
                                key={15}
                                onClick={() => {

                                    const driverID = row.original.document?.ID;
                                    setSelectedDriverID(driverID);

                                    setSelectedDriverName(`${row.original.document?.firstName} ${row.original.document?.lastName}`);

                                    const valuesToSet = {
                                        messageHeader: "Wahu",
                                        messageBody: "",
                                    };
                                    setInitialValues(valuesToSet);
                                    setDriverData({
                                        userIDs: [row.original.document.ID],
                                        messageHeader: "Wahu",
                                        messageSubject: "",
                                        messageBody: "",
                                        channel: "sms",
                                        senderID: user.user_data.document.ID,
                                        senderFirstName: user.user_data.document.firstName,
                                        senderLastName: user.user_data.document.lastName,
                                    });

                                    setCreateEditText("Send SMS");
                                    setShowSMSModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <SmsTwoToneIcon
                                        color='secondary' />
                                </ListItemIcon>
                                Send SMS
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const driverID = row.original.document.ID;

                                    setSelectedDriverID(driverID);
                                    setDriverNameForUpdate(row.original.document.firstName + " " + row.original.document.lastName);
                                    setInitialEmailValue({
                                        email: row?.original?.document?.email ? row.original.document.email : ""
                                    });
                                    setCreateEditText("Update Email Address");
                                    setShowEmailUpdateModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon
                                    style={{ color: "#004225" }}
                                >
                                    <MarkEmailReadTwoToneIcon />
                                </ListItemIcon>
                                Update Email Address
                            </MenuItem>,
                            <MenuItem
                                key={16}
                                onClick={() => {

                                    const driverID = row.original.document?.ID;
                                    setSelectedDriverID(driverID);

                                    setSelectedDriverName(`${row.original.document?.firstName} ${row.original.document?.lastName}`);

                                    const valuesToSet = {
                                        userIDs: "",
                                        messageHeader: "",
                                        messageSubject: "",
                                        messageBody: "",
                                        channel: "email",
                                        senderID: user.user_data.document.ID,
                                        senderFirstName: user.user_data.document.firstName,
                                        senderLastName: user.user_data.document.lastName,
                                        phone: "",
                                        email: row.original.document.email,
                                        recipientFirstName: row.original.document.firstName,
                                        recipientLastName: row.original.document.lastName,
                                        notifier: "",
                                        subNotice: "",
                                        salutation: "",
                                        replyToEmail: "",
                                        isNoReply: false,
                                        copyRecipients: "",
                                    };
                                    setInitialValues(valuesToSet);
                                    setDriverData({
                                        ID: row.original.document.ID,
                                        document: row.original.document,
                                    });

                                    setCreateEditText("Send Email");
                                    setShowEmailModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <SendTwoToneIcon />
                                </ListItemIcon>
                                Send Email
                            </MenuItem>,
                            <MenuItem
                                key={5}
                                onClick={async () => {

                                    setCreateTransactionDefaultValues({
                                        firstName: row.original.document.firstName,
                                        lastName: row.original.document.lastName,
                                        phoneNumber: row.original.document?.phone ?? row.original.document?.phoneNumber,
                                        userID: row.original.document.ID,
                                        email: row?.original?.document?.email ?? "admin@wahu.me",
                                        amount: 0,
                                        currency: "GHS",
                                        isFirstPayment: false,
                                        isSubscriptionPayment: true,
                                        weeksPaid: 1,
                                        paymentDate: new Date().getTime(),
                                        setterID: user.userID,
                                    });
                                    setShowAddPaymentModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon
                                    style={{ color: "#cc8400" }}
                                >
                                    <LocalAtmTwoToneIcon />
                                </ListItemIcon>
                                Log Driver Payment
                            </MenuItem>,
                            <MenuItem
                                key={6}
                                onClick={async () => {

                                    setShowDriverTransactions(true);
                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);

                                    return closeMenu();
                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon
                                    style={{ color: "#990000" }}
                                >
                                    <ReceiptLongRoundedIcon
                                    />
                                </ListItemIcon>
                                View Driver Transactions
                            </MenuItem>,
                            <MenuItem
                                key={14}
                                onClick={async () => {

                                    setDriverID(row.original.document.ID);
                                    setSelectedDriverName(row.original.document.firstName + " " + row.original.document.lastName);
                                    setDriverData(row.original);
                                    setToggleDriverDeletionConfirmationModal(true);

                                    return closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AutoDeleteTwoToneIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                <span>
                                    Delete Rider Account
                                </span>
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAssignment = () => {

                                setDisableActionButtons(false);

                                if (!assignmentType) {

                                    setSeverity('error');
                                    TableMessage.current = "no assignment type set for this action";

                                    setDisableActionButtons(true);
                                    return triggerSnackBar();

                                }

                                switch ((assignmentType).toLowerCase()) {

                                    case 'organization':
                                        table.getSelectedRowModel().flatRows.map(async (row) => {

                                            try {

                                                if (!organizationID) {

                                                    setSeverity('error');
                                                    TableMessage.current = "no organization ID set for this action";

                                                    setDisableActionButtons(true);
                                                    return triggerSnackBar();

                                                }

                                                const driverAssignmentPayload = ({
                                                    driverID: row.original.document.ID,
                                                    organizationID: organizationID,
                                                    assignerID: user.user_data.document.ID
                                                });

                                                await assignDriversToOrganization(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                                    setSeverity('success');
                                                    TableMessage.current = driverAssignmentResponse.data.message;
                                                    triggerSnackBar();

                                                    setReRender(prevState => prevState + 1);
                                                    setDisableActionButtons(true);
                                                    return onChanger();

                                                }).catch((error) => {

                                                    setSeverity('error');
                                                    setDisableActionButtons(true);

                                                    if (error.response) {
                                                        TableMessage.current = error?.response?.data?.message;
                                                        triggerSnackBar();
                                                        return onChanger();
                                                    }

                                                    TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                                    triggerSnackBar();
                                                    return onChanger();

                                                });

                                            } catch (error) {

                                                setSeverity('error');
                                                TableMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? 'an unexpected error occurred';
                                                setDisableActionButtons(true);
                                                triggerSnackBar();

                                            }

                                        });

                                        break;

                                    default:
                                        setSeverity('error');
                                        TableMessage.current = 'assignment parent entity not set for this action';

                                        setDisableActionButtons(true);
                                        return triggerSnackBar();

                                }

                            };

                            const handleSelectionAction = () => {

                                setDisableActionButtons(false);

                                const selectedRows = table.getSelectedRowModel().rows;
                                console.log(rowSelection);
                                setStorageItem("selectedDriverRows", selectedRows);
                                selectionAction();

                            };

                            return (
                                <>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <Button
                                                color="info"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size='small'
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Page
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={
                                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                                }
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                variant="contained"
                                                size='small'
                                                color="secondary"
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Export Selected
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            <IconButton
                                                aria-label="reload"
                                                color='info'
                                                size="small"
                                                onClick={() => {
                                                    setReRender(prevState => prevState + 1);
                                                }}
                                            >
                                                <ReplayCircleFilledTwoToneIcon
                                                    fontSize='medium'
                                                />
                                            </IconButton>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            {assignAction && <Button
                                                color="secondary"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons || !assignAction}
                                                onClick={handleAssignment}
                                                variant="contained"
                                                size="small"
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Assign Drivers
                                            </Button>}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={2}>
                                            {isSelectionAction && <Button
                                                color="secondary"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || !disableActionButtons || !isSelectionAction}
                                                onClick={handleSelectionAction}
                                                variant="contained"
                                                size="small"
                                                className="text-nowrap"
                                                fullWidth
                                            >
                                                Select Riders
                                            </Button>}
                                        </Grid>
                                    </Grid>
                                </>
                            );
                        }}
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        enableColumnResizing
                        paginationDisplayMode="pages"
                        muiSelectCheckboxProps={{
                            color: 'primary',
                        }}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '450px',
                            }
                        }}
                        muiTableBodyRowProps={({ row, table }) => {
                            const { density } = table.getState();
                            return {
                                sx: {
                                    //Set a fixed height for pinned rows
                                    height: row.getIsPinned() ?
                                        `${density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69}px`
                                        :
                                        undefined,
                                },
                            };
                        }}
                        autoResetSelectedRows={false}
                        autoResetPageIndex={false}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}