import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { createFaultSubCategory, editFaultSubCategory } from "../../../../app/modules/Auth/_redux/authCrud";
import { Button, Form, FormGroup } from "react-bootstrap";
import { FormAlert } from "../../controls";
import { FLEET_MANAGING_BASE_URL } from "../../../../app/services/baseService";
import { Typeahead } from 'react-bootstrap-typeahead';
import Axios from "axios";

export function CreateEditSubFaultCategory({ initialValues, onChanger, editState, createEditText, fieldDisabled, faultSubCategoryId }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [categoryData, setCategoryData] = useState([]);
    const [categoryDataLoading, setCategoryDataLoading] = useState(true);


    const creationSchema = Yup.object().shape({
        name: Yup.string().required(),
        parentCategoryID: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            const url = new URL('/fault-categories', `${FLEET_MANAGING_BASE_URL}`);

            if (!categoryData.length) {
                setCategoryDataLoading(true);
            }

            try {
                const response = await Axios.get(url.href);
                const data = response.data;
                isMounted && setCategoryData(data.data);

            } catch (error) {
                // find something to do about this error
                console.log(error);
            } finally {
                isMounted && setCategoryDataLoading(false);
            }

        };

        fetchData();
        return () => {
            isMounted = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            enableLoading();
            setTimeout(
                () => {
                    if (!editState) {
                        createFaultSubCategory(values).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: 'fa fa-check-circle mana-icon-white'
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }
                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'danger',
                                icon: 'flaticon-warning mana-icon-white'
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);

                            return;
                        });
                        return;
                    }
                    if (editState === true) {

                        const editPayload = {
                            name: values.name,
                            parentCategoryID: values.parentCategoryID
                        };

                        editFaultSubCategory(editPayload, faultSubCategoryId).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: 'fa fa-check-circle mana-icon-white'
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }

                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'danger',
                                icon: 'flaticon-warning mana-icon-white'
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);
                            return;
                        });
                        return;
                    }
                }, 3000
            );

        }
    });

    // console.log(categoryData)

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup controlId='subCategoryName'>
                    <Form.Label>
                        Sub-Category Name
                    </Form.Label>
                    <Form.Control type="text" placeholder="Brake Caliper" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text>
                        Name of fault sub-category
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Sub-category name cannot be empty
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="parentCategoryID">
                    <Form.Label>
                        Choose Category
                    </Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.parentCategoryID} onChange={formik.handleChange} className={`${getInputClasses("parentCategoryID")}`} name="parentCategoryID" {...formik.getFieldProps("parentCategoryID")}>
                        {categoryData.map((category) => {
                            return (
                                <option key={category.faultCategoryID} value={category.faultCategoryID}>
                                    {category.document.name}
                                </option>
                            );
                        })
                        }
                    </Form.Control>
                    <Form.Text className="text-muted" >
                        Select the category to which the sub-category belong
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Category cannot be black
                    </Form.Control.Feedback>
                    {formik.touched.parentCategoryID && formik.errors.parentCategoryID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.parentCategoryID}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}