import { useFormik } from 'formik';
import React, { useState } from 'react';
import { Button, Form, FormGroup } from 'react-bootstrap';
import * as Yup from "yup";
import { FormAlert } from '../../controls';
import { createFaultCategory, editFaultCategory } from '../../../../app/modules/Auth/_redux/authCrud';
import getStorage from 'redux-persist/es/storage/getStorage';
import { getStorageItem } from '../../../../app/services/baseService';

export function CreateEditFaultCategory({ initialValues, onChanger, editState, createEditText, fieldDisabled, faultCategoryId }) {
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);


    const creationSchema = Yup.object().shape({
        name: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            enableLoading();
            setTimeout(
                () => {
                    if (!editState) {
                        createFaultCategory(values).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: 'fa fa-check-circle mana-icon-white'
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }
                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'danger',
                                icon: 'flaticon-warning mana-icon-white'
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);

                            return;
                        });

                        return;

                    }
                    if (editState === true) {

                        const editPayload = {
                            name: values.name
                        };

                        editFaultCategory(editPayload, faultCategoryId).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: 'fa fa-check-circle mana-icon-white'
                                });
                                onChanger();
                                return setStatus(response.data.message);
                            }

                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'danger',
                                icon: 'flaticon-warning mana-icon-white'
                            });

                            onChanger();
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );
                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);
                            return;
                        });
                        return;
                    }
                }, 3000
            );

        }
    });
    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup controlId='categoryName'>
                    <Form.Label>
                        Category Name
                    </Form.Label>
                    <Form.Control type="text" placeholder="Brake System" value={formik.values.name} onChange={formik.handleChange} className={`${getInputClasses("name")}`} name="name" {...formik.getFieldProps("name")} />
                    <Form.Text>
                        Name of fault category
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                        Category name cannot be empty
                    </Form.Control.Feedback>
                    {formik.touched.name && formik.errors.name ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.name}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}