import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';

export function SimpleModal(props) {

    return (
        <>
            <Modal
                show={props.show}
                onHide={props.close}
                size={props.size}
                centered
                backdrop={props?.backdrop ? props.backdrop : "static"}
                animation={true}
                keyboard={props?.keyboard ? props.keyboard : true}
                scrollable={true}
                className='rounded-xl'
            >
                <Modal.Header
                    closeButton
                    closeLabel='Exit'
                >
                    <Modal.Title>
                        {props.modal_tittle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='mb-5 centerMan'>
                        {props.infoAnnotation && <small className='label label-lg label-light-info label-inline label-rounded p-8'>
                            <InfoTwoToneIcon />
                            {" "}
                            {props.infoAnnotation}
                        </small>}
                    </div>
                    {props.body}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={props.close}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}