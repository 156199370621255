import { useFormik } from "formik";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import * as Yup from "yup";
import { FormAlert } from "../../controls";
import { sendBulkMessages } from "../../../../app/modules/Auth/_redux/authCrud";
import { getStorageItem } from "../../../../app/services/baseService";
import {
    Button,
} from "@mui/material";
import { trimByComma } from "../../../_helpers";
import { shallowEqual, useSelector } from "react-redux";

export function BulkEmailForm({ onChanger }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });

    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        messageSubject: Yup.string(),
        messageBody: Yup.string().required(),
        notifier: Yup.string().required("message notifier field after salutation cannot be left blank"),
        subNotice: Yup.string(),
        salutation: Yup.string(),
        replyToEmail: Yup.string().email().required(),
        copyRecipients: Yup.string(),
    });

    const initialValues = {
        userIDs: [],
        messageHeader: "",
        messageSubject: "",
        messageBody: "",
        channel: "email",
        senderID: "",
        senderFirstName: "",
        senderLastName: "",
        copyRecipients: "",
        notifier: "",
        subNotice: "",
        salutation: "",
        replyToEmail: "",
        isNoReply: false,
    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({

        initialValues: initialValues,
        validationSchema: creationSchema,

        onSubmit: (values, { setStatus, setSubmitting }) => {

            const userIDArray = [];
            const driverDataArray = getStorageItem("selectedDriverRows");

            values.senderID = user.user_data.document.ID;
            values.senderFirstName = user.user_data.document.firstName;
            values.senderLastName = user.user_data.document.lastName;

            values.senderLastName = user.user_data.document.lastName;
            values.senderLastName = user.user_data.document.lastName;
            values.senderLastName = user.user_data.document.lastName;

            const copyEmailArray = trimByComma(values.copyRecipients);
            values.copyEmailArray = copyEmailArray;

            for (let i = 0; i < driverDataArray.length; i++) {

                const driverData = driverDataArray[i];
                const driverID = driverData.original.document.ID;
                userIDArray.push(driverID);

                if ((1 + i) === driverDataArray.length) {

                    values.userIDs = userIDArray;
                    enableLoading();

                    setTimeout(() => {

                        sendBulkMessages(values).then((response) => {
                            disableLoading();
                            setSubmitting(false);

                            if (response) {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'success',
                                    icon: 'fa fa-check-circle mana-icon-white'
                                });
                                onChanger();
                                return setStatus(response.data.message);

                            }

                        }).catch((error) => {
                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'danger',
                                icon: 'flaticon-warning mana-icon-white'
                            });
                            return setStatus(
                                error.response.data?.error?.code
                                ??
                                error.response.data.message
                                ??
                                'an error occurred, please try again later'
                            );

                        }).finally(() => {
                            disableLoading();
                            setSubmitting(false);
                            return;
                        });

                    }, 1000);

                }

            }

        }

    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit} className="mt-2">

                <Form.Group controlId="messageSubject">
                    <Form.Label>
                        Subject
                    </Form.Label>
                    <Form.Control type="text" placeholder="Subject" value={formik.values.messageSubject} onChange={formik.handleChange} className={`${getInputClasses("messageSubject")}`} name="messageSubject" {...formik.getFieldProps("messageSubject")} />
                    <Form.Text className="text-muted">
                        This is optional
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Subject field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageSubject && formik.errors.messageSubject ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageSubject}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="salutation">
                    <Form.Label>Salutation</Form.Label>
                    <Form.Control type="text" placeholder="Email salutation" value={formik.values.salutation} onChange={formik.handleChange} className={`${getInputClasses("salutation")}`} name="salutation" {...formik.getFieldProps("salutation")} />
                    <Form.Text className="text-muted">
                        Email salutation(Optional: You can leave this empty)
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email salutation field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.salutation && formik.errors.salutation ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.salutation}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="replyToEmail">
                    <Form.Label>
                        ReplyTo Email
                        <span className="required">
                            {" "}
                            *
                        </span>
                    </Form.Label>
                    <Form.Control type="email" placeholder="replyTo Email" value={formik.values.replyToEmail} onChange={formik.handleChange} className={`${getInputClasses("replyToEmail")}`} name="replyToEmail" {...formik.getFieldProps("replyToEmail")} />
                    <Form.Text className="text-muted">
                        ReplyTo Email
                    </Form.Text>
                    <Form.Control.Feedback>valid email!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        ReplyTo Email field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.replyToEmail && formik.errors.replyToEmail ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.replyToEmail}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="messageBody">
                    <Form.Label>
                        Message
                    </Form.Label>
                    <Form.Control as={"textarea"} rows={4} placeholder="Message" value={formik.values.messageBody} onChange={formik.handleChange} className={`${getInputClasses("messageBody")}`} name="messageBody" {...formik.getFieldProps("messageBody")} />
                    <Form.Text className="text-muted">
                        Enter your message
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Message field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageBody && formik.errors.messageBody ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageBody}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="copyRecipients">
                    <Form.Label>
                        Emails to copy
                    </Form.Label>
                    <Form.Control type="text" placeholder="Emails to copy when you send this mail" value={formik.values.copyRecipients} onChange={formik.handleChange} className={`${getInputClasses("copyRecipients")}`} name="copyRecipients" {...formik.getFieldProps("copyRecipients")} />
                    <Form.Text className="text-muted">
                        <span className="bg-light-danger p-1 rounded-xl">Add all emails you want to keep on copy on this mail separate each with a comma.</span>
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Emails to copy field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.copyRecipients && formik.errors.copyRecipients ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.copyRecipients}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="notifier">
                    <Form.Label>
                        Sending Team
                        <span className="required">
                            {" "}
                            *
                        </span>
                    </Form.Label>
                    <Form.Control type="text" placeholder="The team sending the email" value={formik.values.notifier} onChange={formik.handleChange} className={`${getInputClasses("notifier")}`} name="notifier" {...formik.getFieldProps("notifier")} />
                    <Form.Text className="text-muted">
                        Sending team
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Sending team field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.notifier && formik.errors.notifier ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.notifier}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="subNotice">
                    <Form.Label>Sub Notice</Form.Label>
                    <Form.Control type="text" placeholder="Sub Notice" value={formik.values.subNotice} onChange={formik.handleChange} className={`${getInputClasses("subNotice")}`} name="subNotice" {...formik.getFieldProps("subNotice")} />
                    <Form.Text className="text-muted">
                        Sub Notice(Optional: You can leave this empty)
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Sub Notice field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.subNotice && formik.errors.subNotice ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.subNotice}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert
                    isEnabled={isFormAlertEnabled}
                    type={alertType.type}
                    message={formik.status}
                    icon={alertType.icon}
                    handleDismiss={handleAlertDismiss}
                /> : null}

                <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={formik.isSubmitting}
                    className={`font-weight-bold px-9 py-4 my-3`}
                >
                    Send Email
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );

}