import {
    IconButton,
    Snackbar,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    TextField,
    Typography,
    Chip,
    Avatar
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { assignDriversToOrganization } from '../../../../app/modules/Auth/_redux/authCrud';
import { AUTH_BASE_URL, getStorageItem, removeStorageItem } from '../../../../app/services/baseService';
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import BasicLoader from '../../loaders/SimpleLoader';
import SportsMotorsportsTwoToneIcon from '@mui/icons-material/SportsMotorsportsTwoTone';
import ManageAccountsTwoToneIcon from '@mui/icons-material/ManageAccountsTwoTone';
import PersonOutlineTwoToneIcon from '@mui/icons-material/PersonOutlineTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

export function DriversTableSub({ tableClassName, onChanger, organizationID }) {

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [showLoader, setShowLoader] = useState('d-none');

    const TableErrorMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    const driverDelegationRenderer = (rowData) => {

        const isStaffVehicleBool = rowData?.document?.isStaffAccount;
        const isCustomerVehicle = rowData?.document?.isTestAccount;

        if (isStaffVehicleBool === true) {
            return (
                <Chip label="Staff Account" variant="outlined" color="primary" icon={<ManageAccountsTwoToneIcon />}></Chip>
            );
        }

        if (isCustomerVehicle === true) {
            return (
                <Chip label="Test Account" variant="outlined" color="secondary" icon={< PersonOutlineTwoToneIcon />}></Chip >
            );
        }

        return (
            <Chip label="Rider Account" variant="outlined" color="default" icon={<SportsMotorsportsTwoToneIcon />}></Chip>
        );

    };

    function triggerSnackBar() {
        setOpen(true);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/users/drivers/getAll', `${AUTH_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            id: 'designation',
            header: 'Designation',
            Cell: ({ cell, row }) => (
                driverDelegationRenderer(row.original)
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.firstName} ${row?.document?.lastName}`,
            id: 'name',
            header: 'Name',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Avatar
                        alt={row.original?.document?.firstName}
                        src={row.original?.document?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")}
                        className={classes.bigAvatar}
                    />
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => `${row?.document?.email ?? ""}`,
            id: "email",
            enableClickToCopy: true,
            header: 'Email'
        },
        {
            accessorFn: (row) => row?.document?.phoneNumber,
            id: "phone",
            enableClickToCopy: true,
            header: 'Phone Number'
        },
        {
            accessorFn: (row) => row?.document?.country,
            id: "country",
            enableClickToCopy: true,
            header: 'Country'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.info.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ],
        [classes.bigAvatar],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}
                    >
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <div className={`${tableClassName}`}>
                <div className="pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowActions
                        enableRowPinning
                        enableStickyFooter
                        enableStickyHeader
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <Box sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
                                <img alt="avatar" height={80} src={row.original?.document_data?.profileImageURL ?? toAbsoluteUrl("/media/users/default.jpg")} loading="lazy" style={{ borderRadius: '50%' }} />
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography variant="label">ID: </Typography>
                                    <Typography variant="p">
                                        {row.original.document?.ID}
                                    </Typography>
                                </Box>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleAssignment = () => {
                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        setShowLoader('');
                                        handleSnackbarClose();

                                        const driverAssignmentPayload = ({
                                            driverID: row.original.document.ID,
                                            organizationID: getStorageItem('organizationIdForAssignment'),
                                            assignerID: getStorageItem('user').user_data.document.ID
                                        });

                                        await assignDriversToOrganization(driverAssignmentPayload).then((driverAssignmentResponse) => {

                                            setSeverity('success');
                                            TableErrorMessage.current = driverAssignmentResponse.data.message;
                                            triggerSnackBar();

                                            setReRender(prevState => prevState + 1);
                                            setShowLoader('d-none');
                                            removeStorageItem('organizationIdForAssignment');
                                            return onChanger();

                                        }).catch((error) => {

                                            setSeverity('warning');

                                            if (error.response) {
                                                TableErrorMessage.current = error?.response?.data?.message;
                                                triggerSnackBar();
                                                setShowLoader('d-none');
                                                return onChanger();
                                            }

                                            TableErrorMessage.current = error ?? "an expected error occurred";
                                            triggerSnackBar();
                                            setShowLoader('d-none');
                                            return onChanger();

                                        });

                                    } catch (error) {
                                        setSeverity('warning');
                                        TableErrorMessage.current = error.message ?? error ?? 'an unexpected error occurred';
                                        triggerSnackBar();
                                        setShowLoader('d-none');
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="primary"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0}
                                        onClick={handleAssignment}
                                        variant="contained"
                                        size="small"
                                    >
                                        <BasicLoader hideShowLoader={showLoader} size="sm" />
                                        {" "}
                                        Assign Driver(s)
                                    </Button>
                                </div>
                            );
                        }}
                        enableColumnResizing
                        muiSelectCheckboxProps={{
                            color: 'secondary',
                        }}
                        rowPinningDisplayMode='select-sticky'
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '400px',
                            }
                        }}
                        muiTableBodyRowProps={({ row, table }) => {
                            const { density } = table.getState();
                            return {
                                sx: {
                                    //Set a fixed height for pinned rows
                                    height: row.getIsPinned() ?
                                        `${
                                        //Default mrt row height estimates. Adjust as needed.
                                        density === 'compact' ? 37 : density === 'comfortable' ? 53 : 69
                                        }px`
                                        : undefined,
                                },
                            };
                        }}
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                    />
                </div>
            </div>
        </>
    );
}
