import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { pauseDriverSubscription, unpauseDriverSubscription } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';
import { shallowEqual, useSelector } from "react-redux";

export function PauseDriverSubscriptionForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, driverData, modalCloseCallback }) {

    const loggedInUser = useSelector(({ auth }) => auth.user, shallowEqual);

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const creationSchema = Yup.object().shape({
        notes: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    values.driverID = driverData.document.ID;
                    values.adderID = loggedInUser.user_data.document.ID;

                    pauseDriverSubscription(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);
                        setFormAlertEnabled(false);

                        if (response) {

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            setStatus(response.data.message);

                            return setFormAlertEnabled(true);

                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                        disableLoading();
                        setSubmitting(false);

                        return setFormAlertEnabled(true);

                    });

                    return;

                }

                if (editState) {

                    console.log(driverData.document.ID);

                    unpauseDriverSubscription(driverData.document.ID).then((response) => {

                        disableLoading();
                        setSubmitting(false);
                        setFormAlertEnabled(false);

                        if (response) {

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            setStatus(response.data.message);

                            return setFormAlertEnabled(true);

                        }

                    }).catch((error) => {

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                        disableLoading();
                        setSubmitting(false);

                        return setFormAlertEnabled(true);

                    });

                    return;

                }

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="driverFormNotes">
                    <Form.Label>Why is this rider's subscription being paused?</Form.Label>
                    <Form.Control as={"textarea"} placeholder="Reason" rows={6} value={formik.values.notes} onChange={formik.handleChange} className={`${getInputClasses("notes")}`} name="notes" {...formik.getFieldProps("notes")} disabled={fieldDisabled} />
                    <Form.Text className="text-muted">
                        Enter reason here
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Reason provided is invalid
                    </Form.Control.Feedback>
                    {formik.touched.notes && formik.errors.notes ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.notes}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}