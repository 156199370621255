import {
    getAuth,
    signOut
} from "firebase/auth";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { clearStorage } from "../../../services/baseService";
import { getUserByToken } from "./authCrud";

export const actionTypes = {
    Login: "[Login] Action",
    Logout: "[Logout] Action",
    Register: "[Register] Action",
    UserRequested: "[Request User] Action",
    DataRequested: "[Request Data] Action",
    UserLoaded: "[Load User] Auth API",
    DataLoaded: "[Load Data] Auth API",
    SetUser: "[Set User] Action",
    SetData: "[Set Data] Action",
    AuthDTO: "[AuthDTO] Action",
    PassOnData: "[PassOnData] Action",
};

const initialAuthState = {
    user: undefined,
    authToken: undefined,
    authDTO: undefined,
    passOnData: undefined
};

const authenticator = getAuth();

export const reducer = persistReducer(
    {
        storage,
        key: "v001-auth",
        whitelist: [
            "authToken",
            "authDTO",
            "user",
            "data"
        ]
    },
    (state = initialAuthState, action) => {
        switch (action.type) {

            case actionTypes.Login: {
                const { authToken } = action.payload;
                return { authToken: authToken.accessToken, user: undefined, authDTO: authToken };
            }

            case actionTypes.Register: {
                const { authToken } = action.payload;
                return { authToken: authToken.accessToken, user: undefined, authDTO: authToken };
            }

            case actionTypes.Logout: {

                return signOut(authenticator).then(() => {
                    clearStorage();
                    return initialAuthState;
                }).catch(() => {
                    clearStorage();
                    return initialAuthState;
                });
            }

            case actionTypes.UserLoaded: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.DataLoaded: {
                const { data } = action.payload;
                return { ...state, data };
            }

            case actionTypes.SetUser: {
                const { user } = action.payload;
                return { ...state, user };
            }

            case actionTypes.SetData: {
                const { data } = action.payload;
                return { ...state, data };
            }

            default:
                return state;
        }
    }
);

export const actions = {

    login: (authToken) => ({
        type: actionTypes.Login,
        payload: { authToken }
    }),
    register: (authToken) => ({
        type: actionTypes.Register,
        payload: { authToken },
    }),
    logout: () => ({
        type: actionTypes.Logout
    }),
    requestUser: (user) => ({
        type: actionTypes.UserRequested,
        payload: { user }
    }),
    requestData: (data) => ({
        type: actionTypes.DataRequested,
        payload: { data }
    }),
    fulfillUser: (user) => ({
        type: actionTypes.UserLoaded,
        payload: { user }
    }),
    fulfillData: (data) => ({
        type: actionTypes.DataLoaded,
        payload: { data }
    }),
    setUser: (user) => ({
        type: actionTypes.SetUser,
        payload: { user }
    }),
    setData: (data) => ({
        type: actionTypes.SetData,
        payload: { data }
    }),

};

export function* saga() {
    yield takeLatest(actionTypes.Login, function* loginSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.Register, function* registerSaga() {
        yield put(actions.requestUser());
    });

    yield takeLatest(actionTypes.UserRequested, function* userRequested() {
        const { data: user } = yield getUserByToken();
        yield put(actions.fulfillUser(user));
    });

    yield takeLatest(actionTypes.DataRequested, function* dataRequested() {
        yield put(actions.fulfillData());
    });
}
