import {
    IconButton,
    Snackbar,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    TextField,
    Typography,
    Tooltip,
    Grid,
    Paper,
    Alert,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    getMotor,
    getBattery,
    getManufacturer,
    deactivateVehicleModel,
    activateVehicleModel
} from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { SimpleModal } from '../modals/SimpleModal';
import { CreateEditVehicleModelForm } from '../forms/CreateEditVehicleModelForm';
import { Edit, Delete } from '@mui/icons-material';
import { styled } from '@mui/material';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import ElectricMeterTwoToneIcon from '@mui/icons-material/ElectricMeterTwoTone';
import { GridList } from '../data-displays/GridList';
import PrecisionManufacturingTwoToneIcon from '@mui/icons-material/PrecisionManufacturingTwoTone';
import BasicLoader from '../../loaders/SimpleLoader';
import Battery50TwoToneIcon from '@mui/icons-material/Battery50TwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function VehicleModelsSettingsTableSub({ tableClassName }) {

    const classes = useStyles();

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [show, setShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        vehicleTypeID: '',
        manufacturerID: '',
        frameMaterial: '',
        motorID: '',
        batteryID: '',
        brakeSystem: '',
        throttleType: '',
        transmission: '',
        suspension: '',
        loadLimit: 0,
        loadArea: '',
        range: 0,
        maxSpeed: 0,
        chargingTime: '',
        vehicleWeight: 0,
        lights: '',
        wheelbase: ''
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Vehicle Model');
    const [modalHeaderText, setModalHeaderText] = useState('Add Vehicle Model');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [selectedVehicleModelID, setSelectedVehicleModelID] = useState("");

    const [motorSpecs, setMotorSpecs] = useState([]);
    const [manufactureDetails, setManufactureDetails] = useState([]);
    const [batteryDetails, setBatteryDetails] = useState([]);

    const [isLoadingMotorData, setIsLoadingMotorData] = useState(false);
    const [isLoadingManufacturerDetails, setIsLoadingManufacturerDetails] = useState(false);
    const [isLoadingBatteryDetails, setIsLoadingBatteryDetails] = useState(false);

    const [openMotorSpecsModal, setOpenMotorSpecsModal] = useState(false);
    const [openManufacturerSpecsModal, setOpenManufacturerSpecsModal] = useState(false);
    const [openBatteryDetailsModal, setOpenBatteryDetailsModal] = useState(false);

    const TableErrorMessage = useRef();
    const batteryType = useRef();

    const defaultValuesToSet = {
        vehicleTypeID: '',
        manufacturerID: '',
        frameMaterial: '',
        motorID: '',
        batteryID: '',
        brakeSystem: '',
        throttleType: '',
        transmission: '',
        suspension: '',
        loadLimit: 0,
        loadArea: '',
        range: 0,
        maxSpeed: 0,
        chargingTime: '',
        vehicleWeight: 0,
        lights: '',
        wheelbase: ''
    };

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    }

    function triggerSnackBar() {
        setOpen(true);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const returnMotorType = (motorID) => {

        if (motorID) {

            setIsLoadingMotorData(true);

            return getMotor(motorID).then((response) => {

                const name = response?.data?.document?.type;
                const nominalWattage = response?.data?.document?.nominalWattage;
                const peakWattage = response?.data?.document?.peakWattage;
                const operatingVoltage = response?.data?.document?.operatingVoltage;

                const resArray = [
                    {
                        header: "Name",
                        field: name,
                    },
                    {
                        header: "Nominal Wattage",
                        field: `${nominalWattage}kw`,
                    },
                    {
                        header: "Peak Wattage",
                        field: `${peakWattage}kw`,
                    },
                    {
                        header: "Operating Voltage",
                        field: `${operatingVoltage}v`,
                    },
                ];

                setMotorSpecs(resArray);
                setIsLoadingMotorData(false);
                setOpenMotorSpecsModal(true);

            }).catch((error) => {

                setSeverity("error");
                TableErrorMessage.current = error?.response?.data?.message ?? "could not load motor specs at this time";
                triggerSnackBar();
                setIsLoadingMotorData(false);

            });

        }

        setSeverity("error");
        TableErrorMessage.current = "no motor ID specified";
        return triggerSnackBar();

    };

    const returnBatteryType = (batteryID) => {

        if (batteryID) {

            setIsLoadingBatteryDetails(true);

            return getBattery(batteryID).then((response) => {

                const capacity = response?.data?.document?.capacity;
                const operatingVoltage = response?.data?.document?.operatingVoltage;
                const peakVoltage = response?.data?.document?.peakVoltage;
                const type = response?.data?.document?.type;

                const resArray = [
                    {
                        header: "Capacity",
                        field: `${capacity}Ah`,
                    },
                    {
                        header: "Operating Voltage",
                        field: `${operatingVoltage}v`,
                    },
                    {
                        header: "Peak Voltage",
                        field: `${peakVoltage}v`,
                    },
                    {
                        header: "Type",
                        field: type,
                    },
                ];

                setBatteryDetails(resArray);
                setIsLoadingBatteryDetails(false);
                setOpenBatteryDetailsModal(true);

            }).catch((error) => {

                setSeverity("error");
                TableErrorMessage.current = error?.response?.data?.message ?? "could not load battery details at this time";
                triggerSnackBar();
                setIsLoadingBatteryDetails(false);

            });

        }

        setSeverity("error");
        TableErrorMessage.current = "no battery ID specified";
        return triggerSnackBar();

    };

    const returnManufacturer = (manufacturerID) => {

        if (manufacturerID) {

            setIsLoadingManufacturerDetails(true);

            return getManufacturer(manufacturerID).then((response) => {

                const name = response?.data?.document?.name;
                const contactPerson = response?.data?.document?.contactPerson;
                const phone = response?.data?.document?.phone;
                const email = response?.data?.document?.email;
                const location = response?.data?.document?.location;

                const resArray = [
                    {
                        header: "Name",
                        field: name,
                    },
                    {
                        header: "Contact Person",
                        field: contactPerson,
                    },
                    {
                        header: "Phone Number",
                        field: phone,
                    },
                    {
                        header: "Email",
                        field: email,
                    },
                    {
                        header: "Location",
                        field: location,
                    },
                ];

                setManufactureDetails(resArray);
                setIsLoadingManufacturerDetails(false);
                setOpenManufacturerSpecsModal(true);

            }).catch((error) => {

                setSeverity("error");
                TableErrorMessage.current = error?.response?.data?.message ?? "could not load manufacturer details at this time";
                triggerSnackBar();
                setIsLoadingManufacturerDetails(false);

            });

        }

        setSeverity("error");
        TableErrorMessage.current = "no manufacturer ID specified";
        return triggerSnackBar();

    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/vehicleModels/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpen(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.vehicleType?.name,
            id: 'name',
            header: 'Name',
            enableClickToCopy: true,
        },
        {
            accessorFn: (row) => row?.document?.frameMaterial,
            id: 'materialType',
            header: 'Material Type',
            enableClickToCopy: true,
        },
        {
            accessorFn: (row) => row?.document?.manufacturer?.name,
            id: 'manufacturerName',
            header: 'Manufacturer',
            enableClickToCopy: true,
        },
        {
            accessorFn: (row) => row?.document?.motor?.name,
            id: 'motorType',
            header: 'Motor Type',
            enableClickToCopy: true,
        },
        {
            accessorFn: (row) => row?.document?.isActive ? 'Active' : 'inactive',
            header: 'Status',
            enableClickToCopy: true,
            Cell: ({ cell, row }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: row?.original?.document?.isActive === true ? theme.palette.success.dark : row?.original?.document?.isActive === false ? theme.palette.error.dark : theme.palette.grey.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {row?.original?.document?.isActive === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less Than'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button
                        key="undo"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}
                    >
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={modalHeaderText}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditVehicleModelForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    vehicleModelID={selectedVehicleModelID}
                />}
            />
            <SimpleModal
                modal_tittle={"Motor Specifications"}
                show={openMotorSpecsModal}
                close={() => setOpenMotorSpecsModal(false)}
                size="lg"
                body={<GridList
                    dataArray={motorSpecs}
                />}
            />
            <SimpleModal
                modal_tittle={"Manufacturer Details"}
                show={openManufacturerSpecsModal}
                close={() => setOpenManufacturerSpecsModal(false)}
                size="lg"
                body={<GridList
                    dataArray={manufactureDetails}
                />}
            />
            <SimpleModal
                modal_tittle={"Battery Details"}
                show={openBatteryDetailsModal}
                close={() => setOpenBatteryDetailsModal(false)}
                size="lg"
                body={<GridList
                    dataArray={batteryDetails}
                />}
            />
            <div className={`card card-custom ${tableClassName}`}>
                <div className='centerMan mt-3'>
                    {isLoadingMotorData && (<>
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity='info'
                        >
                            <span>
                                loading motor specs...
                                {" "}
                                <BasicLoader size={'sm'} />
                            </span>
                        </Alert>
                    </>)}
                    {isLoadingManufacturerDetails && (<>
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity='info'
                        >
                            <span>
                                loading manufacturer details...
                                {" "}
                                <BasicLoader size={'sm'} />
                            </span>
                        </Alert>
                    </>)}
                    {isLoadingBatteryDetails && (<>
                        <Alert
                            elevation={6}
                            variant="filled"
                            severity='info'
                        >
                            <span>
                                loading battery details...
                                {" "}
                                <BasicLoader size={'sm'} />
                            </span>
                        </Alert>
                    </>)}
                </div>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Vehicle Models
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Vehicle Models from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Vehicle Model");
                                        setModalHeaderText("Add Vehicle Model");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Add Vehicle Model
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        displayColumnDefOptions={{
                            'mrt-row-actions': {
                                size: 220,
                                grow: false,
                            },
                        }}
                        renderDetailPanel={({ row }) => (

                            <>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Model ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Vehicle Weight: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.vehicleWeight}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Brake System: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.brakeSystem}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Charging Time: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.chargingTime}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Frame Material: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.frameMaterial}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Lights: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.lights}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Load Area: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.loadArea}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Load Limit: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.loadLimit}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Max Speed: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.maxSpeed}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Suspension: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.suspension}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Throttle Type: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.throttleType}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Transmission: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.transmission}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'> </Typography>
                                                <Typography variant="p">
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Battery Type: </Typography>
                                                <Typography variant="p">
                                                    {batteryType.current}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Vehicle Type: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.vehicleType.name}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={3}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Wheel Base: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.wheelbase}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>

                            </>

                        )}
                        renderRowActions={({ row, table }) => (
                            <Box sx={{ display: 'flex', gap: '.5rem', width: '200px' }}>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title="Edit">
                                    <IconButton
                                        onClick={() => {

                                            const vehicleModelID = row.original.document.ID;
                                            const valuesToSet = {
                                                vehicleTypeID: row.original.document.vehicleType.ID,
                                                manufacturerID: row.original.document.manufacturerID,
                                                frameMaterial: row.original.document.frameMaterial,
                                                motorID: row.original.document.motorID,
                                                batteryID: row.original.document.batteryID,
                                                brakeSystem: row.original.document.brakeSystem,
                                                throttleType: row.original.document.throttleType,
                                                transmission: row.original.document.transmission,
                                                suspension: row.original.document.suspension,
                                                loadLimit: row.original.document.loadLimit,
                                                loadArea: row.original.document.loadArea,
                                                range: row.original.document.range,
                                                maxSpeed: row.original.document.maxSpeed,
                                                chargingTime: row.original.document.chargingTime,
                                                vehicleWeight: row.original.document.vehicleWeight,
                                                lights: row.original.document.lights,
                                                wheelbase: +row.original.document.wheelbase
                                            };

                                            setSelectedVehicleModelID(vehicleModelID);

                                            setEditState(true);
                                            setFieldDisabled(true);

                                            setCreateEditText("Save Changes");
                                            setModalHeaderText("Edit Vehicle Model");
                                            setInitialValues(valuesToSet);
                                            setShow(true);

                                        }}
                                    >
                                        <Edit color='info' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="left"
                                    title="Delete">
                                    <IconButton>
                                        <Delete color='error' />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="right"
                                    title="Motor Specs">
                                    <IconButton
                                        onClick={() => {
                                            returnMotorType(row.original.document?.motorID);
                                        }}
                                    >
                                        <ElectricMeterTwoToneIcon
                                            color='secondary'
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="right"
                                    title="Manufacturer">
                                    <IconButton
                                        onClick={() => {
                                            returnManufacturer(row.original.document?.manufacturerID);
                                        }}
                                    >
                                        <PrecisionManufacturingTwoToneIcon
                                            color='primary'
                                        />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip
                                    arrow
                                    placement="right"
                                    title="Battery">
                                    <IconButton
                                        onClick={() => {
                                            returnBatteryType(row.original.document?.batteryID);
                                        }}
                                    >
                                        <Battery50TwoToneIcon
                                            color='warning'
                                        />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        )}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleDeactivation = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        handleSnackbarClose();

                                        const response = await deactivateVehicleModel(row.original.document.ID);
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        triggerSnackBar();
                                        setActionBtnDisabled(false);

                                    } catch (error) {

                                        setSeverity('warning');

                                        if (error.response) {
                                            TableErrorMessage.current = error?.response?.data?.message;
                                            triggerSnackBar();
                                            setActionBtnDisabled(false);
                                            return;
                                        }

                                        TableErrorMessage.current = error ?? "an expected error occurred";
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    }
                                });
                            };

                            const handleActivation = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {

                                        handleSnackbarClose();

                                        const response = await activateVehicleModel(row.original.document.ID);
                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    } catch (error) {

                                        setSeverity('warning');

                                        if (error.response) {
                                            TableErrorMessage.current = error?.response?.data?.message;
                                            setActionBtnDisabled(false);
                                            triggerSnackBar();
                                            return;
                                        }

                                        TableErrorMessage.current = error ?? "an expected error occurred";
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();

                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivation}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate Vehicle Model
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivation}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate Vehicle Model
                                    </Button>
                                </div>
                            );

                        }}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
