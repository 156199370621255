import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import {
    IconButton,
    Snackbar,
    styled,
    Alert,
    LinearProgress,
    linearProgressClasses,
    Chip,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FLEET_MANAGING_BASE_URL } from "../services/baseService";
import BasicLoader from "../../_wahu/_partials/loaders/SimpleLoader";
import BarChartAlt from '../../_wahu/_partials/charts/BarChatAlt';
import CloseIcon from '@mui/icons-material/Close';
import {
    Nav,
    Tab
} from 'react-bootstrap';
import { toAbsoluteUrl } from "../../_wahu/_helpers";
import SdCardAlertTwoToneIcon from '@mui/icons-material/SdCardAlertTwoTone';
import DiscFullTwoToneIcon from '@mui/icons-material/DiscFullTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    },
    rawMetricsDiv: {
        padding: 3,
        marginTop: 2,
        backgroundColor: "#d1d1d1"
    }
}));

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
    },
}));

export const VehicleMetricsPage = ({ vehicleID, vehicleData, vehicleVIN }) => {

    const [isDailyContentLoaded, setIsDailyContentLoaded] = useState(false);
    const [isWeeklyContentLoaded, setIsWeeklyContentLoaded] = useState(false);
    const [isMonthlyContentLoaded, setIsMonthlyContentLoaded] = useState(false);

    const [isDailyLoading, setIsDailyLoading] = useState(false);
    const [isWeeklyLoading, setIsWeeklyLoading] = useState(false);
    const [isMonthlyLoading, setIsMonthlyLoading] = useState(false);

    const [dailyMetricsDataYSeries, setDailyMetricsDataYSeries] = useState();
    const [weeklyMetricsDataYSeries, setWeeklyMetricsDataYSeries] = useState();
    const [monthlyMetricsDataYSeries, setMonthlyMetricsDataYSeries] = useState();

    const [monthlyOverSpeedingDataXSeries, setMonthlyOverSpeedingDataXSeries] = useState();
    const [monthlyOverSpeedingDataYSeries, setMonthlyOverSpeedingDataYSeries] = useState();

    const [monthlyHarshBrakingDataYSeries, setMonthlyHarshBrakingDataYSeries] = useState();

    const [monthlyLateNightMileageDataYSeries, setMonthlyLateNightMileageDataYSeries] = useState();

    const [dailyMetricsDataXSeries, setDailyMetricsDataXSeries] = useState();
    const [weeklyMetricsDataXSeries, setWeeklyMetricsDataXSeries] = useState();
    const [monthlyMetricsDataXSeries, setMonthlyMetricsDataXSeries] = useState();

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("info");

    const [key, setKey] = useState("Daily");

    const TableMessage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }

        setOpenSnackbar(false);
    }

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    useEffect(() => {

        let isMounted = true;
        setIsDailyLoading(true);

        const fetchData = async () => {

            const url = new URL(`/vehicles/telemetry/${vehicleID}`, `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dailyMileageArray = [];
                const weeklyMileageArray = [];
                const monthlyMileageArray = [];

                const dailyTimerArray = [];
                const weeklyTimerArray = [];
                const monthlyTimerArray = [];

                const monthlyOverSpeedingArray = [];
                const monthlyHarshBrakingArray = [];
                const monthlyMileageBeyondEveningCounterArray = [];

                for (let i = 0; i < data.dailyTelemetry.length; i++) {

                    const dailyMileage = data.dailyTelemetry[i].dailyMileage;
                    if (dailyMileage) {

                        if (dailyMileage < 0)
                            dailyMileageArray.push(0);
                        else
                            dailyMileageArray.push((dailyMileage).toFixed(2));

                        const timerSeries = data.dailyTelemetry[i].dailyTelemetryDocID;
                        dailyTimerArray.push(timerSeries);

                    }

                    if ((i + 1) === data.dailyTelemetry.length) {

                        setDailyMetricsDataYSeries(dailyMileageArray);
                        setDailyMetricsDataXSeries(dailyTimerArray);

                        setIsDailyLoading(false);
                        setIsDailyContentLoaded(true);

                    }

                }

                for (let i = 0; i < data.weeklyTelemetry.length; i++) {

                    const weeklyMileage = data.weeklyTelemetry[i].weeklyMileage;
                    if (weeklyMileage) {

                        if (weeklyMileage < 0)
                            weeklyMileageArray.push(0);
                        else
                            weeklyMileageArray.push((weeklyMileage).toFixed(2));

                        const timerSeries = data.weeklyTelemetry[i].weeklyTelemetryDocID;
                        weeklyTimerArray.push(timerSeries);

                    }

                    if ((i + 1) === data.weeklyTelemetry.length) {

                        setWeeklyMetricsDataYSeries(weeklyMileageArray);
                        setWeeklyMetricsDataXSeries(weeklyTimerArray);

                        setIsWeeklyLoading(false);
                        setIsWeeklyContentLoaded(true);

                    }

                }

                for (let i = 0; i < data.monthlyTelemetry.length; i++) {

                    const monthlyMileage = data.monthlyTelemetry[i].monthlyMileage;
                    let timerSeries = "";
                    if (monthlyMileage) {

                        if (monthlyMileage < 0)
                            monthlyMileageArray.push(0);
                        else
                            monthlyMileageArray.push((monthlyMileage).toFixed(2));

                        if (!data.monthlyTelemetry[i]?.overSpeedingCount || data.monthlyTelemetry[i].overSpeedingCount < 0)
                            monthlyOverSpeedingArray.push(0);

                        monthlyOverSpeedingArray.push(data?.monthlyTelemetry[i]?.overSpeedingCount ? ((Number(data?.monthlyTelemetry[i]?.overSpeedingCount) / 100).toFixed(0)) : 0);
                        monthlyHarshBrakingArray.push(data?.monthlyTelemetry[i]?.harshBrakingCount ? ((Number(data?.monthlyTelemetry[i]?.harshBrakingCount) / 100).toFixed(0)) : 0);
                        monthlyMileageBeyondEveningCounterArray.push(Number(data?.monthlyTelemetry[i]?.mileageBeyondEveningCount).toFixed(0));

                        if (data?.monthlyTelemetry[i]?.weeklyTelemetryDocID)
                            timerSeries = data?.monthlyTelemetry[i]?.weeklyTelemetryDocID;
                        else
                            timerSeries = data?.monthlyTelemetry[i]?.monthlyTelemetryDocID;

                        monthlyTimerArray.push(timerSeries);

                    }

                    if ((i + 1) === data.monthlyTelemetry.length) {

                        setMonthlyMetricsDataYSeries(monthlyMileageArray);
                        setMonthlyMetricsDataXSeries(monthlyTimerArray);

                        setMonthlyOverSpeedingDataYSeries(monthlyOverSpeedingArray);
                        setMonthlyHarshBrakingDataYSeries(monthlyHarshBrakingArray);
                        setMonthlyLateNightMileageDataYSeries(monthlyMileageBeyondEveningCounterArray);
                        setMonthlyOverSpeedingDataXSeries(monthlyTimerArray);

                        setIsMonthlyLoading(false);
                        setIsMonthlyContentLoaded(true);

                    }

                }

                return;

            } catch (error) {

                TableMessage.current = error?.response?.data?.message ?? "could not find a assigned to this driver";

                setSeverity("error");
                triggerSnackBar();

                setIsMonthlyLoading(false);
                setIsWeeklyLoading(false);
                setIsDailyLoading(false);

                setIsDailyContentLoaded(false);
                setIsWeeklyContentLoaded(false);
                setIsMonthlyContentLoaded(false);

                return;

            }

        };

        isMounted && fetchData();

        return () => isMounted = false;

    }, [
        vehicleID,
    ]);

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <>
                <div className="driver-inner-profile-container">
                    <div className="row">
                        <div className="col-xxl-12">
                            <div className="card-stretch gutter-b">
                                <div>
                                    <div className="col-xl-12">
                                        <div className="card card-custom card-stretch gutter-b">
                                            <div className="card-body d-flex p-0">
                                                <div
                                                    className="flex-grow-1 p-12 card-rounded bgi-no-repeat justify-content-center align-items-start bg-light-success"
                                                    style={{
                                                        backgroundPosition: "right bottom",
                                                        backgroundSize: "auto 100%",
                                                        backgroundImage: `url(${toAbsoluteUrl("/media/products/tft100.webp")})`
                                                    }}
                                                >
                                                    <div className="row mb-5">
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    Overspeeding Ratio
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    50%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    Harsh Braking Ratio
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    30%
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    Late Driving Ratio
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    20%
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row mb-5">
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    Safety Score (Today)
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    here
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    VIN
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    here
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4">
                                                            <div
                                                                className="p-2 rounded-lg"
                                                                style={{
                                                                    backgroundColor: "#ffffffbf",
                                                                }}
                                                            >
                                                                <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                    Tracker IMEI
                                                                </span>
                                                                <br></br>
                                                                <span className="text-dark-100 font-weight-bolder">
                                                                    here
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div className="col-xl-12">
                                            <div className="card card-custom card-stretch gutter-b">
                                                <div className="card-body d-flex p-0">
                                                    <div className="flex-grow-1 p-12 card-rounded bgi-no-repeat justify-content-center align-items-start bg-light-info"
                                                        style={{
                                                            backgroundPosition: "right bottom",
                                                            backgroundSize: "auto 100%",
                                                            backgroundImage: `url(${toAbsoluteUrl("/media/products/dvla.png")})`
                                                        }}
                                                    >
                                                        <div className="row mb-1">
                                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                                <span className="text-dark-80 font-weight-bolder font-size-lg">
                                                                    Health Checklist(Medial Fitness Score)
                                                                </span>
                                                                <BorderLinearProgress variant="determinate" value={30} />
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div
                                                                    className="p-2 rounded-lg"
                                                                    style={{
                                                                        backgroundColor: "#ffffffbf",
                                                                    }}
                                                                >
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        Eye Test
                                                                        <sup className="text-info font-weight-bolder">
                                                                            30%
                                                                        </sup>
                                                                    </span>
                                                                    <br></br>
                                                                    <div className="text-dark-100 font-weight-bolder">
                                                                        <Chip
                                                                            size="small"
                                                                            color="success"
                                                                            variant="outlined"
                                                                            label="Done"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div
                                                                    className="p-2 rounded-lg"
                                                                    style={{
                                                                        backgroundColor: "#ffffffbf",
                                                                    }}
                                                                >
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        Absence seizures
                                                                        <sup className="text-info font-weight-bolder">
                                                                            30%
                                                                        </sup>
                                                                    </span>
                                                                    <br></br>
                                                                    <div className="text-dark-100 font-weight-bolder">
                                                                        <Chip
                                                                            size="small"
                                                                            color="error"
                                                                            variant="outlined"
                                                                            label="Not Done"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div
                                                                    className="p-2 rounded-lg"
                                                                    style={{
                                                                        backgroundColor: "#ffffffbf",
                                                                    }}
                                                                >
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        Convulsions
                                                                        <sup className="text-info font-weight-bolder">
                                                                            40%
                                                                        </sup>
                                                                    </span>
                                                                    <br></br>
                                                                    <div className="text-dark-100 font-weight-bolder">
                                                                        <Chip
                                                                            size="small"
                                                                            color="error"
                                                                            variant="outlined"
                                                                            label="Not Done"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                                <div
                                                                    className="p-2 rounded-lg"
                                                                    style={{
                                                                        backgroundColor: "#ffffffbf",
                                                                    }}
                                                                >
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        Health Check Score
                                                                    </span>
                                                                    <br></br>
                                                                    <span className="text-dark-100 font-weight-bolder">
                                                                        <span className="text-danger">
                                                                            30%
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <div className="col-xl-12">
                                            <div className="card card-custom card-stretch gutter-b">
                                                <div className="card-body d-flex p-0">
                                                    <div className="flex-grow-1 p-12 card-rounded bgi-no-repeat justify-content-center align-items-start bg-light-primary"
                                                    /* style={{
                                                        backgroundPosition: "right bottom",
                                                        backgroundSize: "auto 100%",
                                                        backgroundImage: `url(${toAbsoluteUrl("/media/products/dvla.png")})`
                                                    }} */
                                                    >
                                                        <div className="row mb-1">
                                                            <div className="col-xs-12 col-sm-12 col-md-12">
                                                                <span className="text-dark-80 font-weight-bolder font-size-lg">
                                                                    Overall Score
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="col-xs-6 col-sm-6 col-md-6">
                                                                <div
                                                                    className="p-2 rounded-lg bg-warning"
                                                                >
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        59.5%
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row mb-5">
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div className="p-2 rounded-lg bg-danger">
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        0 - 49%
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div className="p-2 rounded-lg bg-warning">
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        50 - 75%
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4">
                                                                <div className="p-2 rounded-lg bg-success">
                                                                    <span className="text-dark-80 font-weight-bold font-size-lg">
                                                                        76%+
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-header border-0 pt-5">
                                    <div className="card-toolbar">
                                        <Tab.Container defaultActiveKey={key}>
                                            <Nav
                                                as="ul"
                                                onSelect={(_key) => setKey(_key)}
                                                className="nav nav-pills nav-pills-sm nav-dark-75"
                                            >
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Daily"
                                                        className={`nav-link py-2 px-4 ${key === "Daily" ? "active" : ""}`}
                                                    >
                                                        Daily
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Weekly"
                                                        className={`nav-link py-2 px-4 ${key === "Weekly" ? "active" : ""}`}
                                                    >
                                                        Weekly
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item className="nav-item" as="li">
                                                    <Nav.Link
                                                        eventKey="Monthly"
                                                        className={`nav-link py-2 px-4 ${key === "Monthly" ? "active" : ""}`}
                                                    >
                                                        Monthly
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </Tab.Container>
                                    </div>
                                </div>
                                <div className="card-body pt-2 pb-0 mt-n3">
                                    <div className={`tab-content mt-5`} id="myTabTables12">
                                        { }
                                        <div className={`tab-pane fade ${key === "Daily" ? "show active" : ""}`}>
                                            {key === "Daily" && <>
                                                {!isDailyContentLoaded && isDailyLoading && <BasicLoader size={"sm"} />}
                                                {isDailyContentLoaded &&
                                                    <div>
                                                        <div className='centerMan mb-5'>
                                                            <h4>
                                                                Driver Metrics for
                                                                {" "}
                                                                VIN
                                                            </h4>
                                                        </div>
                                                        <div className='mb-3'>
                                                            {dailyMetricsDataXSeries && dailyMetricsDataXSeries.length > 0 && <BarChartAlt
                                                                categories={dailyMetricsDataXSeries}
                                                                data={dailyMetricsDataYSeries}
                                                                exportFilename={`Driver-Metrics-${'VIN'}`}
                                                                xAxisName={"Day"}
                                                                yAxisName={"Mileage in km"}
                                                            />}
                                                        </div>
                                                    </div>
                                                }
                                            </>}
                                        </div>
                                        { }

                                        {/* begin::Tap pane Weekly */}
                                        <div className={`tab-pane fade ${key === "Weekly" ? "show active" : ""}`}>
                                            {key === "Weekly" && <>
                                                {!isWeeklyContentLoaded && isWeeklyLoading && <BasicLoader size={"sm"} />}
                                                {isWeeklyContentLoaded &&
                                                    <div>
                                                        <div className='centerMan mb-5'>
                                                            <h4>
                                                                Driver Metrics for
                                                                {" "}
                                                                {'VIN'}
                                                            </h4>
                                                        </div>
                                                        <div className='mb-3'>
                                                            {weeklyMetricsDataXSeries && weeklyMetricsDataXSeries.length > 0 && <BarChartAlt
                                                                categories={weeklyMetricsDataXSeries}
                                                                data={weeklyMetricsDataYSeries}
                                                                exportFilename={`Driver-Metrics-${'VIN'}`}
                                                                xAxisName={"Week"}
                                                                yAxisName={"Mileage in km"}
                                                            />}
                                                        </div>
                                                    </div>
                                                }
                                            </>}
                                        </div>
                                        { }

                                        {/* begin::Tap pane Monthly */}
                                        <div className={`tab-pane fade ${key === "Monthly" ? "show active" : ""}`}>
                                            {key === "Monthly" && <>
                                                {!isMonthlyContentLoaded && isMonthlyLoading && <BasicLoader size={"sm"} />}
                                                {isMonthlyContentLoaded &&
                                                    <div>
                                                        <div className='centerMan mb-5'>
                                                            <h4>
                                                                Driver Metrics for
                                                                {" "}
                                                                {'VIN'}
                                                            </h4>
                                                        </div>
                                                        <div className='mb-3'>
                                                            {monthlyMetricsDataXSeries && monthlyMetricsDataXSeries.length > 0 && <BarChartAlt
                                                                categories={monthlyMetricsDataXSeries}
                                                                data={monthlyMetricsDataYSeries}
                                                                exportFilename={`Driver-Metrics-${'VIN'}`}
                                                                xAxisName={"Month"}
                                                                yAxisName={"Mileage in km"}
                                                            />}
                                                        </div>
                                                    </div>
                                                }
                                            </>}
                                        </div>
                                        {/* end::Tap pane Monthly */}
                                    </div>
                                    <div className="mt-5">
                                        {!isMonthlyContentLoaded && isMonthlyLoading && <BasicLoader size={"sm"} />}
                                        {isMonthlyContentLoaded &&
                                            (<>

                                                <div>
                                                    <div className='centerMan mb-4'>
                                                        <h4>
                                                            Over-speeding Events
                                                        </h4>
                                                        <small>
                                                            # of times vehicle speed exceeded set speed configured on motor governor
                                                        </small>
                                                    </div>
                                                    <div className='mb-3'>
                                                        {monthlyOverSpeedingDataXSeries && monthlyOverSpeedingDataXSeries.length > 0 && <BarChartAlt
                                                            categories={monthlyOverSpeedingDataXSeries}
                                                            data={monthlyOverSpeedingDataYSeries}
                                                            exportFilename={`Driver-Metrics-${'VIN'}`}
                                                            xAxisName={"Month"}
                                                            yAxisName={"Overspeeding Events"}
                                                        />}
                                                    </div>
                                                </div>
                                                <div className="mt-10">
                                                    <div className='centerMan mb-4'>
                                                        <h4>
                                                            Harsh Braking Events
                                                        </h4>
                                                        <small>
                                                            # of times a driver's braking exceeds 0.5098 g-forces
                                                        </small>
                                                    </div>
                                                    <div className='mb-3'>
                                                        {monthlyOverSpeedingDataXSeries && monthlyOverSpeedingDataXSeries.length > 0 && <BarChartAlt
                                                            categories={monthlyOverSpeedingDataXSeries}
                                                            data={monthlyHarshBrakingDataYSeries}
                                                            exportFilename={`Driver-Metrics-${'VIN'}`}
                                                            xAxisName={"Month"}
                                                            yAxisName={"Harsh Braking Events"}
                                                        />}
                                                    </div>
                                                </div>
                                                <div className="mt-10">
                                                    <div className='centerMan mb-4'>
                                                        <h4>
                                                            Late Driving Events
                                                        </h4>
                                                        <small>
                                                            # of times nights a driver drives beyond 10pm
                                                        </small>
                                                    </div>
                                                    <div className='mb-3'>
                                                        {monthlyOverSpeedingDataXSeries && monthlyOverSpeedingDataXSeries.length > 0 && <BarChartAlt
                                                            categories={monthlyOverSpeedingDataXSeries}
                                                            data={monthlyLateNightMileageDataYSeries}
                                                            exportFilename={`Driver-Metrics-${'VIN'}`}
                                                            xAxisName={"Month"}
                                                            yAxisName={"Late Driving Events"}
                                                        />}
                                                    </div>
                                                </div>

                                            </>)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        </>
    );
};
