import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import * as Yup from "yup";
import { AUTH_BASE_URL, getStorageItem } from "../../../../app/services/baseService";
import { FormAlert } from "../../controls";
import Axios from "axios";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useSelector } from "react-redux";
import { addFaultReport } from "../../../../app/modules/Auth/_redux/authCrud";


export function CreateEditFaultReport({ onChanger, editState, fieldDisabled }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [faultImagesFiles, setFaultImagesFiles] = useState(null);

    const { user } = useSelector((state) => state.auth);

    const reporterID = user.user_data.document.ID;

    const initialValues = {
        driverID: '',
        reporterID: '',
        images: {},
        faultText: ''
    };

    const creationSchema = Yup.object().shape({
        faultText: Yup.string().required(),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    // useEffect(() => {

    //     let isMounted = true;

    //     const fetchData = async () => {

    //         const url = new URL('/users/drivers/getAll', `${AUTH_BASE_URL}`);

    //         if (!driverData.length) {
    //             setDriverDataLoading(true);
    //         }

    //         try {
    //             const response = await Axios.get(url.href);
    //             const data = response.data;
    //             isMounted && setDriverData(data.data);

    //         } catch (error) {
    //             // find something to do about this error
    //             console.log(error);
    //         } finally {
    //             isMounted && setDriverDataLoading(false);
    //         }

    //     };
    //     fetchData();
    //     return () => {
    //         isMounted = false;
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);

    const handleFaultImages = (event) => {
        let files = event.target.files;
        if (files)
            setFaultImagesFiles(files);
    };

    // const driverID = getStorageItem('selectedDriverRows');
    // console.log(driverID[0]?.original?.userID);

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {

            const driverData = getStorageItem('selectedDriverRows');
            const driverID = driverData[0]?.original?.userID;

            if (faultImagesFiles)
                values.images = faultImagesFiles;

            if (reporterID)
                values.reporterID = reporterID;

            if (driverID)
                values.driverID = driverID;

            // console.log(values)

            enableLoading();

            setTimeout(() => {


                // console.log(values);

                addFaultReport(values).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        // console.log(response)

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        onChanger();
                        return setStatus(response.data.message);
                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });

                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );
                }).finally(() => {
                    disableLoading();
                    setSubmitting(false);

                    return;
                });

                return;

            }, 3000);

        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                {/* <FormGroup controlId="driverID">
                    <Form.Label>
                        Choose Driver
                    </Form.Label>
                    {driverDataLoading ?
                        <span className="ml-5 spinner-border spinner-white"></span> :
                        <Typeahead
                            id="driverID"
                            labelKey={(option) => `${option.document.firstName} ${option.document.lastName}`}
                            options={driverData}
                            selected={formik.values.driverID ? [driverData.find(driver => driver.userID === formik.values.driverID)] : []}
                            onChange={(selected) => formik.setFieldValue('driverID', selected.length > 0 ? selected[0].userID : null)}
                            className={`${getInputClasses("driverID")}`}
                            name="driverID"
                            placeholder="Select a driver..."
                        />
                    }

                    <Form.Text className="text-muted" >
                        Select the driver to which the fault is for
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Driver cannot be black
                    </Form.Control.Feedback>
                    {formik.touched.driverID && formik.errors.driverID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.driverID}
                            </div>
                        </div>
                    ) : null}
                </FormGroup> */}

                <FormGroup controlId="faultText">
                    <Form.Label>
                        Fault Description
                    </Form.Label>
                    <Form.Control type="text" placeholder="Broken Lower Frame" value={formik.values.faultText} onChange={formik.handleChange} className={`${getInputClasses("faultText")}`} name="faultText" {...formik.getFieldProps("faultText")} />
                    <Form.Text className="text-muted" >
                        Short description of fault
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Fault Description cannot be black
                    </Form.Control.Feedback>
                    {formik.touched.faultText && formik.errors.faultText ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.faultText}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <div className="form-group row">
                    <div className="col-lg-12 col-xl-12">
                        <label
                            htmlFor="faultImages"
                            className="form-label"
                        >
                            Add Images of Reported Fault
                        </label>
                        <input
                            className="form-control"
                            id="faultImages"
                            type="file"
                            multiple
                            accept=".png, .jpg, .jpeg"
                            name="pic"
                            onChange={e => handleFaultImages(e)}
                        />
                        <span className="form-text text-muted">Add images of the reported fault</span>
                    </div>
                </div>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    Create Fault Report
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form >
        </>
    );
}