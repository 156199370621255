import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { createTrackerRecord, createVendorAccount, updateVendorDetails } from "../../../../app/modules/Auth/_redux/authCrud";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { FormAlert } from '../../controls';

export function CreateEditVendorAccountForm({ initialValues, editState, createEditText, fieldDisabled, onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [requirementInputValues, setRequirementInputValues] = useState({});
    const [inputCounter, setInputCounter] = useState(0);
    const [mapValue, setMapValue] = useState(null);
    const [coordinate, setCoordinates] = useState();

    const handleInputIncrement = () => {
        setInputCounter(inputCounter + 1);
        console.log(inputCounter);
    };

    const handleInputDecrement = (index) => {

        console.log(requirementInputValues[index]);
        delete requirementInputValues[index];
        console.log(requirementInputValues);
    };

    const handleOnChange = (e) => {
        const abc = {};
        abc[e.target.className] = e.target.value;
        setRequirementInputValues({ ...requirementInputValues, ...abc });
        console.log(coordinate);
    };

    let lat = 0;
    let lng = 0;

    const handleSelect = async (value) => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        if (latLng)
            setCoordinates(latLng);
        lat = latLng.lat;
        lng = latLng.lng;

        console.log(lat, lng);

    };

    const creationSchema = Yup.object().shape({
        contactFirstName: Yup.string().required(),
        contactLastName: Yup.string().required(),
        businessName: Yup.string().required(),
        country: Yup.string().required(),
        phoneNumber: Yup.string().required(),
        email: Yup.string(),
        businessRegistrationNumber: Yup.string().required(),
        locationLat: Yup.number(),
        locationLng: Yup.number(),
        locationName: Yup.string(),
        requestID: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    handleSelect(mapValue.label).then(() => {

                        setTimeout(() => {

                            values.locationLat = lat;
                            values.locationLng = lng;
                            values.locationName = mapValue.label;

                            values.tags = [
                                "delivery",
                                "food"
                            ];

                            console.log(values);

                            createVendorAccount(values).then((response) => {

                                disableLoading();
                                setSubmitting(false);

                                if (response) {

                                    setFormAlertEnabled(true);

                                    setAlertType({
                                        type: 'success',
                                        icon: 'fa fa-check-circle mana-icon-white'
                                    });
                                    onChanger();
                                    return setStatus(response.data.message);

                                }

                            }).catch((error) => {

                                setFormAlertEnabled(true);

                                setAlertType({
                                    type: 'danger',
                                    icon: 'flaticon-warning mana-icon-white'
                                });
                                onChanger();
                                return setStatus(
                                    error.response.data?.error?.code
                                    ??
                                    error.response.data.message
                                    ??
                                    'an error occurred, please try again later'
                                );

                            }).finally(() => {
                                disableLoading();
                                setSubmitting(false);
                                return onChanger();
                            });


                        }, 1000);

                    });

                    return;

                }

                if (editState === true) {

                    const id = getStorageItem("requestIDForEdit");
                    // const editPayload = {

                    // }

                    updateVendorDetails(id, values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="requestContactFirstNameModel">
                    <Form.Label>Contact First Name</Form.Label>
                    <Form.Control type="text" placeholder="John" value={formik.values.contactFirstName} onChange={formik.handleChange} className={`${getInputClasses("contactFirstName")}`} name="contactFirstName" {...formik.getFieldProps("contactFirstName")} />
                    <Form.Text className="text-muted">
                        Enter Preferred Vendor Contact First Name
                    </Form.Text>
                    <Form.Control.Feedback>Contact first name looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Contact First Name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.contactFirstName && formik.errors.contactFirstName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.contactFirstName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="requestContactLastNameModel">
                    <Form.Label>Contact Last Name</Form.Label>
                    <Form.Control type="text" placeholder="Doe" value={formik.values.contactLastName} onChange={formik.handleChange} className={`${getInputClasses("contactLastName")}`} name="contactLastName" {...formik.getFieldProps("contactLastName")} />
                    <Form.Text className="text-muted">
                        Enter Preferred Vendor Contact Last Name
                    </Form.Text>
                    <Form.Control.Feedback>Provided Tracker Model looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Contact Last Name field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.contactLastName && formik.errors.contactLastName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.contactLastName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="requestBusinessName">
                    <Form.Label>Business Name</Form.Label>
                    <Form.Control type="text" placeholder="Business Name" value={formik.values.businessName} onChange={formik.handleChange} className={`${getInputClasses("businessName")}`} name="businessName" {...formik.getFieldProps("businessName")} />
                    <Form.Text className="text-muted">
                        Enter Preferred Vendor Contact Last Name
                    </Form.Text>
                    <Form.Control.Feedback>Provided Tracker Model looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Contact Last Name field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.businessName && formik.errors.businessName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.businessName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="requestEmail">
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email Address" value={formik.values.email} onChange={formik.handleChange} className={`${getInputClasses("email")}`} name="email" {...formik.getFieldProps("email")} disabled={fieldDisabled} />
                    <Form.Text className="text-muted">
                        Provide Vendor email address
                    </Form.Text>
                    <Form.Control.Feedback>Provided email looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vendor email address cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.email && formik.errors.email ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.email}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="adminFormCountry">
                    <Form.Label>Country</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.country} onChange={formik.handleChange} className={`${getInputClasses("country")}`} name="country" {...formik.getFieldProps("country")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">Bosnia and Herzegovina</option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">British Indian Ocean Territory</option>
                        <option value="Brunei Darussalam">Brunei Darussalam</option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">Central African Republic</option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">Cocos (Keeling) Islands</option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Democratic Republic of the Congo">Democratic Republic of the Congo</option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote d'Ivoire">Cote d'Ivoire</option>
                        <option value="Croatia (Hrvatska)">Croatia (Hrvatska)</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">Dominican Republic</option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">Equatorial Guinea</option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">Falkland Islands (Malvinas)</option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">French Southern Territories</option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-Bissau">Guinea-Bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard and Mc Donald Islands">Heard and Mc Donald Islands</option>
                        <option value="Holy See (Vatican City State)">Holy See (Vatican City State)</option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran (Islamic Republic of)">Iran (Islamic Republic of)</option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">Korea, Democratic People's Republic of</option>
                        <option value="Korea, Republic of">Korea, Republic of</option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">Lao People's Democratic Republic</option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">Libyan Arab Jamahiriya</option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macau">Macau</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">Macedonia, The Former Yugoslav Republic of</option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">Micronesia, Federated States of</option>
                        <option value="Moldova, Republic of">Moldova, Republic of</option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="Netherlands Antilles">Netherlands Antilles</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">Northern Mariana Islands</option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">Russian Federation</option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Kitts and Nevis">Saint Kitts and Nevis</option>
                        <option value="Saint LUCIA">Saint LUCIA</option>
                        <option value="Saint Vincent and the Grenadines">Saint Vincent and the Grenadines</option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">Sao Tome and Principe</option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia (Slovak Republic)">Slovakia (Slovak Republic)</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and the South Sandwich Islands">South Georgia and the South Sandwich Islands</option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="St. Helena">St. Helena</option>
                        <option value="St. Pierre and Miquelon">St. Pierre and Miquelon</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen Islands">Svalbard and Jan Mayen Islands</option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">Syrian Arab Republic</option>
                        <option value="Taiwan, Province of China">Taiwan, Province of China</option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">Tanzania, United Republic of</option>
                        <option value="Thailand">Thailand</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">Trinidad and Tobago</option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">Turks and Caicos Islands</option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">United Arab Emirates</option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="United States Minor Outlying Islands">United States Minor Outlying Islands</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Virgin Islands (British)">Virgin Islands (British)</option>
                        <option value="Virgin Islands (U.S.)">Virgin Islands (U.S.)</option>
                        <option value="Wallis and Futuna Islands">Wallis and Futuna Islands</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Select country
                    </Form.Text>
                    <Form.Control.Feedback>Selection looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Country selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.country && formik.errors.country ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.country}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="driverFormPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone Number" value={formik.values.phoneNumber} onChange={formik.handleChange} className={`${getInputClasses("phoneNumber")}`} name="phoneNumber" {...formik.getFieldProps("phoneNumber")} disabled={fieldDisabled} />
                    <Form.Text className="text-muted">
                        Add country code when inputting phone number
                    </Form.Text>
                    <Form.Control.Feedback>Your phone number looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone number cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phoneNumber}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="requestFormLocation">
                    <Form.Label>Location</Form.Label>
                    <GooglePlacesAutocomplete
                        apiKey="AIzaSyDYPQI1VL7rgGsE1V8yzA9g2dp14O3mnOw"
                        selectProps={{
                            mapValue,
                            onChange: setMapValue, handleSelect,
                            onselect: handleSelect
                        }}
                    />
                </Form.Group>

                <Form.Group controlId="requestBusinessRegistrationNumber">
                    <Form.Label>Business Registration Number</Form.Label>
                    <Form.Control type="text" placeholder="Business Registration Number" value={formik.values.businessRegistrationNumber} onChange={formik.handleChange} className={`${getInputClasses("businessRegistrationNumber")}`} name="businessRegistrationNumber" {...formik.getFieldProps("businessRegistrationNumber")} />
                    <Form.Text className="text-muted">
                        Enter Business Registration Number
                    </Form.Text>
                    <Form.Control.Feedback>Provided Business Registration looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Business Registration Number field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.businessRegistrationNumber && formik.errors.businessRegistrationNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.businessRegistrationNumber}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="requestRequestID">
                    <Form.Label>Request ID</Form.Label>
                    <Form.Control type="text" placeholder="Request ID" value={formik.values.requestID} onChange={formik.handleChange} className={`${getInputClasses("requestID")}`} name="requestID" {...formik.getFieldProps("requestID")} />
                    <Form.Text className="text-muted">
                        Enter Request ID
                    </Form.Text>
                    <Form.Control.Feedback>Provided Request ID looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Enter Request ID field cannot be blank!
                    </Form.Control.Feedback>
                    {formik.touched.requestID && formik.errors.requestID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.requestID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>

        </>
    );
}