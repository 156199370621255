import React, { useEffect, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Button as MuiButton,
    Stepper,
    Step,
    StepLabel,
    Typography,
    Card,
    CardHeader,
    Avatar,
    Alert,
} from "@mui/material";
import axios from "axios";
import { useFormik } from "formik";
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { makeStyles } from '@mui/styles';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import { createVehicleRecord, editVehicleRecord } from '../../../../app/modules/Auth/_redux/authCrud';
import BasicLoader from '../../loaders/SimpleLoader';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls';
import { toAbsoluteUrl } from "../../../_helpers";

const useStyles = makeStyles(theme => ({
    root: {
        width: '90%',
    },
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    separator: {
        borderColor: theme.palette.success,
    },
    card: {
        minWidth: 275,
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    }
}));

function getSteps() {
    return [
        'Make and Model',
        'Assembly',
        'QC',
        'Insurance',
        'Ready'
    ];
};

export function VehicleCreationWidget({ editState, createEditText, fieldDisabled, onChanger, vehicleID }) {

    const classes = useStyles();

    const [activeStep, setActiveStep] = useState(0);
    const [reRender, setReRender] = useState(0);

    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [specVehicleModelData, setVehicleModelData] = useState([]);
    const [specBatteryTypeData, setBatteryTypeData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showLoader, setShowLoader] = useState('d-none');
    const [profileImageURL, setProfileImage] = useState("");
    const [profileImageFile, setProfileImageFile] = useState("");

    const TableErrorMessage = useRef();

    const creationSchema = Yup.object().shape({
        VIN: Yup.string().required(),
        vehicleModelID: Yup.string().required(),
    });

    const initialValues = {
        VIN: "",
        vehicleModelID: "",

    };

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const handleLoadingAndErrors = () => {
        if (isLoading)
            return true;
        if (isRefetching)
            return true;

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (profileImageFile)
                    values.profileImage = profileImageFile;

                if (!editState) {

                    createVehicleRecord(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        id: vehicleID,
                        vehicleModelID: values.vehicleModelID,
                        VIN: values.VIN,
                        batteryID: values.batteryID
                    };

                    if (profileImageFile)
                        editPayload.profileImage = profileImageFile;

                    editVehicleRecord(editPayload).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);
        },
    });

    const getUserPic = () => {
        if (!profileImageURL) {
            return "none";
        }

        return `url(${profileImageURL})`;
    };

    const handleNewProfileImage = (event) => {

        let file = event.target.files[0];
        if (file) {
            setProfileImageFile(file);
            let reader = new FileReader();
            let url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                setProfileImage(reader.result);
            };
            console.log(url);
        }

    };

    const removePic = () => {
        setProfileImage("");
    };

    const makeOption = (vehicle) => {
        return <option key={vehicle.document.ID} value={vehicle.document.ID}>
            {vehicle.document.vehicleType.name}
            {" - "}
            {vehicle.document.motor.name}
            {" - "}
            {vehicle.document.battery.name}
            {" - "}
            {vehicle.document.manufacturer.name}
        </option>;
    };

    const makeBatteryOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
            {" - "}
            {X.document.capacity}
            mAh
            {" - "}
            {X.document.operatingVoltage}
            V
            {" - "}
            {X.document.peakVoltage}
            V
        </option>;
    };

    useEffect(() => {

        let isMounted = true;

        const fetchVehicleModelData = async () => {

            setShowLoader('');

            if (!specVehicleModelData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/vehicleModels/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setVehicleModelData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        const fetchBatteryTypeData = async () => {

            setShowLoader('');

            if (!specBatteryTypeData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/batteries/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setBatteryTypeData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        fetchVehicleModelData();
        fetchBatteryTypeData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const steps = getSteps();

    function handleNext() {

        setActiveStep(prevActiveStep => prevActiveStep + 1);
        if (activeStep === (steps.length - 1)) {

            setReRender(prevState => prevState + 1);
            onChanger();

        }

    };

    function handleBack() {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    function handleReset() {
        setActiveStep(0);
    };

    function getStepContent(stepIndex) {
        switch (stepIndex) {
            case 0:
                {
                    return (<>
                        <Form onSubmit={formik.handleSubmit}>

                            <Form.Group controlId="vehicleFormVIN">
                                <Form.Label>VIN</Form.Label>
                                <Form.Control type="text" placeholder="Vehicle Identification Number / Serial Number" value={formik.values.VIN} onChange={formik.handleChange} className={`${getInputClasses("VIN")}`} name="VIN" {...formik.getFieldProps("VIN")} />
                                <Form.Text className="text-muted">
                                    Vehicle Identification Number / Serial Number
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Vehicle Identification Number / Serial Number cannot be blank
                                </Form.Control.Feedback>
                                {formik.touched.VIN && formik.errors.VIN ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.VIN}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            <Form.Group controlId="vehicleFormModelID">
                                <Form.Label>Vehicle Model</Form.Label>
                                <Form.Control as="select" data-live-search="true" value={formik.values.vehicleModelID} onChange={formik.handleChange} className={`${getInputClasses("vehicleModelID")}`} name="vehicleModelID" {...formik.getFieldProps("vehicleModelID")}>
                                    <option disabled defaultValue="" value="">Select one</option>
                                    {specVehicleModelData.map(makeOption)}
                                </Form.Control>
                                {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                                <Form.Text className="text-muted">
                                    Vehicle Type - Motor Type - Battery Type - Manufacturer
                                </Form.Text>
                                <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                                <Form.Control.Feedback type="invalid">
                                    Vehicle model selection cannot be blank
                                </Form.Control.Feedback>
                                {formik.touched.vehicleModelID && formik.errors.vehicleModelID ? (
                                    <div className="fv-plugins-message-container">
                                        <div className="fv-help-block">
                                            {formik.errors.vehicleModelID}
                                        </div>
                                    </div>
                                ) : null}
                            </Form.Group>

                            {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                            <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                                Save and Continue
                                {loading && <span className="ml-5 spinner spinner-white"></span>}
                            </Button>

                        </Form>
                    </>);
                }

            case 1:
                {
                    return (
                        <>
                            <h1>
                                checkbox assembly completion
                            </h1>
                        </>
                    );
                }
            case 2:
                {
                    return (
                        <>
                            <h1>
                                checkbox QC completion
                            </h1>
                        </>
                    );
                }
            case 3:
                {
                    return (
                        <>
                            <h1>
                                checkbox Insurance completion and mark bikes as ready
                            </h1>
                        </>
                    );
                }
            case 5:
                {
                    //setReRender(prevState => prevState + 1);
                    //onChanger();
                    return (
                        <>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="success"
                                            className={classes.avatar}
                                        >
                                            <CheckCircleTwoToneIcon
                                                color="success"
                                                fontSize="large"
                                            />
                                        </Avatar>
                                    }
                                    /* action={
                                        <IconButton
                                            aria-label="Settings"
                                            onClick={() => {
    
                                                const indexToRemove = selectedDriversData.indexOf(driverData);
                                                if (indexToRemove > -1) {
    
                                                    selectedDriversData.splice(indexToRemove, 1);
                                                    setStorageItem("selectedDriverRows", selectedDriversData);
                                                    setReRender(prevState => prevState + 1);
    
                                                }
    
                                            }}
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    } */
                                    title="Bulk emails sent successfully"
                                    subheader=""
                                />
                            </Card>
                        </>
                    );
                }
            default:
                return 'Unknown stepIndex';
        }
    };

    return (<>

        <div className={`card card-custom rounded-xl`}>
            <div className="card-body">
                <div className="p-0">
                    <div className="form-group row">
                        <div className="col-lg-12 col-xl-12">
                            <div className="centerMan">
                                <div
                                    className="image-input image-input-outline"
                                    id="kt_profile_avatar"
                                    style={{
                                        backgroundImage: `url(${toAbsoluteUrl(
                                            "/media/users/blank.png"
                                        )}`,
                                    }}
                                >
                                    <div
                                        className="image-input-wrapper"
                                        style={{ backgroundImage: `${getUserPic()}` }}
                                    />
                                    <label
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="change"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Change avatar"
                                    >
                                        <i className="fa fa-pen icon-sm text-muted"></i>
                                        <input
                                            type="file"
                                            name="pic"
                                            accept=".png, .jpg, .jpeg"
                                            onChange={e => handleNewProfileImage(e)}
                                        />
                                        <input type="hidden" name="profile_avatar_remove" />
                                    </label>
                                    <span
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="cancel"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Cancel avatar"
                                    >
                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                    <span
                                        onClick={removePic}
                                        className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                        data-action="remove"
                                        data-toggle="tooltip"
                                        title=""
                                        data-original-title="Remove avatar"
                                    >
                                        <i className="ki ki-bold-close icon-xs text-muted"></i>
                                    </span>
                                </div>
                                <span className="form-text text-muted">
                                    Allowed file types: png, jpg, jpeg.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="centerMan">
                        <Typography
                            variant="h5"
                            component="h2"
                        >
                            Vehicle Creation Checklist
                        </Typography>
                    </div>
                    <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                    >
                        {steps.map((label, index) => {
                            return (
                                <Step key={label}>
                                    <StepLabel>
                                        {label}
                                    </StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    {activeStep === steps.length ? (
                        <>
                            <Card className={classes.card}>
                                <CardHeader
                                    avatar={
                                        <Avatar
                                            aria-label="success"
                                            className={classes.avatar}
                                        >
                                            <CheckCircleTwoToneIcon
                                                color="success"
                                            />
                                        </Avatar>
                                    }
                                    title="Vehicle Creation Checklist Completed"
                                    subheader="Some sub-header here..."
                                />
                            </Card>
                            <div className="p-1">
                                <hr className={classes.separator} />
                            </div>
                            <MuiButton
                                onClick={handleReset}
                                color='info'
                                size='md'
                                variant='contained'
                            >
                                Reset
                            </MuiButton>
                        </>
                    ) : (
                        <>
                            <div>
                                {getStepContent(activeStep)}
                            </div>
                            <div className="p-1">
                                <hr className={classes.separator} />
                            </div>
                            <div className="mt-10">
                                {<>
                                    <MuiButton
                                        disabled={activeStep === 0}
                                        onClick={handleBack}
                                        className={classes.backButton}
                                    >
                                        Back
                                    </MuiButton>
                                    {activeStep === steps.length - 1 ?
                                        ''
                                        :
                                        <MuiButton
                                            /* variant="contained"
                                            color="primary" */
                                            onClick={handleNext}
                                        >
                                            Next
                                        </MuiButton>
                                    }
                                </>}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>

    </>);

}