import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper,
    styled,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { toAbsoluteUrl } from "../../../_helpers";
import { SimpleModal } from '../modals/SimpleModal';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { ExportToCsv } from 'export-to-csv';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import PublishedWithChangesTwoToneIcon from '@mui/icons-material/PublishedWithChangesTwoTone';
import {
    FLEET_MANAGING_BASE_URL
} from '../../../../app/services/baseService';
import { CreateEditPartForm } from '../index';
import AddBoxTwoToneIcon from '@mui/icons-material/AddBoxTwoTone';
import { CreateEditRequisitionForm } from '../forms/CreateEditRequisitionForm';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    rounder: {
        border: `1px solid #b9975b`,
        height: `80px`,
        width: `80px`
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function RequisitionsTable({ customClassName }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 5,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [showRequisitionCreateEditFrom, setShowRequisitionCreateEditFrom] = useState(false);

    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Build');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [initialValues, setInitialValues] = useState({
        vehicleBatchNumber: 0,
        partName: "",
        partCount: 0,
        partMinimumCount: 0,
        purchaseLocation: "",
        partPrice: "",
        SKU: "",
    });
    const defaultValuesToSet = {
        VIN: "",
        vehicleModelID: "",
    };

    const [severity, setSeverity] = useState('info');

    const TableStatusMessage = useRef();
    const specPartID = useRef();
    const specPartImage = useRef();
    const classes = useStyles();

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/inventory/requisitions', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;
                console.log(data);

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];

                    let dataToExport = {
                        requisitionID: element.partID,
                        reason: element.reason,
                        partCount: element.partCount,
                        partImageURL: element.partImageURL,
                        requestingDepartment: element.requestingDepartment,
                        requestorID: element.requestorID,
                        createdAt: new Date(element.document.createdAt).toLocaleDateString()
                    };
                    dataArrayAlt.push(dataToExport);

                    if ((i + 1) >= dataArray.length)
                        isMounted && setDataDocument(dataArrayAlt);

                }

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableStatusMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);
                    isMounted && setIsError(true);

                    return;

                }

                TableStatusMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            }
            finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.partName,
            id: "partName",
            enableClickToCopy: true,
            header: 'Part Name'
        },
        {
            accessorFn: (row) => row?.document?.partID,
            id: "partID",
            enableClickToCopy: true,
            header: 'part ID'
        },
        {
            accessorFn: (row) => row?.document?.partCount,
            id: "partCount",
            enableClickToCopy: true,
            header: 'Part Count'
        },
        {
            accessorFn: (row) => row?.document?.partPrice,
            id: "partPrice",
            enableClickToCopy: true,
            header: 'Part Price'
        },
        {
            accessorFn: (row) => row?.document?.reason,
            id: "reason",
            enableClickToCopy: true,
            header: 'Reason'
        },
        {
            accessorFn: (row) => row?.document?.requestingDepartment,
            id: "requestingDepartment",
            enableClickToCopy: true,
            header: 'Requesting Department'
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toUTCString()}`,
            id: 'createdAt',
            header: 'Creation Date',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "requisitionID",
            enableClickToCopy: true,
            header: 'Requisition ID'
        },
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Inventory',
        showTitle: true
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    <span id="message-id">
                        {TableStatusMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Requisition`}
                show={showRequisitionCreateEditFrom}
                close={() => setShowRequisitionCreateEditFrom(false)}
                size="lg"
                body={<CreateEditRequisitionForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    partId={specPartID.current}
                    currentPartImage={specPartImage.current}
                />}
            />
            <div className={`card card-custom ${customClassName}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Requisitions
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            See and respond to requisitions here
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="large"
                                    color="primary"
                                    aria-label="Create"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShowRequisitionCreateEditFrom(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                    startIcon={<AddBoxTwoToneIcon />}
                                >
                                    Create Requisition
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10 overflow-auto">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: `${severity}`,
                                    children: `${TableStatusMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (

                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={6}>
                                    <Box sx={{ textAlign: 'center' }}>
                                        <img alt="avatar" height={80} width={80} src={row.original.document?.partImageURL ?? toAbsoluteUrl("/media/svg/icons/General/Attachment1.svg")} loading="lazy" style={{ borderRadius: '50%' }} />
                                    </Box>
                                </Grid>
                                <Grid item xs={6}>
                                    <Item>
                                        <Box sx={{ textAlign: 'left' }}>
                                            <Typography variant="label" className='bold'>Document ID: </Typography>
                                            <Typography variant="p">
                                                {row.original.document?.document_id}
                                            </Typography>
                                        </Box>
                                    </Item>
                                </Grid>
                            </Grid>

                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const partID = row.original.document.ID;
                                    const valuesToSet = {
                                        vehicleBatchNumber: row.original.document.vehicleBatch,
                                        partName: row.original.document.partName,
                                        partCount: row.original.document.partCount,
                                        partMinimumCount: row.original.document.partMinimumCount,
                                        purchaseLocation: row.original.document.purchaseLocation,
                                        partPrice: row.original.document.partPrice,
                                        SKU: row.original.document.partSKU,
                                    };

                                    specPartID.current = partID;
                                    specPartImage.current = row.original.document.partImageURL;

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShowRequisitionCreateEditFrom(true);

                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <EditTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Edit
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const requisitionID = row.original.document.ID;
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <DeleteTwoToneIcon
                                        color='error'
                                    />
                                </ListItemIcon>
                                Delete
                            </MenuItem>,
                            <MenuItem
                                key={2}
                                onClick={() => {

                                    const requisitionID = row.original.document.ID;
                                    closeMenu();
                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <PublishedWithChangesTwoToneIcon
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                Change Requestor
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="primary"
                                        //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                        onClick={handleExportData}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export All Data
                                    </Button>
                                    <Button
                                        disabled={table.getPrePaginationRowModel().rows.length === 0}
                                        //export all rows, including from the next page, (still respects filtering and sorting)
                                        onClick={() =>
                                            handleExportRows(table.getPrePaginationRowModel().rows)
                                        }
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export All Rows
                                    </Button>
                                    <Button
                                        disabled={table.getRowModel().rows.length === 0}
                                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                        onClick={() => handleExportRows(table.getRowModel().rows)}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export Page Rows
                                    </Button>
                                    <Button
                                        disabled={
                                            !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                        }
                                        //only export selected rows
                                        onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                        startIcon={<FileDownloadIcon />}
                                        variant="contained"
                                    >
                                        Export Selected Rows
                                    </Button>
                                </div>
                            );
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
