import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { sendCustomSMSForConsultationRequest } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls/';
import { useSelector } from "react-redux";

export function SendCustomSMSForConsultationRequest({ initialValues, createEditText, onChanger, bikeInterestID, bikeInterestData }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [selectedBikeInterestID, setSelectedBikeInterestID] = useState("");
    const [selectedBikeInterestData, setSelectedBikeInterestData] = useState();

    const { user } = useSelector((state) => state.auth);
    const creationSchema = Yup.object().shape({
        messageHeader: Yup.string().required("message header field cannot be left blank"),
        message: Yup.string().required("message field cannot be left blank"),
        consultationRequestLogID: Yup.string().required("request ID not attached to payload"),
        senderID: Yup.string().required("sender ID not attached to payload"),
        senderFirstName: Yup.string().required("sender first name not attached to payload"),
        senderLastName: Yup.string().required("sender last name not attached to payload"),
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                values.senderID = user.user_data.document.ID;
                values.senderFirstName = user.user_data.document.firstName;
                values.senderLastName = user.user_data.document.lastName;

                sendCustomSMSForConsultationRequest(values).then((response) => {

                    disableLoading();
                    setSubmitting(false);

                    if (response) {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'success',
                            icon: 'fa fa-check-circle mana-icon-white'
                        });
                        onChanger();
                        return setStatus(`SMS sent`);

                    }

                }).catch((error) => {

                    setFormAlertEnabled(true);

                    setAlertType({
                        type: 'danger',
                        icon: 'flaticon-warning mana-icon-white'
                    });
                    onChanger();
                    return setStatus(
                        error.response.data?.error?.code
                        ??
                        error.response.data.message
                        ??
                        'an error occurred, please try again later'
                    );

                }).finally(() => {

                    disableLoading();
                    setSubmitting(false);
                    return onChanger();

                });

            }, 1000);

        },
    });

    useEffect(() => {

        let isMounted = true;

        isMounted && setSelectedBikeInterestID(bikeInterestID);
        isMounted && setSelectedBikeInterestData(bikeInterestData);

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>

            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="SMSFormMessageHeader">
                    <Form.Label>Message Header</Form.Label>
                    <Form.Control type="text" placeholder="eg Wahu Sales" value={formik.values.messageHeader} onChange={formik.handleChange} className={`${getInputClasses("messageHeader")}`} name="messageHeader" {...formik.getFieldProps("messageHeader")} />
                    <Form.Text className="text-muted">
                        SMS Message Header
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        SMS Message Header cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.messageHeader && formik.errors.messageHeader ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.messageHeader}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="SMSFormMessage">
                    <Form.Label>Message</Form.Label>
                    <Form.Control type="textarea" placeholder="Email Message" value={formik.values.message} onChange={formik.handleChange} className={`${getInputClasses("message")}`} name="message" {...formik.getFieldProps("message")} />
                    <Form.Text className="text-muted">
                        SMS Message
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        SMS Message cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.message && formik.errors.message ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.message}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="SMSFormBikeLogID">
                    <Form.Label>Consultation Request Log ID</Form.Label>
                    <Form.Control type="text" readOnly placeholder="eg Wahu Sales" value={formik.values.consultationRequestLogID} onChange={formik.handleChange} className={`${getInputClasses("consultationRequestLogID")}`} name="consultationRequestLogID" {...formik.getFieldProps("consultationRequestLogID")} />
                    <Form.Text className="text-muted">
                        Consultation Request Log ID
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Consultation Request Log ID cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.consultationRequestLogID && formik.errors.consultationRequestLogID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.consultationRequestLogID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="consultationRequestSMSResponseFormSenderID">
                    <Form.Label>Sender ID</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderID} onChange={formik.handleChange} className={`${getInputClasses("senderID")}`} name="senderID" {...formik.getFieldProps("senderID")} />
                    <Form.Text className="text-muted">
                        Email Sender ID
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender ID cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderID && formik.errors.senderID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="consultationRequestSMSResponseFormSenderFirstName">
                    <Form.Label>Sender First Name</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderFirstName} onChange={formik.handleChange} className={`${getInputClasses("senderFirstName")}`} name="senderFirstName" {...formik.getFieldProps("senderFirstName")} />
                    <Form.Text className="text-muted">
                        Email Sender First Name
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender First Name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderFirstName && formik.errors.senderFirstName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderFirstName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="consultationRequestSMSResponseFormSenderLastName">
                    <Form.Label>Sender Last Name</Form.Label>
                    <Form.Control type="text" readOnly={true} value={formik.values.senderLastName} onChange={formik.handleChange} className={`${getInputClasses("senderLastName")}`} name="senderLastName" {...formik.getFieldProps("senderLastName")} />
                    <Form.Text className="text-muted">
                        Email Sender Last Name
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Email Sender Last Name cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.senderLastName && formik.errors.senderLastName ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.senderLastName}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}