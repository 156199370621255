import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    Grid,
    Paper,
    Alert,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import WorkHistoryTwoToneIcon from '@mui/icons-material/WorkHistoryTwoTone';
import ModeEditTwoToneIcon from '@mui/icons-material/ModeEditTwoTone';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import {
    MaterialReactTable
} from 'material-react-table';
import { convertUnixTimestampToUTC, formatDate } from "../../../_helpers";
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateJob, deactivateJob } from '../../../../app/modules/Auth/_redux/authCrud';
import { setStorageItem, SUPPORT_SERVICE_BASE_URL } from '../../../../app/services/baseService';
import { CreateEditJobsForm } from '../';
import { SimpleModal } from '../modals/SimpleModal';
import { JobDetailsList } from "../lists/JobDetailsList";
import { CareerDetailsPage } from "../../../../app/pages/CareerDetailsPage";
import { useHistory } from "react-router-dom";
import BasicLoader from '../../loaders/SimpleLoader';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    hide: {
        display: "none"
    }
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));

export function CareersTable({ className }) {

    const history = useHistory();

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);

    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);

    const [initialValues, setInitialValues] = useState({
        closeDate: 0,
        requirements: "",
        responsibilities: "",
        roleDescription: "",
        roleTitle: "",
        additionalInformation: "",
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Tracker');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [selectedJobData, setSelectedJobData] = useState({});
    const [selectedJobID, setSelectedJobID] = useState("");
    const [showTable, setShowTable] = useState(true);
    const [roleTitle, setRoleTitle] = useState("");

    const TableErrorMessage = useRef();

    const classes = useStyles();

    const defaultValuesToSet = {
        closeDate: 0,
        requirements: [],
        responsibilities: [],
        roleDescription: "",
        roleTitle: "",
        additionalInformation: ""
    };

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/jobs/getAll', `${SUPPORT_SERVICE_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

                if (selectedJobID)
                    for (let i = 0; i < data.data.length; i++) {

                        const job = data.data[i];
                        const jobID = job.applicationID;

                        if (selectedJobID === jobID)
                            setSelectedJobData(job.document);

                    }

                return data;

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error?.response?.status}, Message: ${error?.response?.data?.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => `${row?.document?.roleTitle}`,
            id: 'roleTitle',
            header: 'Role Title',
            size: 250,
            Cell: ({ cell, row }) => (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '1rem',
                    }}
                >
                    <Typography>
                        {cell.getValue()}
                    </Typography>
                </Box>
            )
        },
        {
            accessorFn: (row) => new Date(row?.document?.closeDate).toUTCString(),
            id: "closeDate",
            enableClickToCopy: true,
            header: 'Close Date'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Deactivated"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${row?.document?.applicationLink}`,
            id: "Application Link",
            enableClickToCopy: true,
            header: 'Application Link',
            Cell: ({ cell }) => (
                <Box
                    sx={{ width: '100%', maxWidth: 200 }}
                >
                    <Typography noWrap>
                        {cell.getValue()}
                    </Typography>
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'document.createdAt',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            filterVariant: 'date',
            sortingFn: 'datetime',
            Cell: ({ cell }) => cell.getValue()?.toLocaleDateString(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less than or equal to'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        }
    ],
        [],
    );

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={severity}
                    elevation={6}
                    variant="filled"
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Job Opening`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditJobsForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Applications`}
                show={showDetailsModal}
                close={() => setShowDetailsModal(false)}
                size="lg"
                body={<JobDetailsList
                    className="card-stretch gutter-b"
                    jobData={selectedJobData}
                    jobID={selectedJobID}
                />}
            />

            {showTable && <div className={`card card-custom ${className} rounded-xl`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {isLoading && <BasicLoader size={"sm"} />}
                            {!isLoading && rowCount.count}
                            {" "}
                            Roles Listed
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage all roles here
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Job");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Add Job Post
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{
                            showColumnFilters: true,
                        }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount.count}
                        state={{
                            isLoading: isLoading,
                            pagination: pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching,
                        }}
                        renderDetailPanel={({ row }) => (
                            <div>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Role Description: </Typography>
                                                <Typography variant="overline" display="block" gutterBottom>
                                                    {row.original.document?.roleDescription}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Requirements: </Typography>
                                                <Typography variant="overline" display="block" gutterBottom>
                                                    {row.original.document?.requirements.map((item, index) =>
                                                        <div key={index} className='mt-3'>
                                                            {+(index + 1)}
                                                            .
                                                            {" "}
                                                            {item}
                                                        </div>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Responsibilities: </Typography>
                                                <Typography variant="overline" display="block" gutterBottom>
                                                    {row.original.document?.responsibilities.map((item, index) =>
                                                        <div key={index} className='mt-3'>
                                                            {+(index + 1)}
                                                            .
                                                            {" "}
                                                            {item}
                                                        </div>
                                                    )}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>CreatedAt: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'left' }}>
                                                <Typography variant="label" className='bold'>Last Updated: </Typography>
                                                <Typography variant="p">
                                                    {convertUnixTimestampToUTC(row.original.document?.updatedAt ?? row.original.document?.createdAt)}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    let requirementsString = "";
                                    let responsibilitiesString = "";
                                    let additionalInformationString = "";

                                    const jobID = row.original.document.ID;

                                    const requirementsArray = row.original.document.requirements;
                                    const responsibilitiesArray = row.original.document.responsibilities;
                                    const additionalInformationArray = row?.original?.document?.additionalInformation ? row?.original?.document?.additionalInformation : row?.original?.document?.additionalInfo;

                                    for (let i = 0; i < requirementsArray.length; i++) {

                                        const element = requirementsArray[i];
                                        if (requirementsString === "") {
                                            requirementsString = element;
                                        } else {
                                            requirementsString = requirementsString + ";\n" + element;
                                        }

                                        if ((i + 1) === requirementsArray.length) {

                                            for (let j = 0; j < responsibilitiesArray.length; j++) {

                                                const element = responsibilitiesArray[j];
                                                if (responsibilitiesString === "") {
                                                    responsibilitiesString = element;
                                                } else {
                                                    responsibilitiesString = responsibilitiesString + ";\n" + element;
                                                }

                                                if ((1 + j) === responsibilitiesArray.length) {

                                                    if (additionalInformationArray) {

                                                        for (let k = 0; k < additionalInformationArray.length; k++) {

                                                            const element = additionalInformationArray[k];
                                                            if (additionalInformationString === "") {
                                                                additionalInformationString = element;
                                                            } else {
                                                                additionalInformationString = additionalInformationString + ";\n" + element;
                                                            }

                                                            if ((1 + k) === additionalInformationArray.length) {

                                                                const valuesToSet = {
                                                                    closeDate: formatDate(new Date(row.original.document.closeDate)),
                                                                    requirements: requirementsString,
                                                                    responsibilities: responsibilitiesString,
                                                                    roleDescription: row.original.document.roleDescription,
                                                                    roleTitle: row.original.document.roleTitle,
                                                                    additionalInformation: additionalInformationString,
                                                                };

                                                                setStorageItem('jobIdForEdit', jobID);

                                                                setEditState(true);
                                                                setFieldDisabled(true);

                                                                setCreateEditText("Save Changes");
                                                                setInitialValues(valuesToSet);
                                                                setShow(true);

                                                                return closeMenu();

                                                            }

                                                        }

                                                    }

                                                    const valuesToSet = {
                                                        closeDate: formatDate(new Date(row.original.document.closeDate)),
                                                        requirements: requirementsString,
                                                        responsibilities: responsibilitiesString,
                                                        roleDescription: row.original.document.roleDescription,
                                                        roleTitle: row.original.document.roleTitle,
                                                        additionalInformation: additionalInformationString,
                                                    };

                                                    setStorageItem('jobIdForEdit', jobID);

                                                    setEditState(true);
                                                    setFieldDisabled(true);

                                                    setCreateEditText("Save Changes");
                                                    setInitialValues(valuesToSet);
                                                    setShow(true);

                                                    return closeMenu();


                                                }

                                            }

                                        }

                                    }

                                }}
                                divider={true}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <ModeEditTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                Edit Job Details
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const jobID = row.original.document.ID;
                                    setSelectedJobData(row.original.document);
                                    setSelectedJobID(jobID);

                                    //setShowDetailsModal(true);
                                    setRoleTitle(row.original.document.roleTitle);
                                    setShowTable(false);
                                    history.push("/careers/job-details");

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <WorkHistoryTwoToneIcon
                                        color='primary'
                                    />
                                </ListItemIcon>
                                See Applications
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleDeactivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateJob(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            const handleActivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateJob(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            return (
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                    <Button
                                        color="error"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleDeactivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Deactivate
                                    </Button>
                                    <Button
                                        color="success"
                                        disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                        onClick={handleActivate}
                                        variant="contained"
                                        size="small"
                                    >
                                        Activate
                                    </Button>
                                </div>
                            );

                        }}
                        enableStickyFooter
                        enableStickyHeader
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                    />
                </div>
            </div>}
            {!showTable && <div className={`card card-custom ${className}`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {roleTitle}
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            People who've applied
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant='outlined'
                                    color='error'
                                    onClick={() => {
                                        setShowTable(true);
                                    }}
                                    startIcon={<CancelTwoToneIcon color='error' />}
                                >
                                    Close
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pt-3 pb-10">
                    <CareerDetailsPage
                        jobData={selectedJobData}
                        jobID={selectedJobID}
                        onChangeFunction={onChangeWorker}
                    />
                </div>
            </div>}

        </>
    );
}
