import { useFormik } from "formik";
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { getStorageItem } from '../../../../app/services/baseService';
import { updateVendorPhoneNumber } from "../../../../app/modules/Auth/_redux/authCrud";
import { FormAlert } from '../../controls';


export function UpdateVendorPhoneNumberForm({ onChanger }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    // eslint-disable-next-line no-useless-escape
    const phoneRegExp = /^\+?[1-9]\d{1,14}$/;

    const creationSchema = Yup.object().shape({
        phoneNumber: Yup.string().matches(phoneRegExp, 'phone number is not valid!').required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const formik = useFormik({
        initialValues: {
            phoneNumber: ""
        },
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            const id = getStorageItem("vendorIdForEdit");
            enableLoading();
            setTimeout(
                () => {
                    updateVendorPhoneNumber(id, values).then((response) => {
                        disableLoading();
                        setSubmitting(false);

                        if (response) {
                            setFormAlertEnabled();
                            setAlertType({
                                type: "success",
                                icon: "fa fa-check-circle mana-icon-white"
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }
                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return;
                    });
                }, 3000
            );
        }
    });


    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <Form.Group controlId="driverFormPhone">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control type="tel" placeholder="Phone Number" value={formik.values.phoneNumber} onChange={formik.handleChange} className={`${getInputClasses("phoneNumber")}`} name="phoneNumber" {...formik.getFieldProps("phoneNumber")} />
                    <Form.Text className="text-muted">
                        Update vendor phone number
                    </Form.Text>
                    <Form.Control.Feedback>The phone number looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Phone number field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.phone}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    Update Phone Number
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}