import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import * as Yup from "yup";
import { createVehicleModel, editVehicleModel } from '../../../../app/modules/Auth/_redux/authCrud';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { FormAlert } from '../../controls/';
import BasicLoader from '../../loaders/SimpleLoader';

export function CreateEditVehicleModelForm({ initialValues, editState, createEditText, fieldDisabled, onChanger, vehicleModelID }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [vehicleTypeData, setVehicleTypeData] = useState([]);
    const [motorTypeData, setMotorTypeData] = useState([]);
    const [batteryTypeData, setBatteryTypeData] = useState([]);
    const [manufacturerData, setManufacturerData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const [showLoader, setShowLoader] = useState('d-none');

    const TableErrorMessage = useRef();

    const creationSchema = Yup.object().shape({
        vehicleTypeID: Yup.string().required(),
        manufacturerID: Yup.string().required(),
        frameMaterial: Yup.string().required(),
        motorID: Yup.string().required(),
        batteryID: Yup.string().required(),
        brakeSystem: Yup.string().required(),
        throttleType: Yup.string().required(),
        transmission: Yup.string().required(),
        suspension: Yup.string().required(),
        loadLimit: Yup.number().required(),
        loadArea: Yup.string().required(),
        range: Yup.number().required(),
        maxSpeed: Yup.number().required(),
        chargingTime: Yup.string().required(),
        vehicleWeight: Yup.number().required(),
        lights: Yup.string().required(),
        wheelbase: Yup.number().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    const handleLoadingAndErrors = () => {
        if (isLoading)
            return true;
        if (isRefetching)
            return true;

        return false;
    };

    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {

            enableLoading();
            setTimeout(() => {

                if (!editState) {

                    values.wheelbase = Number(values.wheelbase);
                    createVehicleModel(values).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

                if (editState === true) {

                    const editPayload = {
                        id: vehicleModelID,
                        vehicleTypeID: values.vehicleTypeID,
                        manufacturerID: values.manufacturerID,
                        frameMaterial: values.frameMaterial,
                        motorID: values.motorID,
                        batteryID: values.batteryID,
                        brakeSystem: values.brakeSystem,
                        throttleType: values.throttleType,
                        transmission: values.transmission,
                        suspension: values.suspension,
                        loadLimit: values.loadLimit,
                        loadArea: values.loadArea,
                        range: values.range,
                        maxSpeed: values.maxSpeed,
                        chargingTime: values.chargingTime,
                        vehicleWeight: values.vehicleWeight,
                        lights: values.lights,
                        wheelbase: values.wheelbase
                    };

                    editVehicleModel(editPayload, vehicleModelID).then((response) => {

                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);

                        }

                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });
                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );

                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        return onChanger();
                    });

                    return;

                }

            }, 1000);

        },

    });

    const makeOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
        </option>;
    };

    const makeBatteryOptions = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
            {" " + X.document.capacity + "w"}
            {" " + X.document.peakVoltage + "w"}
        </option>;
    };

    const makeMotorOptions = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.type}
            {" " + X.document.nominalWattage + "w"}
            {" " + X.document.peakWattage + "w"}
        </option>;
    };

    const makeManufacturerOption = (X) => {
        return <option key={X.document.ID} value={X.document.ID}>
            {X.document.name}
        </option>;
    };

    useEffect(() => {

        let isMounted = true;

        const fetchVehicleTypeData = async () => {

            setShowLoader('');

            if (!vehicleTypeData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/vehicles/vehicleTypes/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setVehicleTypeData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        const fetchMotorTypeData = async () => {

            setShowLoader('');

            if (!motorTypeData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/motors/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setMotorTypeData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        const fetchBatteryTypeData = async () => {

            setShowLoader('');

            if (!batteryTypeData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/batteries/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setBatteryTypeData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        const fetchManufacturerData = async () => {

            setShowLoader('');

            if (!manufacturerData.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/manufacturers/getAll', `${FLEET_MANAGING_BASE_URL}`);
            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setManufacturerData(data.data);
                setShowLoader('d-none');

            } catch (error) {

                TableErrorMessage.current = error;
                isMounted && setIsError(true);
                setShowLoader('d-none');
                return;

            } finally {

                isMounted && setIsError(false);
                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);
                setShowLoader('d-none');

            }

        };

        fetchVehicleTypeData();
        fetchMotorTypeData();
        fetchBatteryTypeData();
        fetchManufacturerData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                <Form.Group controlId="vehicleFormTypeID">
                    <Form.Label>Vehicle Type</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.vehicleTypeID} onChange={formik.handleChange} className={`${getInputClasses("vehicleTypeID")}`} name="vehicleTypeID" {...formik.getFieldProps("vehicleTypeID")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        {vehicleTypeData.map(makeOption)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select vehicle type
                    </Form.Text>
                    <Form.Control.Feedback>Vehicle type looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle type cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.vehicleTypeID && formik.errors.vehicleTypeID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.vehicleTypeID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormMotorType">
                    <Form.Label>Motor Type</Form.Label>
                    <div>
                        <small>Nominal Wattage | Peak Wattage</small>
                    </div>
                    <Form.Control as="select" data-live-search="true" value={formik.values.motorID} onChange={formik.handleChange} className={`${getInputClasses("motorID")}`} name="motorID" {...formik.getFieldProps("motorID")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        {motorTypeData.map(makeMotorOptions)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select Motor type
                    </Form.Text>
                    <Form.Control.Feedback>Your motor type looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Motor type selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.motorID && formik.errors.motorID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.motorID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormBatteryType">
                    <Form.Label>Battery Type</Form.Label>
                    <div>
                        <small>Capacity | Peak Voltage</small>
                    </div>
                    <Form.Control as="select" data-live-search="true" value={formik.values.batteryID} onChange={formik.handleChange} className={`${getInputClasses("batteryID")}`} name="batteryID" {...formik.getFieldProps("batteryID")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        {batteryTypeData.map(makeBatteryOptions)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select Battery Type
                    </Form.Text>
                    <Form.Control.Feedback>Your battery type looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Battery type selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.batteryID && formik.errors.batteryID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.batteryID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormManufacturer">
                    <Form.Label>Manufacturer</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.manufacturerID} onChange={formik.handleChange} className={`${getInputClasses("manufacturerID")}`} name="manufacturerID" {...formik.getFieldProps("manufacturerID")}>
                        <option defaultValue="" disabled value="">Select one</option>
                        {manufacturerData.map(makeManufacturerOption)}
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select Manufacturer
                    </Form.Text>
                    <Form.Control.Feedback>Your manufacturer looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Manufacturer selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.manufacturerID && formik.errors.manufacturerID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.manufacturerID}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormFrameMaterial">
                    <Form.Label>Frame Material</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.frameMaterial} onChange={formik.handleChange} className={`${getInputClasses("frameMaterial")}`} name="frameMaterial" {...formik.getFieldProps("frameMaterial")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Steel">Steel</option>
                        <option value="Aluminum">Aluminum</option>
                        <option value="Iron">Iron</option>
                        <option value="Steel Aluminum Composite">Steel Aluminum Composite</option>
                    </Form.Control>
                    {handleLoadingAndErrors && <BasicLoader hideShowLoader={showLoader} size="sm" />}
                    <Form.Text className="text-muted">
                        Select Vehicle Frame Material
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle frame material selection cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.frameMaterial && formik.errors.frameMaterial ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.frameMaterial}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormBrakeSystem">
                    <Form.Label>Brake System</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.brakeSystem} onChange={formik.handleChange} className={`${getInputClasses("brakeSystem")}`} name="brakeSystem" {...formik.getFieldProps("brakeSystem")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Hydraulic Braking System">Hydraulic Braking System</option>
                        <option value="Electromagnetic Braking System">Electromagnetic Braking System</option>
                        <option value="Mechanical Braking System">Mechanical Braking System</option>
                        <option value="Servo Braking System">Servo Braking System</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Brake System
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle brake system cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.brakeSystem && formik.errors.brakeSystem ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.brakeSystem}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormThrottleType">
                    <Form.Label>Throttle Type</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.throttleType} onChange={formik.handleChange} className={`${getInputClasses("throttleType")}`} name="throttleType" {...formik.getFieldProps("throttleType")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Thumb Throttle">Thumb Throttle</option>
                        <option value="Half Twist Throttle">Half Twist Throttle</option>
                        <option value="Full Twist Throttle">Full Twist Throttle</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Vehicle Throttle Type
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle throttle type cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.throttleType && formik.errors.throttleType ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.throttleType}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormTransmission">
                    <Form.Label>Transmission</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.transmission} onChange={formik.handleChange} className={`${getInputClasses("transmission")}`} name="transmission" {...formik.getFieldProps("transmission")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Manual Transmission">Manual Transmission</option>
                        <option value="Semi-Automatic Transmission">Semi-Automatic Transmission</option>
                        <option value="Automatic Transmission">Automatic Transmission</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Vehicle Transmission Type
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle transmission type cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.transmission && formik.errors.transmission ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.transmission}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormSuspension">
                    <Form.Label>Suspension</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.suspension} onChange={formik.handleChange} className={`${getInputClasses("suspension")}`} name="suspension" {...formik.getFieldProps("suspension")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Single-pivot Suspension">Single-pivot Suspension</option>
                        <option value="Linkage-driven Single-pivot Suspension">Linkage-driven Single-pivot Suspension</option>
                        <option value="High-Pivot Idler Suspension">High-Pivot Idler Suspension</option>
                        <option value="Twin-link Suspension">Twin-link Suspension</option>
                        <option value="Horst-link Suspension">Horst-link Suspension</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Vehicle Suspension Type
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle suspension type cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.suspension && formik.errors.suspension ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.suspension}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormLoadLimit">
                    <Form.Label>Load Limit (Kg)</Form.Label>
                    <Form.Control type="number" step={0.001} placeholder="Load Limit" value={formik.values.loadLimit} onChange={formik.handleChange} className={`${getInputClasses("loadLimit")}`} name="loadLimit" {...formik.getFieldProps("loadLimit")} />
                    <Form.Text className="text-muted">
                        Vehicle Load Limit
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle load limit cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.loadLimit && formik.errors.loadLimit ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.loadLimit}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormLoadArea">
                    <Form.Label>Load Area</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.loadArea} onChange={formik.handleChange} className={`${getInputClasses("loadArea")}`} name="loadArea" {...formik.getFieldProps("loadArea")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Front">Front</option>
                        <option value="Rear">Rear</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Vehicle Load Area
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle load area cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.loadArea && formik.errors.loadArea ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.loadArea}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormRange">
                    <Form.Label>Range (Km)</Form.Label>
                    <Form.Control type="number" step={0.001} placeholder="Range" value={formik.values.range} onChange={formik.handleChange} className={`${getInputClasses("range")}`} name="range" {...formik.getFieldProps("range")} />
                    <Form.Text className="text-muted">
                        Vehicle Range
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle range cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.range && formik.errors.range ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.range}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormMaxSpeed">
                    <Form.Label>Max Speed (km/h)</Form.Label>
                    <Form.Control type="number" step={0.001} placeholder="Max Speed" value={formik.values.maxSpeed} onChange={formik.handleChange} className={`${getInputClasses("maxSpeed")}`} name="maxSpeed" {...formik.getFieldProps("maxSpeed")} />
                    <Form.Text className="text-muted">
                        Vehicle Max Speed
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle max speed cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.maxSpeed && formik.errors.maxSpeed ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.maxSpeed}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormChargingTime">
                    <Form.Label>Charging Time (hours)</Form.Label>
                    <Form.Control type="text" placeholder="Charging Time" value={formik.values.chargingTime} onChange={formik.handleChange} className={`${getInputClasses("chargingTime")}`} name="chargingTime" {...formik.getFieldProps("chargingTime")} />
                    <Form.Text className="text-muted">
                        Vehicle Charging Time
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle charging time cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.chargingTime && formik.errors.chargingTime ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.chargingTime}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormVehicleWeight">
                    <Form.Label>Vehicle Weight (Kg)</Form.Label>
                    <Form.Control type="number" step={0.001} placeholder="Vehicle Weight" value={formik.values.vehicleWeight} onChange={formik.handleChange} className={`${getInputClasses("vehicleWeight")}`} name="vehicleWeight" {...formik.getFieldProps("vehicleWeight")} />
                    <Form.Text className="text-muted">
                        Vehicle Weight
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle weight cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.vehicleWeight && formik.errors.vehicleWeight ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.vehicleWeight}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormLights">
                    <Form.Label>Lights</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.lights} onChange={formik.handleChange} className={`${getInputClasses("lights")}`} name="lights" {...formik.getFieldProps("lights")}>
                        <option disabled defaultValue="" value="">Select one</option>
                        <option value="Front Lights">Front Lights</option>
                        <option value="Tail Lights">Tail Lights</option>
                        <option value="Frame Lights">Frame Lights</option>
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Vehicle Lights
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Vehicle lights cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.lights && formik.errors.lights ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.lights}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                <Form.Group controlId="vehicleFormWheelBase">
                    <Form.Label>Wheel Base (mm)</Form.Label>
                    <Form.Control type="number" step={0.01} value={formik.values.wheelbase} onChange={formik.handleChange} className={`${getInputClasses("wheelbase")}`} name="wheelbase" {...formik.getFieldProps("wheelbase")} />
                    <Form.Text className="text-muted">
                        Vehicle Wheel Base
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Wheel base cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.wheelbase && formik.errors.wheelbase ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.wheelbase}
                            </div>
                        </div>
                    ) : null}
                </Form.Group>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>
            </Form>
        </>
    );
}