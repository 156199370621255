import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Form, FormGroup } from "react-bootstrap";
import * as Yup from "yup";
import { FormAlert } from "../../controls";
import axios from "axios";
import { FLEET_MANAGING_BASE_URL, getStorageItem } from "../../../../app/services/baseService";
import { createFaultConfiguration, editFaultConfiguration } from "../../../../app/modules/Auth/_redux/authCrud";

export function CreateEditFaultConfiguration({ initialValues, onChanger, editState, createEditText, fieldDisabled }) {

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);
    const [partsArray, setPartsArray] = useState([]);
    const [labourUnitArray, setLabourUnitArray] = useState([]);
    const [categoryArray, setCategoryArray] = useState([]);
    const [subCategoryArray, setSubCategoryArray] = useState([]);

    const creationSchema = Yup.object().shape({
        categories: Yup.array().required(),
        recommendedSolution: Yup.string().required(),
        labourUnits: Yup.number().required(),
        parts: Yup.array().required(),
        personnelCount: Yup.number().required(),
        labourUnitConfigID: Yup.string().required(),
        faultDescription: Yup.string().required()
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const handleAlertDismiss = () => {
        setFormAlertEnabled(false);
    };

    const getInputClasses = (fieldname) => {

        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";

    };

    useEffect(() => {

        let isMounted = true;

        const fetchPartsData = async () => {

            const url = new URL('/inventory/parts', `${FLEET_MANAGING_BASE_URL}`);

            try {
                const response = await axios.get(url.href);
                const data = response.data;

                // console.log(data)

                isMounted && setPartsArray(data.data);


            } catch (error) {

                console.log(error);
            }

        };

        const fetchLabourUnitsData = async () => {

            const url = new URL('/fault-labour-unit-configurations', `${FLEET_MANAGING_BASE_URL}`);

            try {
                const response = await axios.get(url.href);
                const data = response.data;

                isMounted && setLabourUnitArray(data.data);


            } catch (error) {

                console.log(error);
            }

        };

        const fetchCategoryData = async () => {

            const url = new URL('/fault-categories', `${FLEET_MANAGING_BASE_URL}`);

            try {
                const response = await axios.get(url.href);
                const data = response.data;

                isMounted && setCategoryArray(data.data);


            } catch (error) {

                console.log(error);
            }

        };
        const fetchSubCategoryData = async () => {

            const url = new URL('/fault-subcategories', `${FLEET_MANAGING_BASE_URL}`);

            try {
                const response = await axios.get(url.href);
                const data = response.data;

                isMounted && setSubCategoryArray(data.data);


            } catch (error) {

                console.log(error);
            }

        };

        fetchPartsData();
        fetchCategoryData();
        fetchSubCategoryData();
        fetchLabourUnitsData();

        return () => {
            isMounted = false;
        };
    }, []);


    const formik = useFormik({
        initialValues,
        validationSchema: creationSchema,
        onSubmit: (values, { setSubmitting, setStatus }) => {
            console.log(values);
            enableLoading();
            setTimeout(() => {

                if (!editState) {
                    createFaultConfiguration(values).then((response) => {
                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }
                    }).catch((error) => {

                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);

                        return;
                    });

                    return;
                }

                if (editState === true) {
                    const faultConfigID = getStorageItem('faultConfigID');

                    const editPayload = {
                        recommendedSolution: values.recommendedSolution,
                        personnelCount: values.personnelCount,
                        faultDescription: values.faultDescription
                    };
                    editFaultConfiguration(faultConfigID, values).then((response) => {
                        disableLoading();
                        setSubmitting(false);

                        if (response) {

                            setFormAlertEnabled(true);

                            setAlertType({
                                type: 'success',
                                icon: 'fa fa-check-circle mana-icon-white'
                            });
                            onChanger();
                            return setStatus(response.data.message);
                        }
                    }).catch((error) => {
                        setFormAlertEnabled(true);

                        setAlertType({
                            type: 'danger',
                            icon: 'flaticon-warning mana-icon-white'
                        });

                        onChanger();
                        return setStatus(
                            error.response.data?.error?.code
                            ??
                            error.response.data.message
                            ??
                            'an error occurred, please try again later'
                        );
                    }).finally(() => {
                        disableLoading();
                        setSubmitting(false);

                        return;
                    });
                    return;
                }
            }, 3000);

        }
    });

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>
                <FormGroup controlId="faultDescription">
                    <Form.Label>
                        Fault Description
                    </Form.Label>
                    <Form.Control type="faultDescription" placeholder="Broken Lower Frame" value={formik.values.faultDescription} onChange={formik.handleChange} className={`${getInputClasses("faultDescription")}`} name="faultDescription" {...formik.getFieldProps("faultDescription")} />
                    <Form.Text className="text-muted" >
                        Short description of fault
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Fault Description cannot be black
                    </Form.Control.Feedback>
                    {formik.touched.faultDescription && formik.errors.faultDescription ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.faultDescription}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="category">
                    <Form.Label>Choose Categories</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.categories} onChange={formik.handleChange} className={`${getInputClasses("categories")}`} name="categories" {...formik.getFieldProps("categories")} multiple={true} disabled={fieldDisabled}>
                        <option disabled value="">Select one or multiple</option>
                        {
                            categoryArray.map((item) => {
                                return (
                                    <option key={item.document.ID} value={item.document.ID}>
                                        {item.document.name}
                                    </option>
                                );
                            })
                        }
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose job categories
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Categories Field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.categories && formik.errors.categories ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.categories}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {/* <FormGroup controlId="subCategory">
                    <Form.Label>Choose Sub Category</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.subCategory} onChange={formik.handleChange} className={`${getInputClasses("subCategory")}`} name="subCategory" {...formik.getFieldProps("subCategory")}>
                        <option disabled value="">Select one</option>
                        {
                            subCategoryArray.map((item) => {
                                return <option key={item.document.ID} value={item.document.ID}>{item.document.name}</option>
                            })
                        }
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose Sub Category
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Sub Category Field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.subCategory && formik.errors.subCategory ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">{formik.errors.subCategory}</div>
                        </div>
                    ) : null}
                </FormGroup> */}

                <FormGroup controlId="recommendedSolution
                ">
                    <Form.Label>
                        Recommended Solution
                    </Form.Label>
                    <Form.Control type="recommendedSolution" placeholder="Tighten Screws" value={formik.values.recommendedSolution} onChange={formik.handleChange} className={`${getInputClasses("recommendedSolution")}`} name="recommendedSolution" {...formik.getFieldProps("recommendedSolution")} />
                    <Form.Text className="text-muted" >
                        Recommend the solution e.g Tighten Screws
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Recommended solution cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.recommendedSolution && formik.errors.recommendedSolution ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.recommendedSolution}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="labourUnitsConfig">
                    <Form.Label>Select Level</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.labourUnitConfigID} onChange={formik.handleChange} className={`${getInputClasses("labourUnitConfigID")}`} name="labourUnitConfigID" {...formik.getFieldProps("labourUnitConfigID")} disabled={fieldDisabled}>
                        <option disabled value="">Select one</option>
                        {
                            labourUnitArray.map((item) => {
                                return (
                                    <option key={item.document.ID} value={item.document.ID}>
                                        {item.document.title}
                                    </option>
                                );
                            })
                        }
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose job level
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Level field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.labourUnitConfigID && formik.errors.labourUnitConfigID ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.labourUnitConfigID}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="labourUnits">
                    <Form.Label>
                        Number of Labour Units
                    </Form.Label>
                    <Form.Control type="number" placeholder="2" value={formik.values.labourUnits} onChange={formik.handleChange} className={`${getInputClasses("labourUnits")}`} name="labourUnits" {...formik.getFieldProps("labourUnits")} disabled={fieldDisabled} />
                    <Form.Text className="text-muted">
                        Number of Labour Units
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Labour Units cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.labourUnits && formik.errors.labourUnits ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.labourUnits}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                <FormGroup controlId="personnelCount">
                    <Form.Label>
                        Personnel Count
                    </Form.Label>
                    <Form.Control type="number" placeholder="1" value={formik.values.personnelCount} onChange={formik.handleChange} className={`${getInputClasses("personnelCount")}`} name="personnelCount" {...formik.getFieldProps("personnelCount")} />
                    <Form.Text className="text-muted">
                        Personnel Count
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Personnel Count cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.personnelCount && formik.errors.personnelCount ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.personnelCount}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>
                <FormGroup controlId="parts">

                    <Form.Label>Choose Parts</Form.Label>
                    <Form.Control as="select" data-live-search="true" value={formik.values.parts} onChange={formik.handleChange} className={`${getInputClasses("parts")}`} name="parts" {...formik.getFieldProps("parts")} multiple={true} disabled={fieldDisabled} >
                        <option disabled value="">Select one or multiple</option>
                        {partsArray.map((part) => {
                            return (
                                <option value={part.partID} key={part.partID}>
                                    {part.document.partName}
                                </option>
                            );
                        })}
                    </Form.Control>
                    <Form.Text className="text-muted">
                        Choose parts (hold down the shift key and click the parts needed)
                    </Form.Text>
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                    <Form.Control.Feedback type="invalid">
                        Parts Field cannot be blank
                    </Form.Control.Feedback>
                    {formik.touched.parts && formik.errors.parts ? (
                        <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                                {formik.errors.parts}
                            </div>
                        </div>
                    ) : null}
                </FormGroup>

                {formik.status ? <FormAlert isEnabled={isFormAlertEnabled} type={alertType.type} message={formik.status} icon={alertType.icon} handleDismiss={handleAlertDismiss} /> : null}

                <Button variant="primary" type="submit" disabled={formik.isSubmitting} className={`font-weight-bold px-9 py-4 my-3`}>
                    {createEditText}
                    {loading && <span className="ml-5 spinner spinner-white"></span>}
                </Button>

            </Form >
        </>
    );
}