/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import { Dropdown } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../_wahu/_helpers";
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';
import TtyTwoToneIcon from '@mui/icons-material/TtyTwoTone';
import PriceCheckTwoToneIcon from '@mui/icons-material/PriceCheckTwoTone';
import {
    DropdownCustomToggler,
    DropdownMenu4,
} from "../../../../_wahu/_partials/dropdowns";
import ElectricBikeIcon from '@mui/icons-material/ElectricBike';
import AssignmentReturnTwoToneIcon from '@mui/icons-material/AssignmentReturnTwoTone';
import ExitToAppTwoToneIcon from '@mui/icons-material/ExitToAppTwoTone';
import AnnouncementTwoToneIcon from '@mui/icons-material/AnnouncementTwoTone';
import SdCardAlertTwoToneIcon from '@mui/icons-material/SdCardAlertTwoTone';
import DiscFullTwoToneIcon from '@mui/icons-material/DiscFullTwoTone';
import { SimpleModal } from "../../../../_wahu/_partials/widgets/modals/SimpleModal";
import {
    CreateTransactionForm,
    UpdateUserEmailForm,
    UpdateUserPhoneForm,
    VehiclesTableSub
} from "../../../../_wahu/_partials/widgets";
import {
    IconButton,
    Snackbar,
    Button,
    Alert
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import { churnDriver, unChurnDriver } from "../../Auth/_redux/authCrud";
import BasicLoader from "../../../../_wahu/_partials/loaders/SimpleLoader";
import { shallowEqual, useSelector } from "react-redux";
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

export function DriverProfileCard({ driverDetails, onchangeFunction, isChanger }) {

    const TableMessage = useRef();
    const classes = useStyles();

    const [editState, setEditState] = useState(false);

    const [selectedDriverID, setSelectedDriverID] = useState("");
    const [driverNameForUpdate, setDriverNameForUpdate] = useState("");
    const [initialPhoneValue, setInitialPhoneValue] = useState("");
    const [createEditText, setCreateEditText] = useState("");
    const [showPhoneUpdateModal, setShowPhoneUpdateModal] = useState(false);

    const [showEmailUpdateModal, setShowEmailUpdateModal] = useState(false);
    const [initialEmailValue, setInitialEmailValue] = useState({
        email: ""
    });

    const [showAssignModal, setShowAssignModal] = useState(false);
    const [specDriverData, setSpecDriverData] = useState(null);
    const [isError, setIsError] = useState(false);
    const [loading, setLoading] = useState(true);

    const [createTransactionDefaultValues, setCreateTransactionDefaultValues] = useState({
        email: "",
        amount: 0,
        currency: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        isFirstPayment: false,
        isSubscriptionPayment: false,
        userID: "",
        weeksPaid: 1,
        setterID: "",
    });
    const [showAddPaymentModal, setShowAddPaymentModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [isChurnLoadingSate, setIsChurnLoadingSate] = useState(false);
    const [unChurnLoadingSate, setUnChurnLoadingSate] = useState(false);

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpenSnackbar(false);
    }

    useEffect(() => {

        let isMounted = true;

        const fetchDriverData = async () => {

            try {

                setSpecDriverData(driverDetails);
                return setLoading(false);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableMessage.current = "network error, please check your internet connection";

                    setIsError(true);
                    setOpenSnackbar(true);

                    return setLoading(false);

                }

                TableMessage.current = `Code: ${error?.response?.status ?? error?.code}, Message: ${error?.response?.data?.message ?? error?.message}`;
                setSeverity("error");
                setIsError(true);
                setOpenSnackbar(true);

                return setLoading(false);

            }

        };

        isMounted && fetchDriverData();

        return () => isMounted = false;

    }, [driverDetails]);

    return (
        <>

            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate} Phone's Number`}
                show={showPhoneUpdateModal}
                close={() => setShowPhoneUpdateModal(false)}
                size="lg"
                body={<UpdateUserPhoneForm
                    initialValues={initialPhoneValue}
                    createEditText={createEditText}
                    onChanger={onchangeFunction}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Update ${driverNameForUpdate}'s Email Address`}
                show={showEmailUpdateModal}
                close={() => setShowEmailUpdateModal(false)}
                size="lg"
                body={<UpdateUserEmailForm
                    initialValues={initialEmailValue}
                    editState={editState}
                    createEditText={createEditText}
                    onChanger={onchangeFunction}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Select vehicle(s) to assign to ${driverNameForUpdate}`}
                show={showAssignModal}
                close={() => setShowAssignModal(false)}
                size="xl"
                body={<VehiclesTableSub
                    assignmentType="driver"
                    onChanger={onchangeFunction}
                    selectedDriverID={selectedDriverID}
                />}
            />
            <SimpleModal
                modal_tittle={`Add payment for ${driverNameForUpdate}`}
                show={showAddPaymentModal}
                close={() => setShowAddPaymentModal(false)}
                size="lg"
                body={<CreateTransactionForm
                    initialValues={createTransactionDefaultValues}
                    createEditText={"Create Transaction"}
                    onChanger={onchangeFunction}
                    userID={createTransactionDefaultValues.userID}
                />}

            />
            {loading && (<>
                <div className="centerMan p-4">
                    <BasicLoader size={"lg"} />
                </div>
            </>)}
            {isError && !loading && (<>
                <div className="centerMan p-4">
                    <p className="text-danger">
                        <WarningTwoToneIcon color="error" />
                        {" "}
                        {TableMessage.current}
                    </p>
                </div>
            </>)}
            {!loading && !isError && <div className="flex-row-auto">
                <div className="card card-custom rounded-xl no-border-shadow">
                    <div className="card-body pt-4">
                        <div className="d-flex justify-content-end">
                            <Dropdown className="dropdown dropdown-inline border rounded" alignRight>
                                <Dropdown.Toggle
                                    className="btn btn-clean btn-hover-light-primary btn-sm btn-icon cursor-pointer"
                                    variant="transparent"
                                    id="dropdown-toggle-top-user-profile"
                                    as={DropdownCustomToggler}
                                >
                                    <i className="ki ki-bold-more-hor"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="dropdown-menu dropdown-menu-sm dropdown-menu-right">
                                    <DropdownMenu4
                                        driverData={specDriverData}
                                        onchangeFunction={onchangeFunction}
                                        isChanger={isChanger}
                                    />
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="d-flex align-items-center">
                            <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                                <div
                                    className="symbol-label"
                                    style={{
                                        backgroundImage: `url(${specDriverData.user_data.document.profileImageURL ?? toAbsoluteUrl("/media/users/blank.png")})`
                                    }}
                                ></div>
                                {specDriverData.user_data.document.isActive ? <i className="symbol-badge bg-success"></i> : <i className="symbol-badge bg-danger"></i>}
                            </div>
                            <div>
                                <span
                                    className="font-weight-bolder font-size-h5 text-dark-75"
                                >
                                    {specDriverData.user_data.document.firstName}
                                    {" "}
                                    {specDriverData.user_data.document.lastName}
                                </span>
                                <div className="text-muted text-uppercase">
                                    {specDriverData.user_data.document.gender}
                                </div>
                                <div className="mt-2">
                                    {specDriverData.user_data?.document?.isSubscriptionsPaused ? (<>
                                        <span className="bg-warning rounded text-white font-weight-bold py-2 px-3 px-xxl-5 my-1 mx-1">
                                            Paused Subscription
                                        </span>
                                    </>) : ""}
                                    {specDriverData.user_data?.document?.isActive ? (<>
                                        <span className="bg-success rounded text-white font-weight-bold py-2 px-3 px-xxl-5 my-1 mx-1">
                                            Active
                                        </span>
                                    </>) : <span className="bg-danger rounded text-white font-weight-bold py-2 px-3 px-xxl-5 my-1 mx-1">
                                        Inactive
                                    </span>}
                                </div>
                            </div>
                        </div>
                        <div className="py-9">
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">ID:</span>
                                <span className="text-muted">
                                    {specDriverData.user_data?.document.ID}
                                </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">Email:</span>
                                <span className="text-muted">
                                    {specDriverData.user_data?.document?.email ?
                                        (
                                            <>
                                                <a href={`mailto: ${specDriverData.user_data.document.email}`}>
                                                    {specDriverData.user_data.document.email}
                                                </a>
                                            </>
                                        ) : (
                                            <>
                                                <AnnouncementTwoToneIcon
                                                    color="error"
                                                    fontSize="large"
                                                />
                                                {" "}
                                                <span className="text-danger">
                                                    no email address added
                                                </span>
                                            </>
                                        )
                                    }
                                </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between mb-2">
                                <span className="font-weight-bold mr-2">Phone:</span>
                                <span className="text-muted">
                                    {specDriverData.user_data?.document?.phoneNumber ?
                                        (<>
                                            <a href={`tel:${specDriverData.user_data.document.phoneNumber}`}>
                                                {specDriverData.user_data.document.phoneNumber}
                                            </a>
                                        </>)
                                        :
                                        specDriverData.user_data?.document?.phone ?
                                            (<>
                                                <a href={`tel:${specDriverData.user_data.document.phone}`}>
                                                    {specDriverData.user_data.document.phone}
                                                </a>
                                            </>)
                                            :
                                            (<>
                                                <SdCardAlertTwoToneIcon
                                                    color="error"
                                                    fontSize="large"
                                                />
                                                {" "}
                                                <span className="text-danger bold">
                                                    no phone number added
                                                </span>
                                            </>)
                                    }
                                </span>
                            </div>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="font-weight-bold mr-2">Country:</span>
                                <span className="text-muted">
                                    {specDriverData.user_data?.document?.country ?
                                        (<>
                                            {specDriverData.user_data.document.country}
                                        </>)
                                        :
                                        (<>
                                            <DiscFullTwoToneIcon
                                                color="error"
                                                fontSize="large"
                                            />
                                        </>)
                                    }
                                </span>
                            </div>
                        </div>
                        <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                            <div className="navi-item mb-2">
                                <a
                                    href="#0"
                                    className="navi-link py-4 border"
                                    onClick={() => {

                                        const driverID = specDriverData.user_data.document.ID;
                                        setSelectedDriverID(driverID);

                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);
                                        setInitialPhoneValue({
                                            phone: specDriverData.user_data.document.phone
                                        });
                                        setCreateEditText("Update Phone Number");

                                        setShowPhoneUpdateModal(true);

                                        return;

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <TtyTwoToneIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Update Phone Number
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                <a
                                    href="#0"
                                    className="navi-link py-4 border"
                                    onClick={() => {

                                        const driverID = specDriverData.user_data.document.ID;
                                        setSelectedDriverID(driverID);

                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);
                                        setInitialEmailValue({
                                            email: specDriverData.user_data.document.email
                                        });
                                        setCreateEditText("Update Email Address");
                                        setEditState(true);

                                        setShowEmailUpdateModal(true);

                                        return;

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <MarkEmailReadTwoToneIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Update Email
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                <a
                                    href="#0"
                                    className="navi-link py-4 border"
                                    onClick={async () => {

                                        setCreateTransactionDefaultValues({
                                            firstName: specDriverData.user_data.document.firstName,
                                            lastName: specDriverData.user_data.document.lastName,
                                            phoneNumber: specDriverData.user_data.document?.phone ?? specDriverData.user_data.document?.phoneNumber,
                                            userID: specDriverData.user_data.document.ID,
                                            email: specDriverData.user_data.document?.email ?? "admin@wahu.me",
                                            amount: 0,
                                            currency: "GHS",
                                            isFirstPayment: false,
                                            isSubscriptionPayment: true,
                                            weeksPaid: 1,
                                            paymentDate: new Date().getTime(),
                                            setterID: user.userID,
                                        });
                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);
                                        setShowAddPaymentModal(true);

                                        return;

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <PriceCheckTwoToneIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Log Payment
                                    </span>
                                    <span className="navi-label">
                                        <span className="label label-light-primary label-rounded font-weight-bold">

                                        </span>
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                <a
                                    href="#"
                                    className="navi-link py-4 border"
                                    onClick={() => {

                                        const driverID = specDriverData.user_data.document.ID;

                                        setSelectedDriverID(driverID);
                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);

                                        setEditState(true);
                                        setShowAssignModal(true);

                                        return;

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <ElectricBikeIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Assign Vehicle
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                <a
                                    href="#0"
                                    className="navi-link py-4 border"
                                    onClick={async () => {

                                        setIsChurnLoadingSate(true);
                                        setSelectedDriverID(specDriverData.user_data.document.ID);
                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);

                                        churnDriver(specDriverData.user_data.document.ID).then((response) => {

                                            if (response) {

                                                setSeverity('success');
                                                TableMessage.current = response.data.message;
                                                triggerSnackBar();

                                                setIsChurnLoadingSate(false);
                                                onchangeFunction();

                                                return;

                                            }

                                        }).catch((error) => {

                                            setSeverity('error');

                                            if (error.response) {
                                                TableMessage.current = error?.response?.data?.message;
                                                triggerSnackBar();
                                                return onchangeFunction();
                                            }

                                            TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                            triggerSnackBar();
                                            onchangeFunction();

                                            setIsChurnLoadingSate(false);
                                            return;

                                        });

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <ExitToAppTwoToneIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Churn
                                        {" "}
                                        {isChurnLoadingSate && <BasicLoader size={"sm"} />}
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                <a
                                    href="#0"
                                    className="navi-link py-4 border"
                                    onClick={async () => {

                                        setUnChurnLoadingSate(true);
                                        setSelectedDriverID(specDriverData.user_data.document.ID);
                                        setDriverNameForUpdate(specDriverData.user_data.document.firstName + " " + specDriverData.user_data.document.lastName);

                                        unChurnDriver(specDriverData.user_data.document.ID).then((response) => {

                                            if (response) {

                                                setSeverity('success');
                                                TableMessage.current = response.data.message;
                                                triggerSnackBar();

                                                setUnChurnLoadingSate(false);
                                                onchangeFunction();

                                                return;

                                            }

                                        }).catch((error) => {

                                            setSeverity('error');

                                            if (error.response) {
                                                TableMessage.current = error?.response?.data?.message;
                                                triggerSnackBar();
                                                return onchangeFunction();
                                            }

                                            TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                            triggerSnackBar();
                                            onchangeFunction();

                                            setUnChurnLoadingSate(false);

                                            return;

                                        });

                                    }}
                                >
                                    <span className="navi-icon mr-2">
                                        <span className="svg-icon">
                                            <AssignmentReturnTwoToneIcon />
                                            {" "}
                                        </span>
                                    </span>
                                    <span className="navi-text font-size-lg">
                                        Un-Churned
                                        {" "}
                                        {unChurnLoadingSate && <BasicLoader size={"sm"} />}
                                    </span>
                                </a>
                            </div>
                            <div className="navi-item mb-2">
                                {/* <OverlayTrigger
                                    placement="left"
                                    overlay={<Tooltip>Coming soon...</Tooltip>}
                                >
                                    <a href="#" className="navi-link py-4">
                                        <span className="navi-icon mr-2">
                                            <span className="svg-icon">
                                                <SVG
                                                    src={toAbsoluteUrl(
                                                        "/media/svg/icons/Text/Article.svg"
                                                    )}
                                                ></SVG>
                                                {" "}
                                            </span>
                                        </span>
                                        <span className="navi-text">Invoices</span>
                                    </a>
                                </OverlayTrigger> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

        </>
    );
}
