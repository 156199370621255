import React, { useState } from 'react';
import Chart from "react-apexcharts";

const BarChartAlt = ({ categories, data, exportFilename, chartTitle, xAxisName, yAxisName, yAxisMinVal, yAxisMaxVal }) => {

    const [options, setOptions] = useState({
        chart: {
            id: "basic-bar",
            toolbar: {
                show: true,
                tools: {
                    download: true,
                    selection: false,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: false,
                    customIcons: []
                },
                export: {
                    csv: {
                        filename: exportFilename,
                        columnDelimiter: ',',
                        headerCategory: xAxisName,
                        headerValue: 'value',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString();
                        }
                    },
                    svg: {
                        filename: exportFilename,
                    },
                    png: {
                        filename: exportFilename,
                    }
                }
            },
            stroke: {
                curve: "straight",
                show: true,
                width: 1,
            },
            dropShadow: {
                enabled: true,
                color: '#89ff89',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
            },
            grid: {
                borderColor: '#e7e7e7',
                row: {
                    colors: ['#89ff89', 'transparent'],
                    opacity: 0.5
                },
            },
            markers: {
                size: 50
            },
            dataLabels: {
                enabled: true,
                enabledOnSeries: [1]
            },
            title: {
                text: chartTitle,
                align: "left"
            },
            fill: {
                type: 'gradient',
                gradient: {
                    shadeIntensity: 1,
                    inverseColors: false,
                    opacityFrom: 0.5,
                    opacityTo: 0,
                    stops: [0, 90, 100]
                },
            },
        },
        xaxis: {
            categories: categories,
            name: xAxisName,
            labels: {
                show: true
            },
            title: {
                text: xAxisName
            }
        },
        yaxis: {
            title: {
                text: yAxisName
            },
            min: yAxisMinVal,
            max: yAxisMaxVal
        },
        responsive: [
            {
                breakpoint: 1000,
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: false
                        }
                    },
                    legend: {
                        position: "bottom"
                    }
                }
            }
        ]
    });

    const [series, setSeries] = useState(
        [{
            data: data,
            name: yAxisName,
        }]
    );

    return (

        <div className='line-chart-area'>
            <Chart
                type='bar'
                options={options}
                series={series}
            />
        </div>

    );

};

export default BarChartAlt;