import { makeStyles } from '@mui/styles';
import { AssignmentTurnedIn } from '@mui/icons-material';
import EditLocationAltTwoToneIcon from '@mui/icons-material/EditLocationAltTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import {
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    TextField,
    Typography,
    IconButton,
    Snackbar,
    Alert,
    ButtonGroup,
    Grid,
    styled,
    Paper,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { activateTracker, deactivateTracker, assignTracker, unassignTrackerFromVehicle } from '../../../../app/modules/Auth/_redux/authCrud';
import { setStorageItem, FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { CreateEditTrackerForm } from '../forms/CreateEditTrackerForm';
import { SimpleModal } from '../modals/SimpleModal';
import { VehiclesTableSub } from "./VehicleTableSub";
import { shallowEqual, useSelector } from "react-redux";
import { VehicleDetailsList } from '../lists/VehicleDetailsList';
import DisplaySettingsTwoToneIcon from '@mui/icons-material/DisplaySettingsTwoTone';
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone';
import BasicLoader from '../../loaders/SimpleLoader';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'left',
    color: theme.palette.text.secondary,
}));

export function TrackersTable({ className, onChanger, assignmentType, assignAction, isSelectionAction, selectionAction, vehicleID, assignmentEntityName, assignmentModalCloser }) {

    const user = useSelector(({ auth }) => auth.user, shallowEqual);

    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [show, setShow] = useState(false);
    const [showAssignModal, setShowAssignModal] = useState(false);

    const [initialValues, setInitialValues] = useState({
        name: "",
        IMEI: "",
        model: "",
        manufacturer: ""
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Add Tracker');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [actionBtnDisabled, setActionBtnDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');

    const [showVehicleDetailsModal, setShowVehicleDetailsModal] = useState(false);
    const [selectedVehicleModelID, setSelectedVehicleModelID] = useState("");
    const [selectedVehicleData, setSelectedVehicleData] = useState(null);

    const [loadingVehicleDetails, setLoadingVehicleDetails] = useState(false);
    const [loadingTrackerUnassignment, setLoadingTrackerUnassignment] = useState(false);

    const [loadingAssignment, setLoadingAssignment] = useState(false);

    const TableErrorMessage = useRef();
    const classes = useStyles();

    const defaultValuesToSet = {
        name: "",
        IMEI: "",
        model: "",
        manufacturer: ""
    };

    function triggerSnackBar() {
        setOpenSnackbar(true);
    }

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    }

    const handleUnAssignment = async (vehicleID, trackerID) => {

        const vehicleUnassignmentPayload = ({
            trackerID: trackerID,
            vehicleID: vehicleID,
            assignerID: user.user_data.document.ID,
        });

        await unassignTrackerFromVehicle(vehicleUnassignmentPayload).then((tackerUnassignmentResponse) => {

            setSeverity('success');
            TableErrorMessage.current = tackerUnassignmentResponse.data.message;
            triggerSnackBar();

            setReRender(prevState => prevState + 1);
            setActionBtnDisabled(false);
            return setLoadingTrackerUnassignment(false);

        }).catch((error) => {

            setSeverity('error');
            setActionBtnDisabled(false);

            if (error.response) {
                TableErrorMessage.current = error?.response?.data?.message;
                setReRender(prevState => prevState + 1);
                triggerSnackBar();
                return setLoadingTrackerUnassignment(false);
            }

            TableErrorMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? "an expected error occurred";
            setReRender(prevState => prevState + 1);
            triggerSnackBar();
            return setLoadingTrackerUnassignment(false);

        });

    };

    function displayAssignedVehicle(ingressData) {

        const vehicleID = ingressData;
        if (vehicleID) {

            const fetchVehicleData = async () => {

                const url = new URL(`/vehicles/${vehicleID}`, `${FLEET_MANAGING_BASE_URL}`);
                try {

                    const response = axios.get(url.href);
                    const data = (await response).data;

                    setSelectedVehicleModelID(data.document.vehicleModelID);
                    setSelectedVehicleData(data);

                    setShowVehicleDetailsModal(true);
                    setReRender(prevState => prevState + 1);
                    return setLoadingVehicleDetails(false);

                } catch (error) {

                    if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                        setSeverity("error");
                        TableErrorMessage.current = "network error, please check your internet connection";
                        setOpenSnackbar(true);

                        setReRender(prevState => prevState + 1);
                        return setLoadingVehicleDetails(false);

                    }

                    TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                    setSeverity("error");
                    setIsError(true);
                    setOpenSnackbar(true);

                    setReRender(prevState => prevState + 1);
                    return setLoadingVehicleDetails(false);

                }

            };

            fetchVehicleData();
            return;

        }

        setSeverity("error");
        TableErrorMessage.current = "invalid or missing vehicleID";
        triggerSnackBar();

        return setLoadingVehicleDetails(false);

    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    const DisplayAssignedVehicles = (props) => {
        return (
            <Alert
                icon={false}
                action={
                    <>
                        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={6}>
                                <Button
                                    startIcon={<DisplaySettingsTwoToneIcon />}
                                    color="info"
                                    size="small"
                                    variant="contained"
                                    disabled={loadingVehicleDetails}
                                    onClick={() => {
                                        setLoadingVehicleDetails(true);
                                        displayAssignedVehicle(props.vehicleid);
                                    }}
                                >
                                    <span className='text-nowrap'>
                                        Vehicle Details
                                        {" "}
                                        {loadingVehicleDetails && <BasicLoader size={"sm"} />}
                                    </span>
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    startIcon={<CancelPresentationTwoToneIcon />}
                                    color="error"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                        setLoadingTrackerUnassignment(true);
                                        handleUnAssignment(props.vehicleid, props.trackerid);
                                    }}
                                    disabled={loadingTrackerUnassignment}
                                >
                                    <span className='text-nowrap'>
                                        Un-Assign
                                        {" "}
                                        {loadingTrackerUnassignment && <BasicLoader size={"sm"} />}
                                    </span>
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                }
                elevation={7}
                variant="outlined"
                {...props}
            >
                {props.vehicleid ??
                    (<>
                        <span className='text-danger'>
                            no vehicle assigned yet
                        </span>
                    </>)
                }
            </Alert>
        );
    };

    useEffect(() => {

        let isMounted = true;

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/trackers/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                isMounted && setData(data.data);
                isMounted && setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpenSnackbar(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                isMounted && setSeverity("error");
                isMounted && setIsError(true);
                setOpenSnackbar(true);

                return;

            } finally {

                isMounted && setIsLoading(false);
                isMounted && setIsRefetching(false);

            }

        };

        fetchData();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => row?.document?.name,
            id: 'name',
            enableClickToCopy: true,
            header: 'Name',
        },
        {
            accessorFn: (row) => row?.document?.IMEI,
            id: "IMEI",
            enableClickToCopy: true,
            header: 'IMEI'
        },
        {
            accessorFn: (row) => row?.document?.vehicleID,
            header: 'Assignment Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() ? theme.palette.success.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',
                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() ? (<>
                        <span className='text-uppercase'>
                            Assigned
                        </span>
                    </>) : (<>
                        <span className='text-uppercase'>
                            Unassigned
                        </span>
                    </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => row?.document?.model,
            id: "model",
            enableClickToCopy: true,
            header: 'Model'
        },
        {
            accessorFn: (row) => row?.document?.manufacturer,
            id: "manufacturer",
            enableClickToCopy: true,
            header: 'Manufacturer'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? (<>
                        <span className='text-uppercase'>
                            Active
                        </span>
                    </>) : (<>
                        <span className='text-uppercase'>
                            Inactive
                        </span>
                    </>)}
                </Box>
            ),
        },
        {
            accessorFn: (row) => `${new Date(row?.document?.createdAt).toLocaleDateString()}`,
            id: 'document.ID',
            header: 'Date Created',
            filterFn: 'lessThanOrEqualTo',
            sortingFn: 'datetime',
            filterVariant: 'date',
            Cell: ({ cell }) => cell.getValue(),
            Header: ({ column }) => <em>
                {column.columnDef.header}
            </em>,
            Filter: ({ column }) => (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                        onChange={(newValue) => {
                            column.setFilterValue(newValue);
                        }}
                        input={(params) => (
                            <TextField
                                {...params}
                                helperText={'Filter Mode: Less Than'}
                                sx={{ minWidth: '120px' }}
                                variant="standard"
                            />
                        )}
                        value={column.getFilterValue()}
                    />
                </LocalizationProvider>
            )
        },
        {
            accessorFn: (row) => row?.document?.ID,
            id: "trackerID",
            enableClickToCopy: true,
            header: 'ID'
        },
    ],
        [],
    );

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={openSnackbar}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>,
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    onClose={handleSnackbarClose}
                    severity={severity}
                >
                    {<span id="message-id">
                        {TableErrorMessage.current}
                    </span>}
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Tracker`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditTrackerForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                />}
            />
            <SimpleModal
                modal_tittle={`Select vehicle(s) to assigned tracker to`}
                show={showAssignModal}
                close={() => setShowAssignModal(false)}
                size="xl"
                body={<VehiclesTableSub
                    assignmentType="tracker"
                    onChanger={onChangeWorker}
                    tableClassName="table-style"
                />}
            />
            <SimpleModal
                modal_tittle={`Assigned Vehicle Details`}
                show={showVehicleDetailsModal}
                close={() => setShowVehicleDetailsModal(false)}
                size="lg"
                body={<VehicleDetailsList
                    className="card-stretch gutter-b"
                    selectedVehicleModelID={selectedVehicleModelID}
                    selectedVehicleData={selectedVehicleData}
                />}
            />
            <div className={`card card-custom ${className} rounded-xl`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            {isLoading && <BasicLoader size={"sm"} />}
                            {!isLoading && rowCount}
                            {" "}
                            Trackers
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage Platform Trackers from this table
                        </span>
                    </h3>
                    <div className="card-toolbar">
                        <div className="row">
                            <div className="col-xxl-12 order-1 order-xxl-1">
                                <Button
                                    variant="contained"
                                    size="medium"
                                    color="info"
                                    aria-label="Add"
                                    className={classes.margin}
                                    onClick={() => {
                                        setShow(true);
                                        setEditState(false);
                                        setFieldDisabled(false);
                                        setCreateEditText("Add Tracker");
                                        setInitialValues(defaultValuesToSet);
                                    }}
                                >
                                    Add Tracker
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError
                                ? {
                                    color: 'error',
                                    children: `${TableErrorMessage.current}`,
                                }
                                : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                <Grid item xs={12}>
                                    <Item>
                                        <Typography variant="label">Assigned Vehicle:</Typography>
                                        <DisplayAssignedVehicles
                                            severity="info"
                                            vehicleid={row.original.document?.vehicleID}
                                            trackerid={row.original.document?.ID}
                                        />
                                    </Item>
                                </Grid>
                            </Grid>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const trackerID = row.original.document.ID;
                                    const valuesToSet = {
                                        name: row.original.document.name,
                                        model: row.original.document.model,
                                        manufacturer: row.original.document.manufacturer,
                                        IMEI: row.original.document.IMEI
                                    };

                                    setStorageItem('trackerIdForEdit', trackerID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShow(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                                divider={true}
                            >
                                <ListItemIcon>
                                    <EditLocationAltTwoToneIcon
                                        color='info'
                                    />
                                </ListItemIcon>
                                Edit Tracker Details
                            </MenuItem>,
                            <MenuItem
                                key={1}
                                onClick={() => {

                                    const trackerID = row.original.document.ID;
                                    const valuesToSet = {
                                        name: row.original.document.name,
                                        model: row.original.document.model,
                                        manufacturer: row.original.document.manufacturer,
                                        IMEI: row.original.document.IMEI
                                    };

                                    setStorageItem('trackerIdForAssignment', trackerID);

                                    setEditState(true);
                                    setFieldDisabled(true);

                                    setCreateEditText("Save Changes");
                                    setInitialValues(valuesToSet);
                                    setShowAssignModal(true);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <AssignmentTurnedIn
                                        color='primary'
                                    />
                                </ListItemIcon>
                                Assign to Vehicles
                            </MenuItem>
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            const handleDeactivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await deactivateTracker(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            const handleActivate = () => {

                                setActionBtnDisabled(true);

                                table.getSelectedRowModel().flatRows.map(async (row) => {
                                    try {
                                        const response = await activateTracker(row.original.document.ID);

                                        TableErrorMessage.current = response.data.message;
                                        setSeverity('success');

                                        setReRender(prevState => prevState + 1);
                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    } catch (error) {
                                        TableErrorMessage.current = error.message;
                                        setSeverity('error');

                                        setActionBtnDisabled(false);
                                        triggerSnackBar();
                                    }
                                });
                            };

                            const handleAssignment = () => {

                                setActionBtnDisabled(true);
                                setLoadingAssignment(true);

                                if (!assignmentType) {

                                    setSeverity('error');
                                    TableErrorMessage.current = "no assignment type set for this action";

                                    setActionBtnDisabled(false);
                                    return triggerSnackBar();

                                }

                                switch ((assignmentType).toLowerCase()) {

                                    case 'vehicle': {

                                        table.getSelectedRowModel().flatRows.map(async (row) => {

                                            if (!vehicleID) {

                                                setSeverity('error');
                                                TableErrorMessage.current = "no vehicle ID set for this action";

                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);
                                                return triggerSnackBar();

                                            }

                                            const vehicleAssignmentPayload = ({
                                                trackerID: row.original.document.ID,
                                                vehicleID: vehicleID,
                                                assignerID: user.user_data.document.ID,
                                            });

                                            await assignTracker(vehicleAssignmentPayload).then((tackerAssignmentResponse) => {

                                                setSeverity('success');
                                                TableErrorMessage.current = tackerAssignmentResponse.data.message;
                                                triggerSnackBar();

                                                setReRender(prevState => prevState + 1);
                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);

                                                return onChanger();

                                            }).catch((error) => {

                                                setSeverity('error');
                                                setActionBtnDisabled(false);
                                                setLoadingAssignment(false);

                                                if (error.response) {
                                                    TableErrorMessage.current = error?.response?.data?.message;
                                                    triggerSnackBar();
                                                    return onChanger();
                                                }

                                                TableErrorMessage.current = error?.message ?? error?.code ?? error?.response.data.message ?? error?.response.data.code ?? "an expected error occurred";
                                                triggerSnackBar();
                                                return onChanger();

                                            });

                                        });

                                        break;

                                    }

                                    default: {

                                        setSeverity('error');
                                        TableErrorMessage.current = 'assignment parent entity not set for this action';

                                        setActionBtnDisabled(false);
                                        setLoadingAssignment(false);

                                        return triggerSnackBar();

                                    }

                                }

                            };

                            return (
                                <>

                                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                                        {assignAction && <ButtonGroup size="small" aria-label="small button group">
                                            <Button
                                                color="secondary"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled || !assignAction}
                                                onClick={handleAssignment}
                                                variant="contained"
                                                size="small"
                                            >
                                                <span className='text-nowrap'>
                                                    {assignmentEntityName}
                                                    {" "}
                                                    {loadingAssignment && <BasicLoader size={"sm"} />}
                                                </span>
                                            </Button>
                                        </ButtonGroup>}

                                        <ButtonGroup size="small" aria-label="small button group">
                                            <Button
                                                color="error"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                                onClick={handleDeactivate}
                                                variant="contained"
                                                size="small"
                                            >
                                                Deactivate
                                            </Button>
                                            <Button
                                                color="success"
                                                disabled={table.getSelectedRowModel().flatRows.length === 0 || actionBtnDisabled}
                                                onClick={handleActivate}
                                                variant="contained"
                                                size="small"
                                            >
                                                Activate
                                            </Button>
                                        </ButtonGroup>
                                    </div>

                                </>
                            );

                        }}
                        enableStickyHeader
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
