import {
    IconButton,
    Snackbar,
    Box,
    Button,
    ListItemIcon,
    MenuItem,
    Typography,
    Paper,
    Grid,
    styled,
    Alert,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { makeStyles } from '@mui/styles';
import CloseIcon from '@mui/icons-material/Close';
import axios from "axios";
import { MaterialReactTable } from 'material-react-table';
import { FLEET_MANAGING_BASE_URL } from '../../../../app/services/baseService';
import { CreateEditDriverCohortForm } from '../';
import { SimpleModal } from '../modals/SimpleModal';
import { ExportToCsv } from 'export-to-csv';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { CohortDriversList } from '../lists/CohortDriversList';
import BasicLoader from '../../loaders/SimpleLoader';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
    close: {
        padding: theme.spacing(0.5),
    },
    margin: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    avatar: {
        margin: 10,
    },
    bigAvatar: {
        margin: 10,
        width: 30,
        height: 30,
    }
}));

export function DriverCohortsTable({ className }) {

    const [data, setData] = useState([]);
    const [dataDocument, setDataDocument] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 100,
    });
    const [rowCount, setRowCount] = useState(0);
    const [reRender, setReRender] = useState(0);
    const [open, setOpen] = useState(false);
    const [show, setShow] = useState(false);
    const [initialValues, setInitialValues] = useState({
        drivers: [""],
        bikeArrivalStartDate: 0,
        bikeArrivalEndDate: 0,
        testRideInterviewDate: 0
    });
    const [editState, setEditState] = useState(false);
    const [createEditText, setCreateEditText] = useState('Create Cohort');
    const [fieldDisabled, setFieldDisabled] = useState(false);
    const [severity, setSeverity] = useState('info');
    const [showCohortDriversModal, setShowCohortDriversModal] = useState(false);
    const [cohortID, setCohortID] = useState("");
    const [cohortDriversIDs, setCohortDriversIDs] = useState([]);
    const [filterMode, setFilterMode] = useState(false);
    const [filterType, setFilterType] = useState(0);
    const [cohortStartDate, setCohortStartDate] = useState(0);

    const [funnelSizeCountLoading, setFunnelSizeCountLoading] = useState(true);
    const [qualifiedLeadsCountLoading, setQualifiedLeadsCountLoading] = useState(true);
    const [verifiedLeadCountLoading, setVerifiedLeadsLoading] = useState(true);
    const [activatedLeadsLoading, setActivatedLeadsLoading] = useState(true);
    const [activeLeadsCountLoading, setActiveLeadsCountLoading] = useState(true);
    const [churnedRidersCountLoading, setChurnedRidersCountLoading] = useState(true);

    const [funnelSizeCount, setFunnelSizeCount] = useState(0);
    const [qualifiedLeadsCount, setQualifiedLeadsCount] = useState(0);
    const [verifiedLeadCount, setVerifiedLeads] = useState(0);
    const [activatedLeads, setActivatedLeads] = useState(0);
    const [activeLeadsCount, setActiveLeadsCount] = useState(0);
    const [churnedRidersCount, setChurnedRidersCount] = useState(0);

    const TableErrorMessage = useRef();
    const classes = useStyles();

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary
    }));

    function handleSnackbarClose(event, reason) {
        if (reason === 'clickaway')
            return;
        setOpen(false);
    }

    function onChangeWorker() {
        setReRender(prevState => prevState + 1);
    }

    useEffect(() => {

        let isMounted = true;

        setQualifiedLeadsCount(251);
        setActivatedLeads(145);
        setActiveLeadsCount(70);
        setChurnedRidersCount(34);

        const fetchData = async () => {

            if (!data.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL('/driverCohorts/getAll', `${FLEET_MANAGING_BASE_URL}`);
            url.searchParams.set(
                'startNumber',
                `${pagination.pageIndex * pagination.pageSize}`,
            );
            url.searchParams.set('pageSize', `${pagination.pageSize}`);

            try {

                const response = axios.get(url.href);
                const data = (await response).data;

                const dataArrayAlt = [];
                const dataArray = data.data;

                for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let dataArrayDocument = element.document;
                    dataArrayAlt.push(dataArrayDocument);

                    if ((i + 1) >= dataArray.length) {
                        setDataDocument(dataArrayAlt);
                    }

                }

                /* for (let i = 0; i < dataArray.length; i++) {

                    let element = dataArray[i];
                    let dataArrayDocument = element.document;
                    dataArrayAlt.push(dataArrayDocument);

                    const cohortDrivers = dataArrayDocument.drivers;
                    for (let j = 0; j < cohortDrivers.length; j++) {

                        const driverID = cohortDrivers[j];
                        await fetchDriverData(driverDataArray, driverID).then(() => {

                            if ((j + 1) === cohortDrivers.length) {
                                setDriverDataArrayAlt(driverDataArray);
                                console.log(driverDataArray);
                            }

                        });

                    }

                } */

                setData(data.data);
                setRowCount(data.max_count);

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                setSeverity("error");
                setIsError(true);
                setOpen(true);

                return;

            } finally {

                setIsLoading(false);
                setIsRefetching(false);

            }

        };

        const fetchAnalyticsData = async () => {

            const url = new URL('/counters/drivers/getDriverAnalytics', `${FLEET_MANAGING_BASE_URL}`);
            const xAxisArray = [];
            const specDataArray = [];
            const coAvoidedArray = [];
            const harshBrakingArray = [];
            const overSpeedingArray = [];
            const lateNightTravelCountArray = [];
            const unsafeDistanceTravelArray = [];

            try {

                const response = await Axios.get(url.href);
                const counterData = response.data;

                console.log(counterData);
                /* setVehicleCountTrends(counterData);

                const macYaxisVal = Math.max(...counterData.countArray);
                setMaxYaxisVal(macYaxisVal);

                const dataArray = counterData.data.reverse();
                for (let i = 0; i < dataArray.length; i++) {

                    const dataBlock = dataArray[i];
                    const coValue = ((Number(dataBlock.totalMileage) * 92.45) / 1000);
                    const harshBrakingVal = dataBlock.harshBrakingCount ?? 0;
                    const overSpeedingCountVal = dataBlock.overSpeedingCount ?? 0;
                    const lateNightTravelCountVal = dataBlock.mileageBeyondEveningCount ?? 0;
                    const unsafeDistanceTravelCountVal = dataBlock.unsafeMiles ?? 0;

                    xAxisArray.push(`${dataBlock.year}-${dataBlock.month}`);
                    specDataArray.push((dataBlock.totalMileage).toFixed(3));
                    coAvoidedArray.push(coValue.toFixed(3));
                    harshBrakingArray.push(harshBrakingVal);
                    overSpeedingArray.push(overSpeedingCountVal);
                    lateNightTravelCountArray.push(lateNightTravelCountVal);
                    unsafeDistanceTravelArray.push(unsafeDistanceTravelCountVal.toFixed(3));

                    setFleetTravelCountTrending({
                        xAxisArray: xAxisArray,
                        specDataArray: specDataArray,
                        coAvoidedArray: coAvoidedArray,
                        harshBrakingArray: harshBrakingArray,
                        overSpeedingArray: overSpeedingArray,
                        lateNightTravelCountArray: lateNightTravelCountArray,
                        unsafeDistanceTravelArray: unsafeDistanceTravelArray,
                    });

                    if ((i + 1) === dataArray.length) {
                        setTimeout(() => {

                            const maxTotalMileage = Math.max(...specDataArray);
                            setMaxFleetMileageValue(maxTotalMileage);

                            const maxCarbonAvoided = Math.max(...coAvoidedArray);
                            setMaxCarbonAvoidedValue(maxCarbonAvoided);

                            const maxHarshBrakingNumber = Math.max(...harshBrakingArray);
                            setMaxHarshBrakingValue(maxHarshBrakingNumber);

                            const maxOverSpeedingNumber = Math.max(...overSpeedingArray);
                            setMaxOverSpeedingValue(maxOverSpeedingNumber);

                            const maxUnsafeDistanceTraveled = Math.max(...unsafeDistanceTravelArray);
                            setMaxUnsafeDistanceTraveledValue(maxUnsafeDistanceTraveled);

                            setShowActionButtons(true);
                            setIsLoadingVehicleCountTrends(false);

                        }, 5000);
                    }

                } */

            } catch (error) {

                /* setIsLoadingVehicleCountTrendsError(true);
                fetchVehicleCountTrendsErrorMessage.current = error?.response?.data?.message ?? error?.message ?? error?.response?.data?.code ?? error?.code;
                setSeverity("error");
                setTriggerSnackbarOpen(true); */

                return;

            }

        };

        const getDriverCount = async () => {

            const url = new URL('/counters/drivers/getDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href);
                const data = (await response).data;

                setFunnelSizeCount(data.count);
                setFunnelSizeCountLoading(false);

                return;

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    setFunnelSizeCountLoading(false);
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                setSeverity("error");
                setIsError(true);
                setFunnelSizeCountLoading(false);
                setOpen(true);

                return;

            }

        };

        const getVerifiedDriverCount = async () => {

            const url = new URL('/counters/drivers/getVerifiedDriverCount', `${FLEET_MANAGING_BASE_URL}`);

            try {

                const response = Axios.get(url.href);
                const data = (await response).data;

                setVerifiedLeads(data.count);
                setVerifiedLeadsLoading(false);

                return;

            } catch (error) {

                if (error?.code === "ERR_NETWORK" || error?.code === "ECONNABORTED") {

                    setSeverity("error");
                    setVerifiedLeadsLoading(false);
                    TableErrorMessage.current = "network error, please check your internet connection";
                    setOpen(true);

                    return;

                }

                TableErrorMessage.current = `Code: ${error.response.status}, Message: ${error.response.data.message}`;
                setSeverity("error");
                setIsError(true);
                setVerifiedLeadsLoading(false);
                setOpen(true);

                return;

            }

        };

        isMounted && fetchData();
        isMounted && fetchAnalyticsData();
        isMounted && getDriverCount();
        isMounted && getVerifiedDriverCount();

        return () => isMounted = false;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        pagination.pageIndex,
        pagination.pageSize,
        reRender
    ]);

    const columns = useMemo(() => [
        {
            accessorFn: (row) => new Date(row?.document?.testRideInterviewDate).toUTCString(),
            id: 'testRideInterviewDate',
            enableClickToCopy: true,
            header: 'Rider Interview Date'
        },
        {
            accessorFn: (row) => row?.document?.isActive,
            header: 'Status',
            Cell: ({ cell }) => (
                <Box
                    sx={(theme) => ({
                        backgroundColor: cell.getValue() === true ? theme.palette.success.dark : cell.getValue() === false ? theme.palette.error.dark : theme.palette.error.dark,
                        borderRadius: '0.35rem',
                        color: '#fff',

                        p: '0.35rem',
                        textAlign: 'center'
                    })}
                >
                    {cell.getValue() === true ? "Active" : "Inactive"}
                </Box>
            ),
        },
        {
            accessorFn: (row) => new Date(row?.document?.createdAt),
            id: 'document.createdAt',
            header: 'Date Created',
            filterVariant: 'date-range',
            Cell: ({ cell }) => cell.getValue().toLocaleDateString(),
            grow: true,
            size: 300,
        }
    ],
        [],
    );

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: true,
        //headers: columns.map((c) => c.header),
        title: 'Driver Cohorts',
        showTitle: true
    };
    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        csvExporter.generateCsv(rows.map((row) => row.original?.document));
    };

    const handleExportData = () => {
        csvExporter.generateCsv(dataDocument);
    };

    return (
        <>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}
                autoHideDuration={8000}
                onClose={handleSnackbarClose}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}
                action={[
                    <Button key="undo" color="primary" size="small" onClick={handleSnackbarClose}>
                        UNDO
                    </Button>,
                    <IconButton
                        key="close"
                        aria-label="Close"
                        color="inherit"
                        className={classes.close}
                        onClick={handleSnackbarClose}
                    >
                        <CloseIcon />
                    </IconButton>
                ]}
            >
                <Alert
                    elevation={6}
                    variant="filled"
                    severity={severity}
                    onClose={handleSnackbarClose}
                >
                    <span id="message-id">
                        {TableErrorMessage.current}
                    </span>
                </Alert>
            </Snackbar>
            <SimpleModal
                modal_tittle={`Create / Edit Driver Cohort`}
                show={show}
                close={() => setShow(false)}
                size="lg"
                body={<CreateEditDriverCohortForm
                    initialValues={initialValues}
                    editState={editState}
                    createEditText={createEditText}
                    fieldDisabled={fieldDisabled}
                    onChanger={onChangeWorker}
                    cohortID={cohortID}
                />}
            />
            <SimpleModal
                modal_tittle={`Cohort for Week of ${new Date(cohortStartDate).toDateString()}`}
                show={showCohortDriversModal}
                close={() => setShowCohortDriversModal(false)}
                size="xl"
                body={<CohortDriversList
                    cohortDriversIDs={cohortDriversIDs}
                    className="card-stretch gutter-b"
                />}
            />
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                <Grid item xs={12} sm={12} md={4} className='mb-10'>
                    <div
                        className={`d-flex align-items-center ${filterMode ? filterType === 1 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                        onClick={() => {
                            setFilterMode(true);
                            setFilterType(1);
                        }}
                    >
                        <div className="d-flex flex-column flex-grow-1">
                            <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                FUNNEL SIZE
                            </label>
                            <small className='text-muted'>App downloaded</small>
                            <span className="text-muted font-weight-bold">
                                {funnelSizeCountLoading ? <BasicLoader size={"sm"} /> : funnelSizeCount}
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className='mb-10'>
                    <div
                        className={`d-flex align-items-center ${filterMode ? filterType === 3 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                        onClick={() => {
                            setFilterMode(true);
                            setFilterType(3);
                        }}
                    >
                        <div className="d-flex flex-column flex-grow-1">
                            <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                VERIFIED LEADS
                            </label>
                            <small className='text-muted'>Passed Vetting</small>
                            <span className="text-muted font-weight-bold">
                                {verifiedLeadCountLoading ? <BasicLoader size={"sm"} /> : verifiedLeadCount}
                                {" "}
                                <small className='text-success'>
                                    {"("}
                                    {verifiedLeadCountLoading ? <BasicLoader size={"sm"} /> : ((verifiedLeadCount / funnelSizeCount) * 100).toFixed(2)}
                                    {"%"}
                                    {")"}
                                </small>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={4} className='mb-10'>
                    <div
                        className={`d-flex align-items-center ${filterMode ? filterType === 4 ? 'bg-light-success' : 'bg-light-warning' : 'bg-light-warning'} rounded-xl p-6 cursor-pointer`}
                        onClick={() => {
                            setFilterMode(true);
                            setFilterType(4);
                        }}
                    >
                        <div className="d-flex flex-column flex-grow-1">
                            <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                ACTIVATED LEADS
                            </label>
                            <small className='text-muted'>Deposit Paid</small>
                            <span className="text-muted font-weight-bold">
                                {activatedLeads}
                                {" "}
                                <small className='text-success'>
                                    {"("}
                                    {((activeLeadsCount / verifiedLeadCount) * 100).toFixed(2)}
                                    {"%"}
                                    {")"}
                                </small>
                            </span>
                        </div>
                    </div>
                </Grid>
                {/* <Grid item xs={12} sm={12} md={2} className='mb-10'>
                    <div
                        className={`d-flex align-items-center bg-light-success rounded p-6 cursor-pointer`}
                        onClick={() => {
                            setFilterMode(true);
                            setFilterType(5);
                        }}
                    >
                        <div className="d-flex flex-column flex-grow-1">
                            <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                ACTIVE RIDERS
                            </label>
                            <small className='text-muted'>Riders with Vehicles</small>
                            <span className="text-muted font-weight-bold">
                                {activeLeadsCount}
                                {" "}
                                <small className='text-success'>
                                    {"("}
                                    {((activeLeadsCount / activatedLeads) * 100).toFixed(2)}
                                    {"%"}
                                    {")"}
                                </small>
                            </span>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} sm={12} md={2} className='mb-10'>
                    <div
                        className={`d-flex align-items-center bg-light-danger rounded p-6 cursor-pointer`}
                        onClick={() => {
                            setFilterMode(true);
                            setFilterType(6);
                        }}
                    >
                        <div className="d-flex flex-column flex-grow-1">
                            <label className="font-weight-bold text-dark-75 font-size-lg mb-1 cursor-pointer">
                                CHURNED
                            </label>
                            <small className='text-muted'>Riders who left</small>
                            <span className="text-muted font-weight-bold">
                                {churnedRidersCount}
                                {" "}
                                <small className='text-success'>
                                    {"("}
                                    {((churnedRidersCount / activeLeadsCount) * 100).toFixed(2)}
                                    {"%"}
                                    {")"}
                                </small>
                            </span>
                        </div>
                    </div>
                </Grid> */}
            </Grid>
            <div className={`card card-custom ${className} rounded-xl`}>
                <div className="card-header border-0 pt-5">
                    <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                            Rider Cohorts
                        </span>
                        <span className="text-muted mt-3 font-weight-bold font-size-sm">
                            Manage rider cohorts from this table
                        </span>
                    </h3>
                </div>
                <div className="card-body pt-3 pb-10">
                    <MaterialReactTable
                        columns={columns}
                        data={data}
                        enableRowSelection
                        getRowId={(row) => row.id}
                        initialState={{ showColumnFilters: true }}
                        manualPagination
                        enableStickyHeader
                        enableColumnFilterModes
                        enableColumnOrdering
                        enableGrouping
                        enableColumnPinning
                        enableRowPinning
                        enableRowActions
                        muiToolbarAlertBannerProps={
                            isError ? {
                                color: 'error',
                                children: `${TableErrorMessage.current}`,
                            } : undefined
                        }
                        onPaginationChange={setPagination}
                        rowCount={rowCount}
                        state={{
                            isLoading,
                            pagination,
                            showAlertBanner: isError,
                            showProgressBars: isRefetching
                        }}
                        renderDetailPanel={({ row }) => (
                            <div>

                                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography variant="label" className='bold'>ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.ID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography variant="label" className='bold'>Cohort Tracker ID: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.cohortTrackerID}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Item>
                                            <Box sx={{ textAlign: 'center' }}>
                                                <Typography variant="label" className='bold'>Number of Drivers: </Typography>
                                                <Typography variant="p">
                                                    {row.original.document?.drivers.length}
                                                </Typography>
                                            </Box>
                                        </Item>
                                    </Grid>
                                </Grid>

                            </div>
                        )}
                        renderRowActionMenuItems={({ closeMenu, row }) => [
                            <MenuItem
                                key={0}
                                onClick={() => {

                                    const drivers = row.original?.document?.drivers;
                                    const createdAt = row.original?.document?.createdAt;

                                    setCohortDriversIDs(drivers);
                                    setShowCohortDriversModal(true);
                                    setCohortStartDate(createdAt);

                                    closeMenu();

                                }}
                                sx={{ m: 0 }}
                            >
                                <ListItemIcon>
                                    <VisibilityTwoToneIcon
                                        color='secondary'
                                    />
                                </ListItemIcon>
                                View Riders in Cohort
                            </MenuItem>,
                        ]}
                        renderTopToolbarCustomActions={({ table }) => {

                            return (

                                <>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                color="info"
                                                onClick={handleExportData}
                                                variant="contained"
                                                size='small'
                                            >
                                                Export All Data
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                                onClick={() =>
                                                    handleExportRows(table.getPrePaginationRowModel().rows)
                                                }
                                                variant="contained"
                                                size='small'
                                                color="info"
                                            >
                                                Export All Rows
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={table.getRowModel().rows.length === 0}
                                                onClick={() => handleExportRows(table.getRowModel().rows)}
                                                variant="contained"
                                                size='small'
                                                color="info"
                                            >
                                                Export Page Rows
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={3}>
                                            <Button
                                                disabled={
                                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                                }
                                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                                size='small'
                                                variant="contained"
                                                color="info"
                                            >
                                                Export Selected Rows
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </>

                            );
                        }}
                        enableStickyFooter
                        enableColumnResizing
                        muiSkeletonProps={{
                            animation: 'wave',
                            height: 30,
                        }}
                        muiLinearProgressProps={{
                            color: 'primary',
                        }}
                        muiPaginationProps={{
                            rowsPerPageOptions: [5, 10, 20, 30, 40, 50, 100, 150, 200, 300, 400, 600, 1000],
                            showFirstButton: true,
                            showLastButton: true,
                            color: 'success',
                            shape: 'rounded',
                            showRowsPerPage: true,
                            variant: 'outlined',
                        }}
                        paginationDisplayMode={"pages"}
                        paginateExpandedRows={true}
                        muiTableContainerProps={{
                            sx: {
                                maxHeight: '650px',
                            }
                        }}
                        enableBatchRowSelection
                        enableRowNumbers
                        rowNumberDisplayMode={'original'}
                        selectAllMode={"all"}
                        options={{
                            selection: true,
                        }}
                        enableBottomToolbar
                        positionPagination="both"
                    />
                </div>
            </div>
        </>
    );
}
