import React, { useState, useRef } from 'react';
import {
    Grid,
    Button,
} from '@mui/material';
import { unChurnDriver } from '../../../../app/modules/Auth/_redux/authCrud';
import { FormAlert } from '../../controls';
import BasicLoader from '../../loaders/SimpleLoader';

export function UnchurnDriverForm({ driverID, onChangeWorker, selectedDriverName, modalCloseCallback }) {

    const TableMessage = useRef();

    const [loading, setLoading] = useState(false);
    const [alertType, setAlertType] = useState({
        type: '',
        icon: ''
    });
    const [isFormAlertEnabled, setFormAlertEnabled] = useState(true);

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const dismissFormAlert = () => {
        setFormAlertEnabled(false);
    };

    const showFormAlert = () => {
        setFormAlertEnabled(true);
    };

    return (
        <>

            <div className='centerMan'>
                <span>
                    <span className='text-danger'>
                        Are you sure you want to unchurn this rider:
                    </span>
                    {" "}
                    <span className='bold'>
                        {selectedDriverName}
                    </span>
                    <span className='text-danger'>
                        ?
                    </span>
                </span>
            </div>
            <div className='mt-5'>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} className="mb-4">
                    <Grid item xs={6}>
                        <div className='centerMan'>
                            <Button
                                size='small'
                                variant='contained'
                                color='error'
                                onClick={() => {
                                    return modalCloseCallback();
                                }}
                            >
                                No
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div className='centerMan'>
                            <Button
                                size='small'
                                variant='contained'
                                color='success'
                                onClick={() => {

                                    enableLoading();

                                    unChurnDriver(driverID).then((response) => {

                                        if (response) {

                                            TableMessage.current = response.data?.error?.code ?? response.data.message ?? 'an error occurred, please try again later';

                                            setAlertType({
                                                type: 'danger',
                                                icon: 'flaticon-warning mana-icon-white'
                                            });

                                            disableLoading();
                                            showFormAlert();

                                            return onChangeWorker();

                                        }

                                        TableMessage.current = 'an error occurred, please try again later';

                                        setAlertType({
                                            type: 'danger',
                                            icon: 'flaticon-warning mana-icon-white'
                                        });

                                        disableLoading();
                                        showFormAlert();

                                        return onChangeWorker();

                                    }).catch((error) => {

                                        if (error.response) {

                                            TableMessage.current = error?.response?.data?.message;
                                            setAlertType({
                                                type: 'danger',
                                                icon: 'flaticon-warning mana-icon-white'
                                            });

                                            disableLoading();
                                            showFormAlert();

                                            return onChangeWorker();

                                        }

                                        TableMessage.current = error?.message ?? error?.code ?? "an expected error occurred";
                                        setAlertType({
                                            type: 'danger',
                                            icon: 'flaticon-warning mana-icon-white'
                                        });

                                        disableLoading();
                                        showFormAlert();

                                        return onChangeWorker();;

                                    });

                                }}
                            >
                                <span>
                                    Yes
                                    {loading && (<>
                                        {" "}
                                        <BasicLoader size={"sm"} />
                                    </>)}
                                </span>
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <FormAlert
                            isEnabled={isFormAlertEnabled}
                            type={alertType.type}
                            message={TableMessage.current}
                            icon={alertType.icon}
                            handleDismiss={() => {
                                dismissFormAlert();
                            }}
                        />
                    </Grid>
                </Grid>
            </div>

        </>
    );
}